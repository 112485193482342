import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Link,
  InputAdornment,
  IconButton,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Email, Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "App/utils/auth";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { primaryColor, themeColors } from "..";
import { useTranslation } from "react-i18next";
import DirTheme from "../DirTheme";

export const LoginPage = () => {
  const { t } = useTranslation("common");
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const history = useHistory();
  const location = useLocation();
  const { signin, isLoading, isAuthenticated, isLoadingDetails, error } =
    useAuth();

  let { from }: any = location.state || { from: { pathname: "/" } };

  const [username, setUserName] = useState<string>();
  const [password, setPassword] = useState<string>();

  //! TODO: Allows for a common alert flash: Info, Warn, Error

  let login = () => {
    signin(username!, password!, () => {
      history.replace(from);
      return;
    });
  };

  if (isAuthenticated && !isLoadingDetails && from.pathname !== "/login")
    return (
      <Redirect
        to={{
          pathname: from.pathname,
        }}
      />
    );
  return (
    <Grid container sx={{ height: "100vh" }} dir={direction}>
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        p={2}
        sx={{ backgroundColor: "white" }}
        className="login-image"
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: { xs: "none", sm: "none", md: "block" },
            backgroundImage: `url(${require("Kcafi/img/auth.png")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "20px",
          }}
        ></Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        component={Paper}
        elevation={0}
        square
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 10,
        }}
        className="login-form"
      >
        <Typography
          variant="h2"
          className="login-title"
          sx={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 32,
            lineHeight: "120%",
            color: themeColors.darkBlue,
            mb: 4,
          }}
        >
          {t("pages.login.title")}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 18,
            lineHeight: "150%",
            color: themeColors.deepTurquoise,
            mb: 4,
          }}
        >
          {t("pages.login.subtitle")}
        </Typography>

        {error && (
          <Alert sx={{ mb: 4 }} variant="outlined" severity="error">
            {t(`pages.login.${error}`)}
          </Alert>
        )}

        <DirTheme>
          <TextField
            sx={{ mb: 4 }}
            label={t("pages.login.email")}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setUserName(event.target.value);
            }}
            value={username}
          />
        </DirTheme>

        <DirTheme>
          <TextField
            sx={{ mb: 6 }}
            label={t("pages.login.password")}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            value={password}
          />
        </DirTheme>

        <Button
          sx={{
            background: primaryColor().primary,
            borderRadius: "100px",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            ":hover": {
              background: primaryColor().primary,
            },
            mb: 6,
            boxShadow: `0px 11px 64px ${themeColors.black007}`,
          }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={login}
          disabled={isLoading || !username || !password}
        >
          {isLoading ? (
            <CircularProgress size={20} sx={{ color: themeColors.white }} />
          ) : (
            t("pages.login.login")
          )}
        </Button>
        <Typography
          variant="body2"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "150%",
            color: themeColors.darkBlue,
            mt: 1,
            textAlign: "center",
          }}
        >
          {t("pages.login.forgot_your_password")}{" "}
          <Link href="/forgot-password" underline="always">
            {t("pages.login.click_here")}
          </Link>
        </Typography>

        <Typography
          variant="body2"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "150%",
            color: themeColors.darkBlue,
            mt: 2,
            textAlign: "center",
          }}
        >
          {t("pages.login.dont_have_account")}{" "}
          <Link href="/register" underline="always">
            {t("pages.login.register")}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
