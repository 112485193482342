import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { CircularProgress, IconButton } from "@mui/material";
import { PowerSettingsNew } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { useAppContext } from "App";
import { useEffect, useState } from "react";
import { useCommand } from "Components/api";
import { ErrorToast } from "../ErrorToast";
import { themeColors } from "..";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CloseSessionDialog = () => {
  const {t} = useTranslation("common");
  const appQueryClient = useQueryClient();
  const { myDetails, cartManager } = useAppContext();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const {
    onSubmit,
    data: serverReply,
    isLoading: isLoadingCloseSession,
    isSuccess,
  } = useCommand(myDetails, "current_session_close", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setSuccess(t('pages.session.close_session.session_closed'));
    appQueryClient.invalidateQueries(["mySessions"]);
    appQueryClient.invalidateQueries(["My:Details"]);
    cartManager.clearCart();
    handleCloseDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleCloseAlert = () => {
    setError("");
    setSuccess("");
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  return (
    <div>
      <ErrorToast
        error={error}
        success={success}
        handleClose={handleCloseAlert}
      />
      {myDetails?.hasActiveSession && (
        <IconButton
          sx={{
            mr: 1,
            backgroundColor: themeColors.red,
            ":hover": {
              backgroundColor: themeColors.red,
            },
            height: {
              xs: 36,
              sm: 36,
              md: 44
            },
            width: {
              xs: 36,
              sm: 36,
              md: 44
            },
          }}
          aria-label="Button"
          className="add-button"
          onClick={handleClickOpenDialog}
        >
          <PowerSettingsNew
            sx={{
              color: "white",
              width: 34,
              height: 24,
              fontSize: 18,
            }}
          />
        </IconButton>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{t('pages.session.close_session.close_session_dialog_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('pages.session.close_session.close_session_dialog_description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t('pages.session.close_session.close_session_dialog_cancel')}</Button>
          <Button onClick={() => onSubmit({})}>
            {isLoadingCloseSession ? (
              <CircularProgress sx={{ width: 24, height: 24, mr: 1 }} />
            ) : (
              t('pages.session.close_session.close_session_dialog_confirm')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
