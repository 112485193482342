import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ResponsiveDrawer from "Kcafi/ResponsiveDrawer";
import { Section } from "Kcafi/Pages/DashboardPage";
import { primaryColor, themeColors } from "..";
import { ProductsContextMenu } from "./ProductsContextMenu";
import { useTranslation } from "react-i18next";
import DirTheme from "../DirTheme";
import { useEffect, useState } from "react";
import { Category, TProduct } from "../Caisse/CaissePage";
import { useCommand, useOperation } from "Components/api";
import { useAppContext } from "App";
import { Add } from "@mui/icons-material";
import {
  NumericFormatCustomKG,
  NumericFormatCustomLitre,
  NumericFormatCustomPiece,
} from "./NumericFormatCustom";
import { StockItem } from "../Session/StockItem";
import { useQueryClient } from "react-query";
import { ErrorToast } from "../ErrorToast";
import { UnlockDialog } from "../Caisse/UnlockDialog";

export const StockPage = () => {
  const appQueryClient = useQueryClient();
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const { t } = useTranslation("common");
  const { myDetails } = useAppContext();
  const [product, setProduct] = useState<TProduct>();
  const [qte, setQte] = useState<number>(0);

  const { data: categories, isLoading: isLoadingMyProducts } = useOperation<
    Category[]
  >(
    myDetails,
    {
      link: "myProducts",
    },
    "myProducts",
    { type: "achat,achat-vente" } as any,
    undefined,
    !myDetails
  );

  const { data: stocks, isLoading: isLoadingMyStocks } = useOperation<
    {
      title: string;
      imageSrc: string;
      productId: number;
      quantity: number;
      soldeQuantity?: number;
      availableQuantity?: number;
      unit: string;
    }[]
  >(
    myDetails,
    {
      link: "myProductsStock",
    },
    "myProductsStock",
    undefined,
    undefined,
    !myDetails
  );

  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const {
    onSubmit,
    data: serverReply,
    isLoading: isLoadingCreateStock,
    isSuccess,
  } = useCommand(myDetails, "myProductsStockCreate", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setSuccess(t("pages.products.stock.stock_updated"));
    appQueryClient.invalidateQueries(["myProductsStock"]);
    appQueryClient.invalidateQueries(["My:Details"]);
    setProduct(undefined);
    setQte(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };

  return (
    <ResponsiveDrawer title={t("pages.products.stock.title")}>
      <UnlockDialog />
      <Section title="" mt={0}>
        <ErrorToast error={error} success={success} handleClose={handleClose} />

        {isLoadingMyStocks ? (
          <Box sx={{ display: "flex", mb: 2 }}>
            <CircularProgress sx={{ color: primaryColor().primary }} />
          </Box>
        ) : (
          <Box>
            <ProductsContextMenu activeMenu="stock" />
          </Box>
        )}
        <Section
          title=""
          //description={t("pages.products.stock.description")}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <FormControl
              sx={{
                mr: direction === "rtl" ? 0 : 1,
                ml: direction === "rtl" ? 1 : 0,
              }}
            >
              <DirTheme>
                <InputLabel htmlFor="grouped-native-select">
                  {t("pages.session.start_new_session.product")}
                </InputLabel>
                <Select
                  native
                  defaultValue=""
                  id="grouped-native-select"
                  value={`${product?.id}`}
                  label={t("pages.session.start_new_session.product")}
                  onChange={(event) => {
                    setProduct(undefined);
                    categories?.forEach((category) => {
                      category.products?.forEach((product) => {
                        if (product.id === parseInt(event.target.value)) {
                          setProduct(product);
                        }
                      });
                    });
                  }}
                  variant="outlined"
                  size="small"
                >
                  <option aria-label="None" value="00">
                    {t("pages.session.start_new_session.choose_product")}
                  </option>
                  {!isLoadingMyProducts &&
                    categories?.map((category, key) => (
                      <optgroup label={category.title} key={key}>
                        {category.products?.map((product, key) =>
                          product.type !== "vente" ? (
                            <option value={product.id} key={key}>
                              {product.title}
                            </option>
                          ) : (
                            <></>
                          )
                        )}
                      </optgroup>
                    ))}
                </Select>
              </DirTheme>
            </FormControl>
            <FormControl
              variant="outlined"
              sx={{
                mr: direction === "rtl" ? 0 : 1,
                ml: direction === "rtl" ? 1 : 0,
              }}
            >
              <DirTheme>
                <TextField
                  sx={{
                    fontStyle: "normal",
                  }}
                  label={t("pages.session.start_new_session.quantity")}
                  name="quantity"
                  id="formatted-numberformat-input-x"
                  variant="outlined"
                  value={qte}
                  onChange={(event) => setQte(parseFloat(event.target.value))}
                  InputProps={{
                    inputComponent:
                      product?.unit === "litre"
                        ? (NumericFormatCustomLitre as any)
                        : product?.unit === "kg"
                        ? (NumericFormatCustomKG as any)
                        : (NumericFormatCustomPiece as any),
                  }}
                  disabled={!product}
                  size="small"
                />
              </DirTheme>
            </FormControl>
            <Button
              sx={{
                borderRadius: "4px",
                backgroundColor: primaryColor().primary,
                ":hover": {
                  background: primaryColor().primary,
                },
                color: themeColors.white,
                fontStyle: "normal",
                fontWeight: 600,
                height: "40px",
                mr: direction === "rtl" ? 1 : 0,
                ml: direction === "rtl" ? 1 : 0,
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                if (product) {
                  onSubmit({
                    productId: product.id,
                    quantity: qte,
                  });
                }
              }}
              disabled={!product || !qte}
            >
              {isLoadingCreateStock ? (
                <CircularProgress size={20} sx={{ color: themeColors.white }} />
              ) : (
                <Add />
              )}
            </Button>
          </Box>

          <Box mt={2}>
            {isLoadingMyStocks ? (
              <CircularProgress size={20} sx={{ color: themeColors.white }} />
            ) : (
              <Section title={t("pages.products.stock.stock_items")}>
                {stocks?.length !== 0 ? (
                  stocks?.map((stockItem) => (
                    <StockItem
                      stockItem={stockItem}
                      imageSrc={stockItem.imageSrc}
                      title={stockItem.title}
                      quantity={`${stockItem.quantity} ${stockItem.unit}`}
                      soldeQuantity={`${stockItem.soldeQuantity} ${stockItem.unit}`}
                      availableQuantity={`${stockItem.availableQuantity} ${stockItem.unit}`}
                    />
                  ))
                ) : (
                  <Typography variant="body1">
                    {t("pages.products.stock.empty_stock")}
                  </Typography>
                )}
              </Section>
            )}
          </Box>
        </Section>
      </Section>
    </ResponsiveDrawer>
  );
};
