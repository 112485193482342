import { Section } from "../../DashboardPage";
import { Card } from "@mui/material";
import { UidInput } from ".";
import { themeColors } from "Kcafi/Pages";
import { useTranslation } from "react-i18next";


export const AccountUID = () => {
  const {t} = useTranslation("common");
  return (
    <Card
      sx={{
        boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
        padding: "15px",
        borderRadius: "10px",
        marginBottom: "25px",
      }}
    >
      <Section
        title={t('pages.settings.account_uid.title')}
        description={t('pages.settings.account_uid.description')}
      >
        <UidInput />
      </Section>
    </Card>
  );
};
