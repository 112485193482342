import ResponsiveDrawer from "Kcafi/ResponsiveDrawer";
import { Section, SessionType } from "../DashboardPage";
import { SessionLabel } from "./SessionLabel";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useAppContext } from "App";
import { useOperation } from "Components/api";
import { useParams } from "react-router-dom";
import { FiberManualRecord } from "@mui/icons-material";
import { primaryColor, themeColors } from "..";
import { StockItem } from "./StockItem";
import { DataNotFound } from "../DataNotFound";
import { useTranslation } from "react-i18next";
import { UnlockDialog } from "../Caisse/UnlockDialog";

export const SessionGenerateReportPage = () => {
  const { t } = useTranslation("common");
  const { myDetails } = useAppContext();

  const { sessionId } = useParams<any>();
  const { data: session, isLoading: isLoadingSessions } =
    useOperation<SessionType>(
      {
        _links: {
          mySessionDetails: myDetails?._links["mySessionDetails"]?.replace(
            ":sessionId",
            sessionId
          ),
        },
      },
      {
        link: "mySessionDetails",
      },
      "mySessionDetails" + sessionId,
      {} as any,
      undefined,
      !myDetails
    );

  return (
    <ResponsiveDrawer title={t("pages.session.session_report.title")}>
      <UnlockDialog />
      <Section
        title={`${t("pages.session.session_report.session")} ${
          session?.id ? `#${session?.id}` : ""
        }`}
      >
        <Grid container spacing={2}>
          {isLoadingSessions ? (
            <Box sx={{ display: "flex", ml: 2 }}>
              <CircularProgress sx={{ color: primaryColor().primary }} />
            </Box>
          ) : session ? (
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                sx={{
                  height: "160px",
                  background: themeColors.white,
                  borderRadius: "15.53px",
                  padding: "25px 15px",
                  position: "relative",
                }}
              >
                <FiberManualRecord
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    color: session.closedAt
                      ? themeColors.red
                      : themeColors.green,
                    fontSize: 16,
                  }}
                />
                <SessionLabel
                  label={t("pages.session.session_report.session_id")}
                  text={`${session.internalId}`}
                />
                <SessionLabel
                  label={t("pages.session.session_info.opened_by")}
                  text={`${session.nom} ${session.prenom}`}
                />
                <SessionLabel
                  label={t("pages.session.session_report.started_at")}
                  text={`${new Date(
                    session.startedAt.date
                  ).toLocaleDateString()} ${new Date(
                    session.startedAt.date
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`}
                />
                <SessionLabel
                  label={t("pages.session.session_report.ended_at")}
                  text={`${
                    session.closedAt
                      ? new Date(session.closedAt.date).toLocaleDateString() +
                        " " +
                        new Date(session.closedAt.date).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "-- : --"
                  }`}
                />
                <SessionLabel
                  label={t("pages.session.session_report.total_tickets")}
                  text={`${session.totalTickets}`}
                />
                <SessionLabel
                  label={t("pages.session.session_report.total_earnings")}
                  text={`${session.totalEarnings.toFixed(2)} ${t(
                    "pages.currency"
                  )}`}
                />
                <SessionLabel
                  label={t("pages.session.session_report.sold_available")}
                  text={`${
                    session.availableFunds
                      ? `${session.availableFunds.toFixed(2)} ${t(
                          "pages.currency"
                        )}`
                      : "--"
                  }`}
                />
              </Box>

              <Box mt={5}>
                <Section
                  title={t("pages.session.session_report.session_stock")}
                >
                  {session?.stocks?.length !== 0 ? (
                    session?.stocks?.map((stockItem) => (
                      <StockItem
                        imageSrc={stockItem.imageSrc}
                        title={stockItem.title}
                        quantity={`${stockItem.quantity} ${stockItem.unit}`}
                        soldeQuantity={`${stockItem.soldeQuantity} ${stockItem.unit}`}
                        availableQuantity={`${stockItem.availableQuantity} ${stockItem.unit}`}
                      />
                    ))
                  ) : (
                    <Typography variant="body1">
                      {t("pages.session.session_report.empty_stock")}
                    </Typography>
                  )}
                </Section>
              </Box>
            </Grid>
          ) : (
            <DataNotFound />
          )}
        </Grid>
      </Section>
    </ResponsiveDrawer>
  );
};
