import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
} from "@mui/material";
import { useAppContext } from "App";
import { useCommand } from "Components/api";
import { primaryColor, themeColors } from "Kcafi/Pages";
import { Section } from "Kcafi/Pages/DashboardPage";
import DirTheme from "Kcafi/Pages/DirTheme";
import { ErrorToast } from "Kcafi/Pages/ErrorToast";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const SecuritySettings = () => {
  const { t } = useTranslation("common");
  const appQueryClient = useQueryClient();
  const { myDetails } = useAppContext();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [codePin, setCodePin] = useState<string>();
  const [isHidden, setIsHidden] = useState(true);
  const [enableLock, setEnableLock] = useState<boolean | undefined>(
    myDetails.enableLock
  );

  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(myDetails, "update_profile_security", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setSuccess(t("pages.settings.security.security_updated"));
    appQueryClient.invalidateQueries(["My:Details"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodePin((event.target as HTMLInputElement).value);
  };
  const handleToggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  return (
    <Card
      sx={{
        boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
        padding: "15px",
        borderRadius: "10px",
        marginBottom: "25px",
      }}
    >
      <Section
        title={t("pages.settings.security.title")}
        description={t("pages.settings.security.description")}
      >
        <ErrorToast error={error} success={success} handleClose={handleClose} />
        <DirTheme>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              type={isHidden ? "password" : "text"}
              label={t("pages.settings.security.code_pin")}
              variant="outlined"
              value={codePin}
              onChange={handleChange}
              fullWidth
              sx={{
                maxWidth: "200px",
                mb: 2,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleVisibility} edge="end">
                      {isHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <FormControlLabel
              sx={{
                mb: 2,
              }}
              control={
                <Switch
                  checked={enableLock}
                  onChange={() => {
                    setEnableLock(!enableLock);
                  }}
                  name="enableLock"
                />
              }
              label={t("pages.settings.security.enable_lock")}
            />
            <Button
              sx={{
                borderRadius: "4px",
                backgroundColor: primaryColor().primary,
                ":hover": {
                  background: primaryColor().primary,
                },
                color: themeColors.white,
                fontStyle: "normal",
                fontWeight: 600,
                height: "54px",
                width: "120px",
              }}
              variant="contained"
              color="primary"
              onClick={() =>
                onSubmit({
                  codePin: codePin,
                  enableLock: !!enableLock,
                })
              }
            >
              {isLoading ? (
                <CircularProgress size={20} sx={{ color: themeColors.white }} />
              ) : (
                t("pages.settings.security.update")
              )}
            </Button>
          </Box>
        </DirTheme>
      </Section>
    </Card>
  );
};
