import ResponsiveDrawer from "Kcafi/ResponsiveDrawer";
import {
  AccountUID,
  ActivitySettings,
  WorkerManagement,
  ThemeSettings,
  SecuritySettings,
} from "./components";
import { useTranslation } from "react-i18next";
import { useAppContext } from "App";

export const SettingsPage = () => {
  const { t } = useTranslation("common");
  const { myDetails } = useAppContext();
  return (
    <ResponsiveDrawer title={t("pages.settings.title")}>
      <AccountUID />
      {myDetails.permissions?.Settings?.ReadSettings && (
        <>
          <ActivitySettings />
          <WorkerManagement />
          <SecuritySettings />
          <ThemeSettings />
        </>
      )}
    </ResponsiveDrawer>
  );
};
