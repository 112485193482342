import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import ImageEditor from "@uppy/image-editor";
import Webcam from "@uppy/webcam";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/image-editor/dist/style.min.css";
import { Avatar, Box, Button } from "@mui/material";
import { primaryColor, themeColors } from "..";
import { ErrorToast } from "../ErrorToast";
import { useQueryClient } from "react-query";
import { CameraAlt } from "@mui/icons-material";

type Props = {
  currentImageUrl: string | null;
  uploadUrl: string;
  variant?: "square" | "circular" | "rounded";
  avatarSx?: any;
};

const ImageUploader = ({
  currentImageUrl,
  uploadUrl,
  variant = "circular",
  avatarSx = { width: 80, height: 80 },
}: Props) => {
  const appQueryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (!openModal) return;

    const initializeUppy = () => {
      const dashboardContainer = document.getElementById("dashboard");

      if (!dashboardContainer) {
        console.error("Dashboard container not found");
        return;
      }

      const uppy = new Uppy()
        .use(Dashboard, {
          inline: true,
          target: dashboardContainer,
          autoOpenFileEditor: true,
          showProgressDetails: true,
          proudlyDisplayPoweredByUppy: false,
        })
        .use(ImageEditor, {
          actions: {
            revert: true,
            rotate: true,
            zoomIn: true,
            zoomOut: true,
            cropSquare: true,
            cropWidescreen: false,
            cropWidescreenVertical: false,
            granularRotate: true,
            flip: true,
          },
        })
        .use(Webcam, {
          target: Dashboard,
        })
        .on("upload", (data) => {
          console.log("Upload logic goes here:", data);
        })
        .on("complete", async (result) => {
          const file = result.successful[0]; // Assuming you're uploading only one file
          const authToken = localStorage.getItem("token");
          const formData = new FormData();
          formData.append("file", file.data);

          try {
            const response = await fetch(uploadUrl, {
              method: "POST",
              body: formData,
              headers: {
                Authorization: `Bearer ${authToken}`, // Include the token in the headers
              },
            });

            if (response.ok) {
              setSuccess("File uploaded successfully");
              appQueryClient.invalidateQueries(["My:Details"]);
            } else {
              setError("Failed to upload file");
            }
          } catch (error) {
            console.error("Error uploading file:", error);
          }

          // Close the modal or perform other actions as needed
          handleCloseModal();
        });
      uppy.setOptions({
        restrictions: { maxNumberOfFiles: 1 },
      });
      return () => {
        uppy.close();
      };
    };

    const timeoutId = setTimeout(initializeUppy, 200); // Adjust the delay as needed

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);
  const handleClose = () => {
    setError("");
    setSuccess("");
  };
  return (
    <Box mb={2}>
      <ErrorToast error={error} success={success} handleClose={handleClose} />
      <Box
        sx={{
          cursor: "pointer",
          height: "65%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "25px",
          borderRadius: "8px",
        }}
        onClick={handleOpenModal}
      >
        {currentImageUrl ? (
          <Avatar
            alt="img-pro"
            src={currentImageUrl}
            sx={avatarSx}
            variant={variant}
          />
        ) : (
          <Avatar alt="img-pro" sx={avatarSx} variant={variant}>
            <CameraAlt />
          </Avatar>
        )}
      </Box>

      {openModal && (
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 275,
              bgcolor: "white",
              border: "2px solid "+themeColors.black,
              boxShadow: 24,
              p: 4,
              outline: 0,
            }}
          >
            <div id="dashboard"></div>
            <Button
              sx={{
                border: "1px solid "+primaryColor().primary,
                borderRadius: "82px",
                backgroundColor: themeColors.white,
                color: primaryColor().primary,
                fontStyle: "normal",
                fontWeight: 600,
                marginRight: 1,
              }}
              fullWidth
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default ImageUploader;
