import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Link,
  InputAdornment,
  IconButton,
  Fade,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useCommand, useOperation } from "Components/api";
import { useAppContext } from "App";
import { primaryColor, themeColors } from "..";
import { useTranslation } from "react-i18next";
import DirTheme from "../DirTheme";

export const NewPasswordPage = () => {
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const { t } = useTranslation("common");
  const { ws } = useAppContext();
  const { tokenValue } = useParams<any>();

  const { data, isLoading: isLoadingToken } = useOperation<any>(
    {
      _links: {
        user_get_token_password: ws?._links["user_get_token_password"]?.replace(
          ":tokenValue",
          tokenValue
        ),
      },
    },
    {
      link: "user_get_token_password",
    },
    "user_get_token_password",
    undefined,
    undefined,
    !ws
  );

  const deResetForm = {
    _links: {
      user_update_password: ws?._links["user_update_password"]?.replace(
        ":tokenValue",
        tokenValue
      ),
    },
  };

  return (
    <Grid container sx={{ height: "100vh" }} dir={direction}>
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        p={2}
        sx={{ backgroundColor: "white" }}
        className="login-image"
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: { xs: "none", sm: "block" },
            backgroundImage: `url(${require("Kcafi/img/auth.png")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "20px",
          }}
        ></Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        component={Paper}
        elevation={0}
        square
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 10,
        }}
        className="login-form"
      >
        <Typography
          variant="h2"
          sx={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 32,
            lineHeight: "120%",
            color: themeColors.darkBlue,
            mb: 4,
          }}
          className="login-title"
        >
          {t("pages.new_password.title")}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 18,
            lineHeight: "150%",
            color: themeColors.deepTurquoise,
            mb: 4,
          }}
          className="login-subtitle"
        >
          {t("pages.new_password.subtitle")}
        </Typography>
        {isLoadingToken ? (
          <Box sx={{ height: 40 }}>
            <Fade
              in={true}
              style={{
                transitionDelay: "800ms",
              }}
              unmountOnExit
            >
              <CircularProgress sx={{ color: primaryColor().primary }} />
            </Fade>
          </Box>
        ) : !data || data?.expired ? (
          <Alert sx={{ mb: 4 }} variant="outlined" severity="error">
            {t("pages.new_password.token_not_found_or_expired")}
          </Alert>
        ) : (
          deResetForm?._links?.user_update_password && (
            <ResetForm de={deResetForm} />
          )
        )}

        <Typography
          variant="body2"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "150%",
            color: themeColors.darkBlue,
            mt: 2,
            textAlign: "center",
          }}
        >
          {t("pages.new_password.already_have_account")}{" "}
          <Link href="/login" underline="always">
            {t("pages.new_password.login")}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NewPasswordPage;

const ResetForm = ({ de }: any) => {
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const { t } = useTranslation("common");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const {
    onSubmit,
    data: serverReply,
    isLoading: isLoadingChange,
    isSuccess,
  } = useCommand(de, "user_update_password", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setPassword("");
    setConfirmPassword("");
    setError("");
    setSuccess(t("pages.new_password.success_update_password"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form dir={direction}>
      {error && (
        <Alert sx={{ mb: 4 }} variant="outlined" severity="error">
          {error}
        </Alert>
      )}
      {success && (
        <Alert sx={{ mb: 4 }} variant="outlined" severity="success">
          {success}
        </Alert>
      )}

      <DirTheme>
        <TextField
          sx={{ mb: 6 }}
          label={t("pages.new_password.password")}
          variant="outlined"
          type={showPassword ? "text" : "password"}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          value={password}
        />
      </DirTheme>

      <DirTheme>
        <TextField
          sx={{ mb: 6 }}
          label={t("pages.new_password.confirm_password")}
          variant="outlined"
          type={showPassword ? "text" : "password"}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            setConfirmPassword(event.target.value);
          }}
          error={
            confirmPassword !== undefined &&
            confirmPassword !== "" &&
            password !== confirmPassword
          }
          value={confirmPassword}
        />
      </DirTheme>
      <Button
        sx={{
          background: primaryColor().primary,
          borderRadius: "100px",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          ":hover": {
            background: primaryColor().primary,
          },
          mb: 6,
          boxShadow: `0px 11px 64px ${themeColors.black007}`,
        }}
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          setError(undefined);
          setSuccess(undefined);
          onSubmit({
            password: password,
          } as any);
        }}
        disabled={isLoadingChange || !password || password !== confirmPassword}
      >
        {isLoadingChange ? (
          <CircularProgress size={20} sx={{ color: themeColors.white }} />
        ) : (
          t("pages.new_password.save_password")
        )}
      </Button>
    </form>
  );
};
