import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import { primaryColor, themeColors } from "..";
import { useTranslation } from "react-i18next";
import React from "react";
import { useOperation } from "Components/api";

export const StockItem = ({
  stockItem,
  imageSrc,
  title,
  quantity,
  hasDevider,
  removeProduct,
  soldeQuantity,
  availableQuantity,
}: any) => {
  const { t } = useTranslation("common");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { data: rows, isLoading } = useOperation<
    {
      id: number;
      date: any;
      action: string;
      totalIn: number;
      totalOut: number;
    }[]
  >(
    stockItem,
    {
      link: "stock_history",
    },
    "stock_history:"+stockItem.productId,
    undefined,
    undefined,
    !stockItem
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () => rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, rowsPerPage, rows]
  );

  return (
    <Box
      sx={{
        backgroundColor: themeColors.white,
        padding: "10px 5px",
        display: "flex",
        alignItems: "center",
        position: "relative",
        borderRadius: "10px",
        width: "97%",
        borderBottom: hasDevider
          ? "1px solid " + themeColors.black007
          : "unset",
      }}
      mb={1}
    >
      {removeProduct && (
        <IconButton
          sx={{
            backgroundColor: primaryColor().primary,
            color: themeColors.white,
            ":hover": {
              backgroundColor: primaryColor().primary,
            },
            width: "24px",
            height: "24px",
            position: "absolute",
            right: -4,
            top: -4,
          }}
          onClick={removeProduct}
        >
          <Close />
        </IconButton>
      )}
      <Accordion
        sx={{
          width: "100%",
          border: 0,
          boxShadow: "unset",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box
            sx={{
              width: { xs: "60px", sm: "60px", md: "60px" },
              height: { xs: "60px", sm: "60px", md: "60px" },
              marginRight: "15px",
              marginLeft: "15px",
              borderRadius: "10px",
              border: "1px solid " + themeColors.midnightBlueLight,
              backgroundColor: themeColors.gray,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={imageSrc}
              alt="Product"
              width="60px"
              style={{ borderRadius: "10px" }}
            />
          </Box>

          <Box width="65%">
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "28px",
              }}
            >
              {title}
            </Typography>

            <Grid container>
              <Grid item xs={12} sm={12} md={4}>
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "28px",
                      color: themeColors.deepTurquoise,
                      whiteSpace: "nowrap",
                    }}
                    mr={1}
                  >
                    {t("pages.session.stock.total_qte")} : {quantity}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "28px",
                      }}
                    ></Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {soldeQuantity && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "28px",
                        color: themeColors.red,
                        whiteSpace: "nowrap",
                      }}
                      mr={1}
                    >
                      {t("pages.session.stock.sold_qte")}: {soldeQuantity}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "28px",
                        }}
                      ></Typography>
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {availableQuantity && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "28px",
                        color: themeColors.green,
                        whiteSpace: "nowrap",
                      }}
                      mr={1}
                    >
                      {t("pages.session.stock.available_qte")}:{" "}
                      {availableQuantity}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "28px",
                        }}
                      ></Typography>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer
            component={Paper}
            sx={{
              width: "100%",
              border: 0,
              boxShadow: "unset",
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("pages.session.stock.date")}</TableCell>
                  <TableCell>{t("pages.session.stock.action")}</TableCell>
                  <TableCell align="right">
                    {t("pages.session.stock.in")}
                  </TableCell>
                  <TableCell align="right">
                    {t("pages.session.stock.out")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading && visibleRows?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {new Date(row.date?.date)?.toLocaleString()}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "28px",
                          color:
                            row.action === "buy"
                              ? themeColors.green
                              : themeColors.red,
                        }}
                      >
                        {row.action}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "28px",
                          color: themeColors.green,
                        }}
                      >
                        {row.action === "buy" ? `+${row.totalIn}` : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "28px",
                          color: themeColors.red,
                        }}
                      >
                        {row.action === "sell" ? `-${row.totalOut}` : "--"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
