import {
  Button,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Alert,
  Snackbar,
  Paper,
  Badge,
  Grid,
} from "@mui/material";
import { useAppContext } from "App";
import { useCommand, useOperation } from "Components/api";
import ResponsiveDrawer from "Kcafi/ResponsiveDrawer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import DirTheme from "../DirTheme";
import { primaryColor, themeColors } from "..";

export const InvoicesPage = () => {
  const { t } = useTranslation("common");
  const appQueryClient = useQueryClient();
  const { myDetails } = useAppContext();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const { data } = useOperation<any>(
    myDetails,
    {
      link: "woocommerce_subscription",
    },
    "woocommerce_subscription",
    undefined,
    undefined,
    !myDetails
  );
  const {
    onSubmit,
    data: serverReply,
    isLoading: isLoadingInitPayment,
    isSuccess,
  } = useCommand(myDetails, "initiate_payment", (e: any) => {
    setError(t(e?.detail));
  });

  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setSuccess(t("Payment initiated successfully"));
    appQueryClient.invalidateQueries(["mySessions"]);
    if ((serverReply as any)?.payUrl) {
      window.location.href = (serverReply as any)?.payUrl;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleCloseAlert = () => {
    setError("");
    setSuccess("");
  };

  const { subscribion, subscriptionStatus, paymentHistory } = data || {};

  const [nbrMonths, setNbrMonths] = useState<number>(1);
  return (
    <ResponsiveDrawer title={`${t("pages.subscription.title")}`}>
      <DirTheme>
        <Snackbar
          open={!!error || !!success}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={error ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {error || success}
          </Alert>
        </Snackbar>
        <Box textAlign="center" my={4}>
          {subscribion && !subscribion.active && (
            <Paper
              elevation={3}
              sx={{
                padding: 4,
                borderRadius: "8px",
                backgroundColor: "#f5f5f5",
                textAlign: "center",
                maxWidth: "500px",
                margin: "0 auto",
              }}
            >
              <Typography variant="h6" gutterBottom>
                {`${t("pages.subscription.NoActiveSubscription")}`}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {`${t(
                  "pages.subscription.Please_subscribe_to_access_the_services"
                )}`}
              </Typography>

              <Grid item xs={12} mt={4}>
                <Button
                  sx={{
                    border:
                      nbrMonths === 1
                        ? `1px solid ${primaryColor().primary}`
                        : `1px solid ${themeColors.deepTurquoise}`,
                    borderRadius: "82px",
                    backgroundColor: themeColors.white,
                    color:
                      nbrMonths === 1
                        ? primaryColor().primary
                        : themeColors.deepTurquoise,
                    fontStyle: "normal",
                    fontWeight: 600,
                    pl: 2,
                    pr: 2,
                    mb: 1,
                  }}
                  onClick={() => setNbrMonths(1)}
                  fullWidth
                >
                  <Box ml={1} mr={1}>
                    {t("pages.subscription.1_month")}
                  </Box>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  sx={{
                    border:
                      nbrMonths === 3
                        ? `1px solid ${primaryColor().primary}`
                        : `1px solid ${themeColors.deepTurquoise}`,
                    borderRadius: "82px",
                    backgroundColor: themeColors.white,
                    color:
                      nbrMonths === 3
                        ? primaryColor().primary
                        : themeColors.deepTurquoise,
                    fontStyle: "normal",
                    fontWeight: 600,
                    pl: 2,
                    pr: 2,
                    mb: 1,
                  }}
                  onClick={() => setNbrMonths(3)}
                  fullWidth
                >
                  <Box ml={1} mr={1}>
                    {t("pages.subscription.3_months")}
                  </Box>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  sx={{
                    border:
                      nbrMonths === 6
                        ? `1px solid ${primaryColor().primary}`
                        : `1px solid ${themeColors.deepTurquoise}`,
                    borderRadius: "82px",
                    backgroundColor: themeColors.white,
                    color:
                      nbrMonths === 6
                        ? primaryColor().primary
                        : themeColors.deepTurquoise,
                    fontStyle: "normal",
                    fontWeight: 600,
                    pl: 2,
                    pr: 2,
                    mb: 1,
                  }}
                  onClick={() => setNbrMonths(6)}
                  fullWidth
                >
                  <Box ml={1} mr={1}>
                    {t("pages.subscription.6_months")}
                  </Box>
                </Button>
              </Grid>
              <Button
                onClick={() => onSubmit({
                  nbrMonths: nbrMonths
                })}
                disabled={isLoadingInitPayment}
                variant="contained"
                color="primary"
                size="large"
                sx={{ marginTop: 2 }}
              >
                {`${t("pages.subscription.SubscribeNow")}`}
              </Button>
            </Paper>
          )}
        </Box>

        {subscribion && (
          <Box mx={4}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 500 }}>
              {`${t("pages.subscription.SubscriptionInformation")}`}
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={`${t("pages.subscription.SubscriptionID")}`}
                  secondary={subscribion.id}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${t("pages.subscription.SubscribedAt")}`}
                  secondary={new Date(
                    subscribion.subscribedAt.date
                  ).toLocaleString()}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${t("pages.subscription.ExpireAt")}`}
                  secondary={new Date(
                    subscribion.expireAt.date
                  ).toLocaleString()}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${t("pages.subscription.subscriptionStatus")}`}
                  secondary={
                    <Badge
                      sx={{
                        marginTop: 2,
                        mx: 3,
                      }}
                      badgeContent={
                        <Typography
                          variant="caption"
                          gutterBottom
                          sx={{
                            fontWeight: 500,
                            lineHeight: 18,
                            paddingTop: "2px",
                          }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {subscriptionStatus
                            ? `${t("pages.subscription.active")}`
                            : `${t("pages.subscription.inactive")}`}
                        </Typography>
                      }
                      color={subscriptionStatus ? "success" : "error"}
                    ></Badge>
                  }
                />
              </ListItem>
            </List>
            {paymentHistory?.length !== 0 && (
              <>
                {" "}
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  style={{ marginTop: "16px" }}
                >
                  {`${t("pages.subscription.paymentHistory")}`}
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{`${t(
                          "pages.subscription.paymentID"
                        )}`}</TableCell>
                        <TableCell>{`${t(
                          "pages.subscription.amount"
                        )}`}</TableCell>
                        <TableCell>{`${t(
                          "pages.subscription.status"
                        )}`}</TableCell>
                        <TableCell>{`${t(
                          "pages.subscription.createdAt"
                        )}`}</TableCell>
                        <TableCell>{`${t(
                          "pages.subscription.updatedAt"
                        )}`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paymentHistory.map((payment: any) => (
                        <TableRow key={payment.id}>
                          <TableCell>{payment.id}</TableCell>
                          <TableCell>
                            {(payment.amount / 1000).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                color:
                                  payment.status === "pending"
                                    ? "orange"
                                    : payment.status === "failed"
                                    ? "red"
                                    : payment.status === "completed"
                                    ? "green"
                                    : payment.status === "canceled"
                                    ? "grey"
                                    : payment.status === "refunded"
                                    ? "blue"
                                    : "black",
                                fontWeight: "bold",
                              }}
                            >
                              {payment.status}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {new Date(payment.createdAt.date).toLocaleString()}
                          </TableCell>
                          <TableCell>
                            {new Date(payment.updatedAt.date).toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
        )}
      </DirTheme>
    </ResponsiveDrawer>
  );
};
