import { fetchAxios, resolveApiUrl, useAxios } from "Components/api";
import { colors } from "Kcafi/Pages";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

export type UserDetails = {
  id: string /*varchar*/;
  email: string /*varchar*/;
  username: string /*varchar*/;
  nom: string;
  prenom: string;
  uid: string | null;
  avatarUrl: string | null;
  roles: string[];
  hasActiveSession?: boolean;
  activeSessionTotalTickets?: number;
  activeSessionId?: string;
  activityThemeColor?: string;
  activityName?: string;
  activityLogo: string | null;
  permissions?: {
    Caisse: { ReadCaisse: boolean; WriteCaisse: boolean };
    Products: { ReadProducts: boolean; WriteProducts: boolean };
    Sessions: { ReadSessions: boolean; WriteSessions: boolean };
    Settings: { ReadSettings: boolean; WriteSettings: boolean };
    Stats: { ReadStats: boolean; WriteStats: boolean };
  };
  showAllCategory?: boolean;
  showTopCategory?: boolean;
  showFavoriteCategory?: boolean;
  mutedCaisse?: boolean;
  enableLock?: boolean;
  locked?: boolean;
  _links: any;
};

type AuthContextType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  isLoadingDetails: boolean;
  user: UserDetails;
  error: Error | null;
  signin: (username: string, password: string, callback: any) => void;
  signout: (_: any) => void;
};

export const USER_ANONYMOUS: UserDetails = {
  id: "Anonymous",
  email: "Anonymous",
  username: "Anonymous",
  nom: "Anonymous",
  prenom: "Anonymous",
  uid: null,
  avatarUrl: null,
  activityLogo: null,
  roles: [],
  activityThemeColor: "orange",
  _links: {},
};

export const USER_ADMIN: UserDetails = {
  id: "Admin",
  email: "admin@kcafi.com",
  username: "admin",
  nom: "admin",
  prenom: "admin",
  uid: null,
  avatarUrl: null,
  activityLogo: null,
  roles: ["ROLE_ADMIN"],
  _links: {},
};

const AuthContext = React.createContext<AuthContextType>({
  error: null,
  isAuthenticated: true,
  isLoading: false,
  isLoadingDetails: true,
  user: USER_ANONYMOUS,
  signin: (_user: string, _pass: string, _: any) => {},
  signout: (_: any) => {},
});

export const ProvideAuth = ({ children }: any) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext<AuthContextType>(AuthContext);

export const useProvideAuth = () => {
  const appQueryClient = useQueryClient();
  const axiosApi = useAxios();
  const [currentUser, setCurrentUser] =
    React.useState<UserDetails>(USER_ANONYMOUS);

  const { data: myDetails = USER_ANONYMOUS, isLoading: isLoadingDetails } =
    useQuery<any, Error>(
      "My:Details",
      () => fetchAxios<any>(axiosApi, resolveApiUrl() + "/me", "MyDetails"),
      { retry: 1 }
    );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  React.useEffect(() => {
    if (currentUser === myDetails) return;
    setCurrentUser(myDetails);
    const f = colors?.find((c) => c.name === myDetails?.activityThemeColor);
    if (f) {
      localStorage.setItem("activityThemeColor", f.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, myDetails]);

  return {
    error,
    user: currentUser,
    isLoading,
    isLoadingDetails,
    isAuthenticated: currentUser && currentUser.id !== "Anonymous",
    signin: (username: string, password: string, cb: any) => {
      setIsLoading(true);
      axios
        .post(resolveApiUrl() + "/login_check", { username, password })
        .then(({ data: { token } }) => {
          setIsLoading(false);
          if (token) {
            localStorage.setItem("token", token);
            appQueryClient.invalidateQueries(["My:Details"]);
          } else {
            console.warn("Expected token in server reply");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response && err.response.data && err.response.data.message) {
            console.log(err.response.data.message);
            setError(err.response.data.message);
          } else {
            if (err.response.data.detail) {
              setError(err.response.data.detail);
            } else {
              setError(err.message);
            }
          }
          return false;
        });

      cb();
    },
    signout: (cb: any) => {
      localStorage.removeItem("token");
      appQueryClient.invalidateQueries(["My:Details"]);
      setCurrentUser(USER_ANONYMOUS);
      cb();
    },
  };
};
