import { Box, IconButton, Typography } from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import { useAppContext } from "App";
import { CartManager } from "./CartManager";
import { primaryColor, themeColors } from "..";

export const CartItem = ({
  productId,
  imageSrc,
  title,
  price,
  quantity,
  hasDevider,
}: any) => {
  const { cartManager, setCartManager } = useAppContext();

  return (
    <Box
      sx={{
        boxShadow: "0px 10.55px 63.35px 0px "+themeColors.black007,
        padding: "10px 5px",
        display: "flex",
        alignItems: "center",
        position: "relative",
        borderRadius: "10px",
        width: "97%",
        borderBottom: hasDevider ? "1px solid "+themeColors.black007 : "unset",
      }}
      mb={1}
    >
      <IconButton
        sx={{
          backgroundColor: primaryColor().primary,
          color: themeColors.white,
          ":hover": {
            backgroundColor: primaryColor().primary,
          },
          width: "24px",
          height: "24px",
          position: "absolute",
          right: -4,
          top: -4,
        }}
        onClick={() => {
          cartManager.removeProduct(productId);
          setCartManager(new CartManager());
        }}
      >
        <Close />
      </IconButton>
      <Box
        sx={{
          width: { xs: "60px", sm: "60px", md: "60px" },
          height: { xs: "60px", sm: "60px", md: "60px" },
          marginRight: "15px",
          borderRadius: "10px",
          border: "1px solid "+themeColors.midnightBlueLight,
          backgroundColor: themeColors.gray,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={imageSrc}
          alt="Product"
          width="60px"
          style={{ borderRadius: "10px" }}
        />
      </Box>

      <Box width="65%">
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "28px",
          }}
        >
          {title}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "28px",
              color: primaryColor().primary,
              whiteSpace: "nowrap",
            }}
            mr={1}
          >
            {price}
          </Typography>
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() => {
                cartManager.decreaseQuantity(productId, 1);
                setCartManager(new CartManager());
              }}
            >
              <Remove />
            </IconButton>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "28px",
                mx: "10px",
              }}
            >
              {quantity}
            </Typography>
            <IconButton
              onClick={() => {
                cartManager.increaseQuantity(productId, 1);
                setCartManager(new CartManager());
              }}
            >
              <Add />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
