import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from "react";
import { useQuery } from "react-query";
import {
  fetchAxios,
  OperationEntry,
  resolveApiUrl,
  UrlParams,
  useAxios,
  useOperation,
  WebServiceComponent
} from ".";

type ISelectOperation = OperationEntry & SelectDataElementOptions;

type ISelectedDataElements = {
  [name: string]: any;
};

export type IWebService = WebServiceComponent & {
  RS_APP_LABEL: string;
  APP_API_URL: string;
  APP_URL: string;
  SYN_LARAVEL_URL: string;
  RS_ADMIN_URL: string;
};

type IWebServiceState = {
  error: any;
  deWebService?: IWebService;
  selectedDataElements: ISelectedDataElements;
  showSelectedDataElements: boolean;
  showMockData: boolean;
};

export type SelectDataElementOptions = {
  parentKey?: string;
  key: string;
  link: string;
  dataElementName: string;
  searchKey: string;
  searchValue?: string;
};

type IWebServiceMethods = {
  clearErrors(): void;
  setSelectDeOperation(opts: SelectDataElementOptions): void;
  clearSelectedDe(key: string): void;
  setShowSelectedDataElements(show: boolean): void;
  setShowMockData(show: boolean): void;
};

type IWebServiceContext = IWebServiceState & IWebServiceMethods;

export const WebServiceContext = createContext<IWebServiceContext>(
  {} as IWebServiceContext
);

export const initWebServiceState: IWebServiceState = {
  error: undefined,
  selectedDataElements: {},
  showSelectedDataElements: false,
  showMockData: false,
};

export function useWebServiceContext() {
  return useContext(WebServiceContext);
}

export function useSelectedItem<T>(name: string) {
  const ctx = useContext(WebServiceContext);
  const [selectedItem, setSelectedItem] = useState(
    ctx?.selectedDataElements[name] as unknown as T
  );

  useEffect(() => {
    setSelectedItem(ctx?.selectedDataElements[name]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx?.selectedDataElements[name]]);

  // if (!ctx || !ctx.selectedDataElements) return undefined;
  return selectedItem; // ctx?.selectedDataElements[name] as unknown as T;
}

type IWebServiceReducer = (
  state: IWebServiceState,
  action: any
) => IWebServiceState;

export type IWebServiceReducerMessageType =
  | "CLEAR_ERROR"
  | "SET_SELECTED_WS_DE"
  | "CLEAR_SELECTED_WS_DE"
  | "SET_SELECTED_LOCAL_APP_CONFIG_ID";
export type IWebServiceReducerMessageWithPayload = {
  type: IWebServiceReducerMessageType;
  payload: any;
};

type IWebServiceSelectedDeReducerMessage = {
  type: IWebServiceReducerMessageType;
  key: string;
};

type ISelectEntyItemPayload = {
  selectOperation: ISelectOperation;
  data: any;
};

// type ISelectedLocalAppConfigPayload = {
//   LocalWorkspaceId: string;
//   data: any;
// };

type IReducerMessage =
  | IWebServiceReducerMessageWithPayload
  | IWebServiceSelectedDeReducerMessage;

export function webServiceReducer(
  state: IWebServiceState,
  message: IReducerMessage
): IWebServiceState {
  switch (message.type) {
    case "CLEAR_ERROR":
      return { ...state, error: undefined };

    case "CLEAR_SELECTED_WS_DE":
      const m = message as IWebServiceSelectedDeReducerMessage;
      if (m.key && m.key !== "" && !!state.selectedDataElements[m.key]) {
        state.selectedDataElements[m.key] = undefined;
        return { ...state, selectedDataElements: state.selectedDataElements };
      } else {
        return state;
      }

    case "SET_SELECTED_WS_DE":
      const { selectOperation, data }: ISelectEntyItemPayload = (
        message as IWebServiceReducerMessageWithPayload
      ).payload;
      const deList: Array<any> = data[selectOperation.dataElementName] || [];
      if (deList.length === 1)
        state.selectedDataElements[selectOperation.key] = deList.at(0);
      else state.selectedDataElements[selectOperation.key] = undefined;

      return { ...state, selectedDataElements: state.selectedDataElements };

    default: {
      return state;
    }
  }
}
export type Wrapper = { children: JSX.Element | JSX.Element[] | string };

type WebServiceContextProviderProps = Wrapper & {
  // de?: object;
};

export function WebServiceContextProvider({
  children,
}: WebServiceContextProviderProps) {
  const [selectOperation, setSelectDeOperation] = useState<ISelectOperation>({
    isDisabled: true,
  } as ISelectOperation);

  const [deForSelection, setDeForSelection] = useState<any>();
  const [showSelectedDataElements, setShowSelectedDataElements] =
    useState<boolean>();

  const [showMockData, setShowMockData] = useState<boolean>(
    localStorage.getItem("showMocks") === "show"
  );

  useEffect(() => {
    const x = showMockData ? "show" : "hide";
    localStorage.setItem("showMocks", x);
  }, [showMockData]);

  const [state, dispatch] = useReducer(
    webServiceReducer as IWebServiceReducer,
    initWebServiceState as IWebServiceState
  );
  const axios = useAxios();
  const { data: deWebService, isLoading } = useQuery<IWebService, Error>(
    "WebServiceComponent",
    () => fetchAxios<IWebService>(axios, resolveApiUrl(), "WebServiceComponent")
  );

  const selectUrlParams: UrlParams = {
    [selectOperation.searchKey]: selectOperation.searchValue,
  };
  const selectDisabled =
    !selectOperation || !selectOperation.searchValue || isLoading;
  const selectOperationKey = `SelectOperation:${selectOperation.key}`;

  const { data: selectedDataElement } = useOperation(
    deForSelection,
    selectOperation,
    selectOperationKey,
    selectUrlParams,
    {
      handleInvalidOperation: (de: any, operation: OperationEntry) => {
        console.log("TODO: Add webserice error - invalid operatiopn", {
          de,
          operation,
        });
        return true;
      },
    },
    selectDisabled
  );

  useEffect(() => {
    if (!deWebService) return;
    setDeForSelection(deWebService);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deWebService]);

  useEffect(() => {
    if (selectOperation?.parentKey && selectOperation?.parentKey !== "") {
      const parentValue = state.selectedDataElements[selectOperation.parentKey];
      setDeForSelection(parentValue);
    } else {
      setDeForSelection(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOperation]);

  useEffect(() => {
    if (!selectedDataElement) return;
    dispatch({
      type: "SET_SELECTED_WS_DE",
      payload: { selectOperation, data: selectedDataElement },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataElement]);

  const clearErrors = () => dispatch({ type: "CLEAR_ERROR" });
  const clearSelectedDe = (key: string) =>
    dispatch({ type: "CLEAR_SELECTED_WS_DE", key });
  return (
    <WebServiceContext.Provider
      value={{
        ...state,
        deWebService,
        clearErrors,
        setSelectDeOperation,
        clearSelectedDe,
        showSelectedDataElements: showSelectedDataElements || false,
        setShowSelectedDataElements,
        showMockData: showMockData || false,
        setShowMockData,
      }}
    >
      {children}
    </WebServiceContext.Provider>
  );
}

export type FallbackedWrapper = Wrapper & { fallback?: () => JSX.Element };
export function createWrapper<T extends object>(
  context: React.Context<T>,
  consumer: (context: T) => boolean
) {
  return function ConditionalWrapper({
    children,
    fallback: Fallback,
  }: FallbackedWrapper) {
    const contextValue = useContext(context);
    const condition = useMemo(() => consumer(contextValue), [contextValue]);
    return (
      <React.Fragment>
        {condition ? children : Fallback && <Fallback />}
      </React.Fragment>
    );
  };
}

export const createLogicalWrapper = (
  consumer: (ctx: IWebServiceContext) => boolean
) => createWrapper(WebServiceContext, consumer);
