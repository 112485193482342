import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Icon,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Edit, MoreVert } from "@mui/icons-material";
import { ProductSTD } from ".";
import { useCommand } from "Components/api";
import { ErrorToast } from "../ErrorToast";
import { useQueryClient } from "react-query";
import { primaryColor, themeColors } from "..";
import { NumericFormatCustom } from "./NumericFormatCustom";
import ImageUploader from "./ImageUploader";
import { useTranslation } from "react-i18next";
import DirTheme from "../DirTheme";
import { DeleteProductDialog } from "./DeleteProductDialog";
import { AddToFavoritProductDialog } from "./AddToFavoritProductDialog";

type ProductProps = {
  product: ProductSTD;
};

export const Product = ({ product }: ProductProps) => {
  const { t } = useTranslation("common");
  const appQueryClient = useQueryClient();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState<string>(product.title);
  const [description, setDescription] = useState<string>(product.description);
  const [sellPrice, setSellPrice] = useState<any>(product.sellPrice);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(
    product,
    product.added ? "my_product_update" : "my_product_create",
    (e: any) => {
      setError(t(e?.detail));
    }
  );
  useEffect(() => {
    if (!isSuccess || isLoading) return;
    setError("");
    setSuccess(t("pages.products.product.product_saved") + ": " + title);
    handleCloseModal();
    appQueryClient.invalidateQueries(["products_std_by_category"]);
    appQueryClient.invalidateQueries(["myProducts"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply, isLoading]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };

  const uploadUrl = product._links.product_update_image;

  return (
    <>
      {product.custom && !product.added ? (
        <Box
          sx={{
            height: {
              xs: "122px",
              sm: "132px",
              md: "160px",
            },
            background: themeColors.white,
            borderRadius: "8px",
            position: "relative",
            border: `1px solid ${themeColors.gray}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <Add
            style={{
              width: 54,
              height: 54,
              color: themeColors.midnightBlueMiddle,
            }}
            onClick={handleOpenModal}
          />
        </Box>
      ) : (
        <Box
          sx={{
            height: {
              xs: "122px",
              sm: "132px",
              md: "160px",
            },
            background: themeColors.white,
            borderRadius: "8px",
            position: "relative",
            border: `1px solid ${themeColors.gray}`,
          }}
        >
          <ErrorToast
            error={error}
            success={success}
            handleClose={handleClose}
          />

          <Box
            sx={{
              height: {
                xs: "60%",
                sm: "60%",
                md: "65%",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "5px",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              borderBottom: `1px solid ${themeColors.gray}`,
              //background: themeColors.grayLinearGrad,
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "72px",
                  sm: "72px",
                  md: "92px",
                },
              }}
            >
              <img
                src={
                  product.img ||
                  `https://fakeimg.pl/600x400?text=${product.title}`
                }
                alt="Product"
                style={{
                  display: "block",
                  userSelect: "none",
                  WebkitTapHighlightColor: themeColors.transparent,
                  background: themeColors.white,
                  width: "100%",
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              paddingLeft: 1,
              paddingRight: 1,
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: { xs: "10px", sm: "10px", md: "12px" },
                  lineHeight: "16px",
                  leadingTrim: "both",
                  textEdge: "cap",
                  color: themeColors.darkBlue,
                  textOverflow: "ellipsis",
                  maxWidth: 150,
                  overflow: "hidden",
                }}
              >
                {product.title}
              </Typography>
              {product.sellPrice && product.type !== "achat" ? (
                <Typography
                  variant="caption"
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: { xs: "10px", sm: "10px", md: "12px" },
                    lineHeight: "22px",
                    leadingTrim: "both",
                    textEdge: "cap",
                    color: primaryColor().primary,
                  }}
                >
                  {t("pages.currency")}{" "}
                  {product.sellPrice ? product.sellPrice.toFixed(2) : "--"}
                </Typography>
              ) : (
                <></>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "5px",
              }}
            >
              {product.added ? (
                <>
                  <Icon
                    aria-controls="menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    sx={{
                      paddingTop: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <MoreVert />
                  </Icon>
                </>
              ) : (
                <Icon
                  sx={{
                    border: "1px solid " + primaryColor().primary,
                    borderRadius: "5px",
                    width: 26,
                    height: 26,
                    color: primaryColor().primary,
                    fontStyle: "normal",
                    fontWeight: 600,
                    boxShadow: "0px 11px 64px " + themeColors.black007,
                    cursor: "pointer",
                  }}
                  onClick={handleOpenModal}
                >
                  <Add
                    sx={{
                      color: primaryColor().primary,
                    }}
                  />
                </Icon>
              )}

              <Menu
                id="menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    handleOpenModal();
                  }}
                >
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  {t("pages.products.product.edit")}
                </MenuItem>
                <DeleteProductDialog product={product} />
                <Divider component="li" />
                <AddToFavoritProductDialog product={product} />
              </Menu>
            </Box>
          </Box>
        </Box>
      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            border: 0,
            boxShadow: `0px 11px 64px ${themeColors.black007}`,
            p: 4,
            outline: 0,
          }}
        >
          <DirTheme>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                mb: 4,
              }}
            >
              {t(
                product.added
                  ? "pages.products.product.update_product"
                  : "pages.products.product.add_new_product"
              )}
            </Typography>
          </DirTheme>
          <ImageUploader
            product={product}
            title={title}
            description={description}
            sellPrice={sellPrice}
            uploadUrl={uploadUrl}
          />

          <DirTheme>
            <TextField
              sx={{ mb: 4, fontStyle: "normal" }}
              label={t("pages.products.product.title")}
              variant="outlined"
              fullWidth
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              value={title}
              size="small"
            />
          </DirTheme>

          <DirTheme>
            <TextField
              sx={{ mb: 4, fontStyle: "normal" }}
              label={t("pages.products.product.description")}
              variant="outlined"
              fullWidth
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              value={description}
              multiline
              rows={4}
              size="small"
            />
          </DirTheme>

          {product.type !== "achat" && (
            <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
              <DirTheme>
                <TextField
                  sx={{
                    fontStyle: "normal",
                  }}
                  label={t("pages.products.product.amount")}
                  value={sellPrice}
                  onChange={(event) => {
                    setSellPrice(event.target.value);
                  }}
                  name="numberformat"
                  id="formatted-numberformat-input"
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  variant="outlined"
                  fullWidth
                />
              </DirTheme>
            </FormControl>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                sx={{
                  border: "1px solid " + primaryColor().primary,
                  borderRadius: "82px",
                  backgroundColor: themeColors.white,
                  color: primaryColor().primary,
                  fontStyle: "normal",
                  fontWeight: 600,
                  marginRight: 1,
                }}
                fullWidth
                onClick={handleCloseModal}
              >
                {t("pages.products.product.cancel")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{
                  borderRadius: "82px",
                  backgroundColor: primaryColor().primary,
                  ":hover": {
                    background: primaryColor().primary,
                  },
                  color: themeColors.white,
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  !title ||
                  (!sellPrice && product.type !== "achat") ||
                  isLoading
                }
                onClick={() => {
                  onSubmit({
                    productStd: product.id,
                    title: title,
                    description: description,
                    sellPrice: sellPrice,
                    img: product.img,
                    _method: product.added ? "PUT" : "POST",
                  } as any);
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: themeColors.white }}
                  />
                ) : (
                  t("pages.products.product.save")
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
