import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useAppContext } from "App";
import { useOperation } from "Components/api";
import ResponsiveDrawer from "Kcafi/ResponsiveDrawer";
import { useHistory, useParams } from "react-router-dom";
import { SessionType } from "../DashboardPage";
import { ArrowBackIos } from "@mui/icons-material";
import { primaryColor, themeColors } from "..";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { DataNotFound } from "../DataNotFound";
import { useTranslation } from "react-i18next";
import { UnlockDialog } from "../Caisse/UnlockDialog";

type TicketItem = {
  productId: number;
  productTitle: string;
  productSellPrice: number;
  quantity: number;
  total: number;
};

type Ticket = {
  id: number;
  internalId: string;
  workerId: number | null;
  workerName: string | null;
  workerSurname: string | null;
  createdById: number;
  createdByName: string;
  createdBySurname: string;
  status: string;
  total: number;
  items: TicketItem[];
  _links: {
    ticket_details: string;
    ticket_return: string;
  };
};

export const SessionTicketDetailsPage = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const { sessionId, ticketId } = useParams<any>();
  const { myDetails } = useAppContext();
  const { data: session, isLoading: isLoadingSessions } =
    useOperation<SessionType>(
      {
        _links: {
          mySessionDetails: myDetails?._links["mySessionDetails"]?.replace(
            ":sessionId",
            sessionId
          ),
        },
      },
      {
        link: "mySessionDetails",
      },
      "mySessionDetails" + sessionId,
      {} as any,
      undefined,
      !myDetails
    );
  const { data: ticket, isLoading: isLoadingTicket } = useOperation<Ticket>(
    {
      _links: {
        session_ticket_details: session?._links[
          "session_ticket_details"
        ]?.replace(":ticketId", ticketId),
      },
    },
    {
      link: "session_ticket_details",
    },
    "session_ticket_details" + ticketId,
    undefined,
    undefined,
    !session
  );
  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    documentTitle: ticket?.internalId,
    content: () => componentRef.current,
    pageStyle: `@media print {
      @page {
        size: 75mm 140mm;
        margin: 0;
      }
    }`,
  });

  return (
    <ResponsiveDrawer title={t("pages.session.ticket_details.ticket_details")}>
      <UnlockDialog />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {isLoadingSessions || isLoadingTicket ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress sx={{ color: primaryColor().primary }} />
          </Box>
        ) : ticket ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"center"}
            mt={12}
          >
            <Button
              sx={{
                border: "1px solid " + primaryColor().primary,
                borderRadius: "82px",
                backgroundColor: themeColors.white,
                color: primaryColor().primary,
                fontStyle: "normal",
                fontWeight: 600,
                pl: 2,
                pr: 2,
                mb: 1,
              }}
              onClick={() => history.push(`/sessions/${sessionId}/tickets`)}
              dir="ltr"
            >
              <ArrowBackIos /> {t("pages.session.ticket_details.back")}
            </Button>

            <Card
              sx={{
                boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
                width: { xs: "240px", md: "340px" },
                padding: "15px",
                borderRadius: "10px",
                marginBottom: "25px",
              }}
              ref={componentRef}
            >
              <Box
                sx={{
                  marginBottom: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      lineHeight: "28px",
                      letterSpacing: 0,
                    }}
                  >
                    {t("pages.caisse.payment.order")}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      lineHeight: "28px",
                      letterSpacing: 0,
                      textAlign: "left",
                      color: themeColors.midnightBlueLight,
                      whiteSpace: "nowrap",
                    }}
                  >
                    #{ticket.internalId}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      lineHeight: "28px",
                      letterSpacing: 0,
                    }}
                  >
                    {t("pages.caisse.payment.affected_to")}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      lineHeight: "28px",
                      letterSpacing: 0,
                      color: themeColors.midnightBlueLight,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {!ticket.workerName && !ticket.workerSurname
                      ? "--"
                      : `${ticket.workerName} ${ticket?.workerSurname}`}
                  </Typography>
                </Box>
              </Box>
              <Divider variant="fullWidth" />

              {ticket.items?.map((item) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                    marginTop: "5px",
                  }}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Box display={"flex"}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: 14,
                          fontWeight: 600,
                          lineHeight: "28px",
                          letterSpacing: 0,
                        }}
                      >
                        <span>{item.quantity}</span>
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: 14,
                          fontWeight: 600,
                          lineHeight: "28px",
                          letterSpacing: 0,
                        }}
                      >
                        <span>x</span>
                      </Typography>
                    </Box>

                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: "28px",
                        letterSpacing: 0,
                      }}
                    >
                      {item.productTitle}
                    </Typography>
                  </Box>
                  <Box textAlign="right">
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: "28px",
                        letterSpacing: 0,
                        textAlign: "right",
                        color: themeColors.midnightBlueLight,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.total.toFixed(2)} {t("pages.currency")}
                    </Typography>
                  </Box>
                </Box>
              ))}

              <Divider variant="fullWidth" />
              <Box sx={{ my: 3 }}>
                <Grid container alignItems="center" mb={1}>
                  <Grid item xs>
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: themeColors.midnightBlueLight,
                      }}
                      gutterBottom
                      component="div"
                    >
                      {t("pages.session.ticket_details.items")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: themeColors.midnightBlueDark,
                      }}
                      gutterBottom
                      component="div"
                    >
                      {ticket.items.length}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider variant="fullWidth" />
                <Grid container alignItems="center" mt={2}>
                  <Grid item xs>
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: themeColors.midnightBlueLight,
                      }}
                      gutterBottom
                      component="div"
                    >
                      {t("pages.session.ticket_details.total")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: primaryColor().primary,
                        whiteSpace: "nowrap",
                      }}
                      gutterBottom
                      component="div"
                    >
                      {ticket.total.toFixed(2)} {t("pages.currency")}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  sx={{
                    border: "1px solid " + primaryColor().primary,
                    borderRadius: "82px",
                    backgroundColor: primaryColor().primary,
                    ":hover": {
                      background: primaryColor().primary,
                    },
                    color: themeColors.white,
                    fontStyle: "normal",
                    fontWeight: 600,
                  }}
                  fullWidth
                  onClick={handlePrint}
                >
                  {t("pages.session.ticket_details.print")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <DataNotFound />
        )}
      </Box>
    </ResponsiveDrawer>
  );
};
