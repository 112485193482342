import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Link,
  InputAdornment,
  IconButton,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  Business,
  Email,
  Lock,
  Person,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useAppContext } from "App";
import { useCommand } from "Components/api";
import { primaryColor, themeColors } from "..";
import { useTranslation } from "react-i18next";
import DirTheme from "../DirTheme";

export const RegisterPage = () => {
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";

  const { t } = useTranslation("common");
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [nom, setNom] = useState<string>();
  const [prenom, setPrenom] = useState<string>();
  const [accountType, setAccountType] = useState<string>("Personal");
  const { ws } = useAppContext();
  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(ws, "user_register", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setEmail("");
    setPassword("");
    setNom("");
    setPrenom("");
    setAccountType("Personal");
    setSuccess(t("pages.register.success_create_account"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Grid container sx={{ height: "100vh" }} dir={direction}>
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        p={2}
        sx={{ backgroundColor: "white" }}
        className="login-image"
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: { xs: "none", sm: "block" },
            backgroundImage: `url(${require("Kcafi/img/auth.png")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "20px",
          }}
        ></Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        component={Paper}
        elevation={0}
        square
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 10,
        }}
        className="login-form"
      >
        <Typography
          variant="h2"
          sx={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 32,
            lineHeight: "120%",
            color: themeColors.darkBlue,
            mb: 4,
          }}
          className="login-title"
        >
          {t("pages.new_password.title")}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 18,
            lineHeight: "150%",
            color: themeColors.deepTurquoise,
            mb: 4,
          }}
          className="login-subtitle"
        >
          {t("pages.new_password.subtitle")}
        </Typography>
        {error && (
          <Alert sx={{ mb: 4 }} variant="outlined" severity="error">
            {error}
          </Alert>
        )}

        {success && (
          <Alert sx={{ mb: 4 }} variant="outlined" severity="success">
            {success}
          </Alert>
        )}
        <form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                sx={{
                  border:
                    accountType === "Personal"
                      ? `1px solid ${primaryColor().primary}`
                      : `1px solid ${themeColors.deepTurquoise}`,
                  borderRadius: "82px",
                  backgroundColor: themeColors.white,
                  color:
                    accountType === "Personal"
                      ? primaryColor().primary
                      : themeColors.deepTurquoise,
                  fontStyle: "normal",
                  fontWeight: 600,
                  pl: 2,
                  pr: 2,
                  mb: 1,
                }}
                onClick={() => setAccountType("Personal")}
                fullWidth
              >
                <Person />{" "}
                <Box ml={1} mr={1}>
                  {t("pages.register.personal_account")}
                </Box>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{
                  border:
                    accountType === "Business"
                      ? `1px solid ${primaryColor().primary}`
                      : `1px solid ${themeColors.deepTurquoise}`,
                  borderRadius: "82px",
                  backgroundColor: themeColors.white,
                  color:
                    accountType === "Business"
                      ? primaryColor().primary
                      : themeColors.deepTurquoise,
                  fontStyle: "normal",
                  fontWeight: 600,
                  pl: 2,
                  pr: 2,
                  mb: 1,
                }}
                onClick={() => setAccountType("Business")}
                fullWidth
              >
                <Business />{" "}
                <Box ml={1} mr={1}>
                  {t("pages.register.business_account")}
                </Box>
              </Button>
            </Grid>

            <Grid item xs={6}>
              <DirTheme>
                <TextField
                  sx={{ mb: 4 }}
                  label={t("pages.register.name")}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    setPrenom(event.target.value);
                  }}
                  value={prenom}
                />
              </DirTheme>
            </Grid>
            <Grid item xs={6}>
              <DirTheme>
                <TextField
                  sx={{ mb: 4 }}
                  label={t("pages.register.last_name")}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    setNom(event.target.value);
                  }}
                  value={nom}
                />
              </DirTheme>
            </Grid>
          </Grid>
          <DirTheme>
            <TextField
              sx={{ mb: 4 }}
              label={t("pages.register.email")}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
            />
          </DirTheme>
          <DirTheme>
            <TextField
              sx={{ mb: 6 }}
              label={t("pages.register.password")}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              value={password}
            />
          </DirTheme>

          <Button
            sx={{
              background: primaryColor().primary,
              borderRadius: "100px",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              ":hover": {
                background: primaryColor().primary,
              },
              mb: 6,
              boxShadow: `0px 11px 64px ${themeColors.black007}`,
            }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              setError(undefined);
              setSuccess(undefined);
              onSubmit({
                email: email,
                username: email,
                nom: nom,
                prenom: prenom,
                password: password,
                accountType: accountType,
              } as any);
            }}
            disabled={isLoading || !password || !email || !nom || !prenom}
          >
            {isLoading ? (
              <CircularProgress size={20} sx={{ color: themeColors.white }} />
            ) : (
              t("pages.register.register")
            )}
          </Button>
        </form>
        <Typography
          variant="body2"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "150%",
            color: themeColors.darkBlue,
            mt: 2,
            textAlign: "center",
          }}
        >
          {t("pages.register.already_have_account")}{" "}
          <Link href="/login" underline="always">
            {t("pages.register.login")}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RegisterPage;
