import * as React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Section } from "Kcafi/Pages/DashboardPage";
import { MyWorkerType } from "./WorkerManagement";
import { Save } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useCommand } from "Components/api";
import { useQueryClient } from "react-query";
import { ErrorToast } from "Kcafi/Pages/ErrorToast";
import { DeleteWorkerDialog } from "./DeleteWorkerDialog";
import { primaryColor, themeColors } from "Kcafi/Pages";
import { useTranslation } from "react-i18next";

interface IndeterminateCheckboxProps {
  label: string;
  checked: boolean[];
  setChecked: React.Dispatch<React.SetStateAction<boolean[]>>;
}

export default function IndeterminateCheckbox({
  label,
  checked,
  setChecked,
}: IndeterminateCheckboxProps) {
  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([checked[0], event.target.checked]);
  };

  const children = (
    <Box sx={{ display: "flex", flexDirection: "row", ml: 3 }}>
      <FormControlLabel
        label={`Read`}
        control={
          <Checkbox
            checked={checked[0]}
            onChange={handleChange2}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: checked[0] ? primaryColor().primary : themeColors.deepTurquoise,
              },
            }}
          />
        }
        sx={{
          border: `1px solid ${checked[0] ? primaryColor().primary : themeColors.deepTurquoise}`, // Change border color based on checked state
          borderRadius: "92px",
          backgroundColor: "white", // Change background color based on your theme
          color: checked[0] ? primaryColor().primary : themeColors.deepTurquoise, // Change text color based on checked state
          fontStyle: "normal",
          fontWeight: 600,
          px: 2
        }}
      />
      <FormControlLabel
        label={`Write`}
        control={
          <Checkbox
            checked={checked[1]}
            onChange={handleChange3}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: checked[1] ? primaryColor().primary : themeColors.deepTurquoise,
              },
            }}
          />
        }
        sx={{
          border: `1px solid ${checked[1] ? primaryColor().primary : themeColors.deepTurquoise}`, // Change border color based on checked state
          borderRadius: "82px",
          backgroundColor: "white", // Change background color based on your theme
          color: checked[1] ? primaryColor().primary : themeColors.deepTurquoise, // Change text color based on checked state
          fontStyle: "normal",
          fontWeight: 600,
          paddingLeft: 1,
          paddingRight: 2,
        }}
      />
    </Box>
  );

  return (
    <div>
      <FormControlLabel
        label={`${label}`}
        control={
          <Checkbox
            checked={checked[0] && checked[1]}
            indeterminate={checked[0] !== checked[1]}
            onChange={handleChange1}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: checked[0] && checked[1] ? primaryColor().primary : themeColors.deepTurquoise,
              },
            }}
          />
        }
      />
      {children}
    </div>
  );
}

export const PermissionForWorkers = ({
  worker,
  setSelectedWorker,
}: {
  worker: MyWorkerType;
  setSelectedWorker: React.Dispatch<
    React.SetStateAction<MyWorkerType | undefined>
  >;
}) => {
  const {t} = useTranslation("common");
  const appQueryClient = useQueryClient();
  const [error, setError] = React.useState<any>();
  const [success, setSuccess] = React.useState<any>();
  const [checkedCaisse, setCheckedCaisse] = React.useState([
    worker.permissions.Caisse?.ReadCaisse || false,
    worker.permissions.Caisse?.WriteCaisse || false,
  ]);
  const [checkedProducts, setCheckedProducts] = React.useState([
    worker.permissions.Products?.ReadProducts || false,
    worker.permissions.Products?.WriteProducts || false,
  ]);
  const [checkedStats, setCheckedStats] = React.useState([
    worker.permissions.Stats?.ReadStats || false,
    worker.permissions.Stats?.WriteStats || false,
  ]);
  const [checkedSettings, setCheckedSettings] = React.useState([
    worker.permissions.Settings?.ReadSettings || false,
    worker.permissions.Settings?.WriteSettings || false,
  ]);
  const [checkedSessions, setCheckedSessions] = React.useState([
    worker.permissions.Sessions?.ReadSessions || false,
    worker.permissions.Sessions?.WriteSessions || false,
  ]);

  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(worker, "updated_worker_permissions", (e: any) => {
    setError(t(e?.detail));
  });
  React.useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setSuccess(t('pages.settings.worker.permission.update_permission_success'));
    appQueryClient.invalidateQueries(["settings_activity_workers"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };

  return (
    <Section title={t('pages.settings.worker.permission.title')}>
      <ErrorToast error={error} success={success} handleClose={handleClose} />
      <IndeterminateCheckbox
        label={t('pages.settings.worker.permission.caisse')}
        checked={checkedCaisse}
        setChecked={setCheckedCaisse}
      />
      <IndeterminateCheckbox
        label={t('pages.settings.worker.permission.products')}
        checked={checkedProducts}
        setChecked={setCheckedProducts}
      />
      <IndeterminateCheckbox
        label={t('pages.settings.worker.permission.stats')}
        checked={checkedStats}
        setChecked={setCheckedStats}
      />
      <IndeterminateCheckbox
        label={t('pages.settings.worker.permission.settings')}
        checked={checkedSettings}
        setChecked={setCheckedSettings}
      />
      <IndeterminateCheckbox
        label={t('pages.settings.worker.permission.sessions')}
        checked={checkedSessions}
        setChecked={setCheckedSessions}
      />
      <br></br>

      <DeleteWorkerDialog
        worker={worker}
        setSelectedWorker={setSelectedWorker}
      />
      <Button
        sx={{
          background: primaryColor().primary,
          borderRadius: "4px",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          color: themeColors.white,
          ":hover": {
            background: primaryColor().primary,
          },
          ml: 2,
          boxShadow: `0px 11px 64px ${themeColors.black007}`
        }}
        onClick={() =>
          onSubmit({
            ReadCaisse: checkedCaisse[0],
            ReadProducts: checkedProducts[0],
            ReadSettings: checkedSettings[0],
            ReadSessions: checkedSessions[0],
            ReadStats: checkedStats[0],
            WriteCaisse: checkedCaisse[1],
            WriteProducts: checkedProducts[1],
            WriteSettings: checkedSettings[1],
            WriteSessions: checkedSessions[1],
            WriteStats: checkedStats[1],
            _method: "PUT",
          })
        }
      >
        {isLoading ? (
          <CircularProgress size={20} sx={{ color: themeColors.white }} />
        ) : (
          <>
            <Save /> {t('pages.settings.worker.permission.save')}
          </>
        )}
      </Button>
    </Section>
  );
};
