import { Box, Tab, Tabs, Typography } from "@mui/material";
import { primaryColor, themeColors } from "..";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ProductsContextMenu = ({ activeMenu }: { activeMenu: string }) => {
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const history = useHistory();
  const {t} = useTranslation("common");
  return (
    <Tabs
      value={activeMenu}
      aria-label="Custom Tabs"
      variant="scrollable"
      sx={{
        backgroundColor: themeColors.transparent,
        "& .MuiTab-root": {
          marginRight: direction === "ltr" ? "8px" : "0px",
          marginLeft: direction === "rtl" ? "8px" : "0px",
          "&:last-child": {
            marginRight: direction === "ltr" ? "8px" : "0px",
            marginLeft: direction === "rtl" ? "8px" : "0px",
          },
        },
        "& .MuiTabs-indicator": {
          display: "none",
        },
        mb: 2,
      }}
      TabScrollButtonProps={{
        sx: {
          backgroundColor: themeColors.white,
          borderRadius: "20px",
          height: "40px",
          width: "40px",
          marginTop: { xs: 2.5, md: 5 },
          marginRight: 1,
          "& svg": {
            fontSize: 18,
          },
        },
      }}
    >
      {["products", "stock", "equivalence"]?.map((link) => (
        <Tab
          key={link}
          label={
            <>
              <Box
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: 12,
                    leadingTrim: "both",
                    color:
                      link === activeMenu
                        ? primaryColor().primary
                        : themeColors.darkBlue,
                  }}
                >
                  {t(`pages.products.products_context_menu.${link}`)}
                </Typography>
              </Box>
            </>
          }
          sx={{
            height: { md: 42, xs: 42 },
            padding: "2px 8px",
            background:
              link === activeMenu
                ? primaryColor().primaryLinearGrad
                : themeColors.white075,
            border:
              "1px solid " +
              (link === activeMenu
                ? primaryColor().primary
                : themeColors.midnightBlueLight),
            borderRadius: "15px",
            color: link === activeMenu ? primaryColor().primary : "",
          }}
          onClick={() => history.push(`/${link}`)}
        />
      ))}
    </Tabs>
  );
};
