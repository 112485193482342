import React, { ReactNode } from "react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";

interface DirThemeProps {
  children: ReactNode;
}

const createDir = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const DirTheme: React.FC<DirThemeProps> = ({ children }) => {
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  return direction === "rtl" ? (
    <CacheProvider value={createDir}>
      <div dir={direction}>{children}</div>
    </CacheProvider>
  ) : (
    <div dir={direction}>{children}</div>
  );
};

export default DirTheme;
