import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ResponsiveDrawer from "../ResponsiveDrawer";
import { Add, Poll } from "@mui/icons-material";
import { useAppContext } from "App";
import { useOperation } from "Components/api";
import { primaryColor, themeColors } from ".";
import { Session } from "./Session/Session";
import { useHistory } from "react-router-dom";
import {
  ChartData,
  OverviewEarnings,
  OverviewTodaysTickets,
  OverviewTotalTickets,
  OverviewTotalToday,
} from "./Stats/StatsPage";
import { DataNotFound } from "./DataNotFound";
import { useTranslation } from "react-i18next";
export type SessionType = {
  id: number;
  internalId: string;
  nom: string;
  prenom: string;
  startedAt: any;
  closedAt: any;
  totalEarnings: number;
  totalTickets: number;
  availableFunds: number;
  stocks?: {
    title: string;
    imageSrc: string;
    productId: number;
    quantity: number;
    soldeQuantity?: number;
    availableQuantity?: number;
    unit: string;
  }[];
  _links: any;
};
export const DashboardPage = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const { myDetails } = useAppContext();
  const { data: mySessions, isLoading: isLoadingSessions } = useOperation<
    SessionType[]
  >(
    myDetails,
    {
      link: "mySessions",
    },
    "mySessions",
    {
      //startedAt: new Date().toLocaleDateString(),
      isToday: true
    } as any,
    undefined,
    !myDetails
  );

  const { data: myOpenSessions, isLoading: isLoadingOpenSessions } =
    useOperation<SessionType[]>(
      myDetails,
      {
        link: "mySessions",
      },
      "mySessions",
      {
        open: true,
      } as any,
      undefined,
      !myDetails
    );
  const { data: myStats, isLoading: isLoadingMyStats } =
    useOperation<ChartData>(
      myDetails,
      {
        link: "myStats",
      },
      "myStats",
      {} as any,
      undefined,
      !myDetails
    );
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";

  return (
    <ResponsiveDrawer title={t("pages.dashboard.home")}>
      <Box
        sx={{
          height: 240, // Set the height of the box
          overflow: "hidden", // Hide anything that goes outside the box
          position: "relative",
          mb: 2,
        }}
        className="dashboard-cover-box"
        dir={direction}
      >
        <img
          src={require("Kcafi/img/b.png")}
          alt="background"
          style={{ width: "100%", height: "100%", objectFit: "fill" }}
          className="dashboard-cover"
        />

        <Typography
          variant="h4"
          sx={{
            position: "absolute",
            top: "20%",
            left: direction === "rtl" ? "10%" : "10%",
            zIndex: 1,
            fontSize: { xs: "18px", sm: "42px" },
            fontStyle: "normal",
            fontWeight: 700,
            color: themeColors.darkBrown,
          }}
          mb={1}
        >
          {t("pages.dashboard.title")}
        </Typography>

        <Typography
          variant="h4"
          sx={{
            position: "absolute",
            top: "40%",
            left: direction === "rtl" ? "10%" : "10%",
            zIndex: 1,
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: { xs: "18px", sm: "42px" },
            color: themeColors.white,
          }}
          className="cover-subtitle"
          mb={1}
        >
          {t("pages.dashboard.subtitle")}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            position: "absolute",
            top: "65%",
            left: direction === "rtl" ? "90%" : "10%",
            width: "50%",
            zIndex: 1,
            display: { xs: "none", sm: "none", md: "block" },
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14.5527px",
            color: themeColors.white,
          }}
          mb={1}
        >
          {t("pages.dashboard.description")}
        </Typography>
      </Box>

      {myDetails.permissions?.Sessions?.ReadSessions && (<Section
        title={t("pages.dashboard.todays_sessions")}
        action={() => {
          history.push("/caisse");
        }}
      >
        <Grid overflow="auto" container spacing={2} mb={5}>
          {isLoadingSessions ? (
            <Box sx={{ display: "flex", ml: 2 }}>
              <CircularProgress sx={{ color: primaryColor().primary }} />
            </Box>
          ) : mySessions?.length ? (
            mySessions?.map((session, key) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                <Session session={session} />
              </Grid>
            ))
          ) : (
            <DataNotFound />
          )}
        </Grid>
      </Section>)}
      {myDetails.permissions?.Sessions?.ReadSessions && (<Section title={t("pages.dashboard.open_sessions")}>
        <Grid overflow="auto" container spacing={2} mb={5}>
          {isLoadingOpenSessions ? (
            <Box sx={{ display: "flex", ml: 2 }}>
              <CircularProgress sx={{ color: primaryColor().primary }} />
            </Box>
          ) : myOpenSessions?.length ? (
            myOpenSessions?.map((session, key) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                <Session session={session} />
              </Grid>
            ))
          ) : (
            <DataNotFound />
          )}
        </Grid>
      </Section>)}
      {myDetails.permissions?.Stats?.ReadStats && (
        <Section
          title={t("pages.dashboard.overview")}
          extraChildren={
            <Button
              sx={{
                borderRadius: "82px",
                backgroundColor: primaryColor().primary,
                ":hover": {
                  background: primaryColor().primary,
                },
                color: themeColors.white,
                fontStyle: "normal",
                fontWeight: 600,
                paddingLeft: 2,
                paddingRight: 2,
              }}
              color="primary"
              onClick={() => history.push(`/stats`)}
            >
              <Poll />{" "}
              <Box ml={1} mr={1}>
                {t("pages.dashboard.full_report")}
              </Box>
            </Button>
          }
        >
          {!isLoadingMyStats && myStats && (
            <Grid container spacing={2} sx={{ marginBottom: "25px" }}>
              <Grid item xs={12} sm={6} lg={3}>
                <OverviewEarnings
                  sx={{
                    height: "100%",
                    boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
                  }}
                  value={`${t("pages.currency")}`}
                  value2={`${myStats.totalEarnings.toFixed(
                    2
                  )}`}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <OverviewTotalToday
                  sx={{
                    height: "100%",
                    boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
                  }}
                  value={`${t("pages.currency")}`}
                  value2={`${myStats.totalEarningOfToday.toFixed(2)}`}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <OverviewTotalTickets
                  sx={{
                    height: "100%",
                    boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
                  }}
                  value={`${myStats.totalTickets}`}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <OverviewTodaysTickets
                  sx={{
                    height: "100%",
                    boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
                  }}
                  value={`${myStats.totalTicketsOfToday}`}
                />
              </Grid>
            </Grid>
          )}
        </Section>
      )}
    </ResponsiveDrawer>
  );
};

export default DashboardPage;

type SectionProps = {
  title: string;
  description?: string;
  children: any;
  action?: any;
  loading?: boolean;
  extraChildren?: any;
  mt?: number;
};
export const Section = ({
  title,
  description,
  children,
  action,
  loading,
  extraChildren,
  mt = 2,
}: SectionProps) => {
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  return (
    <Box dir={direction}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "28px",
          }}
        >
          {title}
        </Typography>
        {action &&
          (loading ? (
            <CircularProgress
              sx={{
                color: primaryColor().primary,
                width: 24,
                height: 24,
                mr: 1,
              }}
            />
          ) : (
            <IconButton
              sx={{
                ml: 1,
                backgroundColor: primaryColor().primary,
                ":hover": {
                  backgroundColor: primaryColor().primary,
                },
              }}
              aria-label="Button"
              className="add-button"
              onClick={action}
              disabled={loading}
            >
              <Add
                sx={{
                  color: "white",
                  width: 24,
                  height: 24,
                  fontSize: 18,
                }}
              />
            </IconButton>
          ))}
        {extraChildren && extraChildren}
      </Box>

      {description && (
        <Box overflow="y" sx={{ mt: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "142.19%",
            }}
          >
            {description}
          </Typography>
        </Box>
      )}
      <Box overflow="y" sx={{ mt: mt }}>
        {children}
      </Box>
    </Box>
  );
};
