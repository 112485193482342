import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import ImageEditor from "@uppy/image-editor";
import Webcam from "@uppy/webcam";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/image-editor/dist/style.min.css";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { ProductSTD } from ".";
import { primaryColor, themeColors } from "..";
import { ErrorToast } from "../ErrorToast";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import DirTheme from "../DirTheme";
import { CameraAlt } from "@mui/icons-material";

type Props = {
  product: ProductSTD;
  title: string;
  sellPrice: string;
  description: string;
  uploadUrl: string;
};

const ImageUploader = ({ product, title, uploadUrl, sellPrice }: Props) => {
  const { t } = useTranslation("common");
  const appQueryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (!openModal) return;

    const initializeUppy = () => {
      const dashboardContainer = document.getElementById("dashboard");

      if (!dashboardContainer) {
        console.error("Dashboard container not found");
        return;
      }

      const uppy = new Uppy()
        .use(Dashboard, {
          inline: true,
          target: dashboardContainer,
          autoOpenFileEditor: true,
          showProgressDetails: true,
          proudlyDisplayPoweredByUppy: false,
          locale: {
            strings: {
              cancel: t("pages.img_uploader.cancel"),
              save: t("pages.img_uploader.save"),
              browseFiles: t("pages.img_uploader.browseFiles"),
              dropPasteImportFiles: t("pages.img_uploader.dropPasteImportFiles"),
              myDevice: t("pages.img_uploader.myDevice"),
              takePictureBtn: t("pages.img_uploader.takePictureBtn"),
              recordVideoBtn: t("pages.img_uploader.recordVideoBtn"),
              upload: t("pages.img_uploader.upload")
            }
          }
        })
        .use(ImageEditor, {
          actions: {
            revert: true,
            rotate: true,
            zoomIn: true,
            zoomOut: true,
            cropSquare: true,
            cropWidescreen: false,
            cropWidescreenVertical: false,
            granularRotate: true,
            flip: true,
          },
          cropperOptions: {
            croppedCanvasOptions: {
              width: 300,
              height: 300,
              minWidth: 200,
              minHeight: 200,
              maxWidth: 500,
              maxHeight: 500,
              fillColor: "#fff",
              imageSmoothingEnabled: false,
              imageSmoothingQuality: "medium",
            },
            viewMode: 1,
            aspectRatio: 1
          },
          locale: {
            strings: {
              revert: t("pages.img_uploader.revert"),
              rotate: t("pages.img_uploader.rotate"),
              zoomIn: t("pages.img_uploader.zoomIn"),
              zoomOut: t("pages.img_uploader.zoomOut"),
              flipHorizontal: t("pages.img_uploader.flipHorizontal"),
              aspectRatioSquare: t("pages.img_uploader.aspectRatioSquare"),
              aspectRatioLandscape: t("pages.img_uploader.aspectRatioLandscape"),
              aspectRatioPortrait: t("pages.img_uploader.aspectRatioPortrait"),
            },
          },
        })
        .use(Webcam, {
          target: Dashboard,
        })
        .on("upload", (data) => {
          console.log("Upload logic goes here:", data);
        })
        .on("complete", async (result) => {
          const file = result.successful[0]; // Assuming you're uploading only one file
          const authToken = localStorage.getItem("token");
          const formData = new FormData();
          formData.append("file", file.data);
          if (product.myProductId)
            formData.append("productId", product.myProductId?.toString());
          else return;

          try {
            const response = await fetch(uploadUrl, {
              method: "POST",
              body: formData,
              headers: {
                Authorization: `Bearer ${authToken}`, // Include the token in the headers
              },
            });

            if (response.ok) {
              setSuccess(t("pages.img_uploader.success"));
              appQueryClient.invalidateQueries(["products_std_by_category"]);
              appQueryClient.invalidateQueries(["myProducts"]);
            } else {
              setError(t("pages.img_uploader.error"));
            }
          } catch (error) {
            console.error("Error uploading file:", error);
          }

          // Close the modal or perform other actions as needed
          handleCloseModal();
        });
      uppy.setOptions({
        restrictions: { maxNumberOfFiles: 1 },
      });
      return () => {
        uppy.close();
      };
    };

    const timeoutId = setTimeout(initializeUppy, 200); // Adjust the delay as needed

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);
  const handleClose = () => {
    setError("");
    setSuccess("");
  };
  return (
    <DirTheme>
      <Box mb={2}>
        <ErrorToast error={error} success={success} handleClose={handleClose} />
        <Box
          sx={{
            cursor: "pointer",
            height: "65%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            pointerEvent: product.added ? "auto" : "none",
            opacity: product.added ? 1 : 0.5,
            background: themeColors.grayLinearGrad,
          }}
          onClick={product.added ? handleOpenModal : undefined}
        >
          {!product.img ? (
            <Avatar
              alt="img-pro"
              sx={{
                pointerEvent: product.added ? "auto" : "none",
                opacity: product.added ? 1 : 0.5,
              }}
              variant={"square"}
            >
              <CameraAlt />
            </Avatar>
          ) : (
            <img
              src={product.img}
              alt="Product"
              style={{
                display: "block",
                background: themeColors.white,
                width: 100,
              }}
            />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            paddingLeft: 1,
            paddingRight: 1,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="caption"
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: { xs: "10px", sm: "10px", md: "12px" },
                lineHeight: "16px",
                leadingTrim: "both",
                textEdge: "cap",
                color: themeColors.darkBlue,
                textOverflow: "ellipsis",
                maxWidth: 150,
                overflow: "hidden",
              }}
            >
              {title || product.title}
            </Typography>{" "}
            {product.sellPrice && (
              <Typography
                variant="caption"
                sx={{
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: { xs: "10px", sm: "10px", md: "12px" },
                  lineHeight: "22px",
                  leadingTrim: "both",
                  textEdge: "cap",
                  color: primaryColor().primary,
                }}
              >
                {t("pages.currency")}
                {sellPrice
                  ? parseFloat(sellPrice).toFixed(2)
                  : product.sellPrice?.toFixed(2)}
              </Typography>
            )}
          </Box>
        </Box>

        {openModal && (
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 275,
                bgcolor: "white",
                border: "2px solid " + themeColors.black,
                boxShadow: 24,
                p: 4,
                outline: 0,
              }}
            >
              <div id="dashboard"></div>
              <Button
                sx={{
                  border: "1px solid " + primaryColor().primary,
                  borderRadius: "82px",
                  backgroundColor: themeColors.white,
                  color: primaryColor().primary,
                  fontStyle: "normal",
                  fontWeight: 600,
                  marginRight: 1,
                }}
                fullWidth
                onClick={handleCloseModal}
              >
                {t("pages.img_uploader.cancel")}
              </Button>
            </Box>
          </Modal>
        )}
      </Box>
    </DirTheme>
  );
};

export default ImageUploader;
