import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { CircularProgress } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { useCommand } from "Components/api";
import { MyWorkerType } from "./WorkerManagement";
import { ErrorToast } from "Kcafi/Pages/ErrorToast";
import { themeColors } from "Kcafi/Pages";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DeleteWorkerDialog = ({
  worker,
  setSelectedWorker,
}: {
  worker: MyWorkerType;
  setSelectedWorker: React.Dispatch<
    React.SetStateAction<MyWorkerType | undefined>
  >;
}) => {
  const {t} = useTranslation("common");
  const appQueryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const {
    onSubmit,
    data: serverReply,
    isLoading: isLoadingCloseSession,
    isSuccess,
  } = useCommand(worker, "delete_worker", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setSuccess(t('pages.settings.worker.worker_deleted'));
    setSelectedWorker(undefined);
    appQueryClient.invalidateQueries(["settings_activity_workers"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleCloseAlert = () => {
    setError("");
    setSuccess("");
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  return (
    <>
      <ErrorToast
        error={error}
        success={success}
        handleClose={handleCloseAlert}
      />
      <Button
        sx={{
          background: themeColors.red,
          borderRadius: "4px",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          color: themeColors.white,
          ":hover": {
            background: themeColors.red,
          },
          ml: 2,
          boxShadow: `0px 11px 64px ${themeColors.black007}`
        }}
        onClick={handleClickOpenDialog}
      >
        <Delete /> {t('pages.settings.worker.delete')}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{t('pages.settings.worker.delete_worker_dialog_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('pages.settings.worker.delete_worker_dialog_description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t('pages.settings.worker.delete_worker_dialog_cancel')}</Button>
          <Button onClick={() => onSubmit({})}>
            {isLoadingCloseSession ? (
              <CircularProgress sx={{ width: 24, height: 24, mr: 1 }} />
            ) : (
              t('pages.settings.worker.delete_worker_dialog_confirm')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
