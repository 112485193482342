import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import emptyImage from 'Kcafi/img/empty.png'; // Import the image directly

export const DataNotFound = () => {
  const { t } = useTranslation("common");

  return (
    <Box textAlign="center" mt={2} ml={2}>
      <img src={emptyImage} alt="Empty" style={{ width: "90px", height: "90px", borderRadius: "20px", marginBottom: "8px" }} />
      <Typography variant="subtitle1" color="textSecondary">{t("pages.data_not_found")}</Typography>
    </Box>
  );
}

export default DataNotFound;
