import { Box } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { themeColors } from "..";
import { SessionLabel } from "./SessionLabel";
import { SessionType } from "../DashboardPage";
import { useTranslation } from "react-i18next";

export type SessionProps = {
  session: SessionType;
};

export const Session = ({ session }: SessionProps) => {
  const {t} = useTranslation("common");
  const history = useHistory();
  return (
    <Box
      sx={{
        height: "130px",
        background: themeColors.white,
        borderRadius: "15.53px",
        padding: "25px 15px",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => session.closedAt
        ? history.push(`/sessions/${session.id}/tickets`)
        : history.push("/caisse")}
    >
      <FiberManualRecord
        sx={{
          position: "absolute",
          top: "8px",
          right: "8px",
          color: session.closedAt ? themeColors.red : themeColors.green,
          fontSize: 16,
        }} />
      <SessionLabel label={t('pages.session.session_info.session_id')} text={`${session.internalId}`} />
      <SessionLabel label={t('pages.session.session_info.opened_by')} text={`${session.nom} ${session.prenom}`} />
      <SessionLabel
        label={t('pages.session.session_info.started_at')}
        text={`${new Date(
          session.startedAt.date
        ).toLocaleDateString()} ${new Date(
          session.startedAt.date
        ).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`} />
      <SessionLabel
        label={t('pages.session.session_info.ended_at')}
        text={`${session.closedAt
            ? new Date(session.closedAt.date).toLocaleDateString() +
            " " +
            new Date(session.closedAt.date).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
            : "-- : --"}`} />
      <SessionLabel label={t('pages.session.session_info.total_tickets')} text={`${session.totalTickets}`} />
      <SessionLabel
        label={t('pages.session.session_info.total_earnings')}
        text={`${session.totalEarnings.toFixed(2)} ${t('pages.currency')}`} />
    </Box>
  );
};
