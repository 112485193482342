type TCartItem = {
  product: number;
  title: string;
  description?: string;
  img: string;
  quantity: number;
  sellPrice: number;
};

type TCart = {
  worker: number;
  items: TCartItem[];
};

export class CartManager {
  private cart: TCart;

  constructor() {
    this.cart = this.getCartFromLocalStorage() || {
      worker: 0, // Default worker ID
      items: [],
    };
  }

  private getCartFromLocalStorage(): TCart | null {
    const cartData = localStorage.getItem("cart");
    if (cartData) {
      try {
        return JSON.parse(cartData);
      } catch (error) {
        console.error("Error parsing cart data:", error);
      }
    }
    return null;
  }

  private updateLocalStorage(cart: TCart) {
    localStorage.setItem("cart", JSON.stringify(cart));
  }

  addItem(
    product: number,
    title: string,
    img: string,
    quantity: number,
    sellPrice: number,
    description?: string
  ) {
    const existingItem = this.cart.items.find(
      (item) => item.product === product
    );

    if (existingItem) {
      existingItem.quantity += quantity;
    } else {
      this.cart.items.push({
        product,
        quantity,
        sellPrice,
        title,
        img,
        description,
      });
    }

    this.updateLocalStorage(this.cart);
  }

  removeProduct(product: number) {
    const productIndex = this.cart.items.findIndex(
      (item) => item.product === product
    );

    if (productIndex !== -1) {
      this.cart.items.splice(productIndex, 1);
      this.updateLocalStorage(this.cart);
    }
  }

  increaseQuantity(product: number, quantity: number) {
    const existingItemIndex = this.cart.items.findIndex(
      (item) => item.product === product
    );
    if (existingItemIndex !== -1) {
      this.cart.items[existingItemIndex].quantity += quantity;
      this.updateLocalStorage(this.cart);
    }
  }

  decreaseQuantity(product: number, quantity: number) {
    const existingItemIndex = this.cart.items.findIndex(
      (item) => item.product === product
    );
    if (existingItemIndex !== -1) {
      if (this.cart.items[existingItemIndex].quantity <= 1)
        this.removeProduct(product);
      else this.cart.items[existingItemIndex].quantity -= quantity;
      this.updateLocalStorage(this.cart);
    }
  }

  setWorker(worker: number) {
    this.cart.worker = worker;
    this.updateLocalStorage(this.cart);
  }
  
  getWorker() {
    return this.cart.worker;
  }

  getTotal(): number {
    return this.cart.items.reduce(
      (total, item) => total + item.quantity * item.sellPrice,
      0
    );
  }

  getTotalProducts(): number {
    return this.cart.items.length;
  }

  clearCart() {
    this.cart.worker = 0; // Clear the worker
    this.cart.items = []; // Clear the items array
    this.updateLocalStorage(this.cart);
  }

  getCart(): TCart {
    return this.cart;
  }
}
