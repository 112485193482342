import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ResponsiveDrawer from "Kcafi/ResponsiveDrawer";
import { Section } from "Kcafi/Pages/DashboardPage";
import { primaryColor, themeColors } from "..";
import { ProductsContextMenu } from "./ProductsContextMenu";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useAppContext } from "App";
import { useEffect, useState } from "react";
import { Category, TProduct } from "../Caisse/CaissePage";
import { useCommand, useOperation } from "Components/api";
import { ErrorToast } from "../ErrorToast";
import DirTheme from "../DirTheme";
import {
  NumericFormatCustomKG,
  NumericFormatCustomLitre,
  NumericFormatCustomPiece,
} from "./NumericFormatCustom";
import { Add, EditOutlined, MoreVert, RepeatOne } from "@mui/icons-material";
import { UnlockDialog } from "../Caisse/UnlockDialog";

export const EquivalencePage = () => {
  const appQueryClient = useQueryClient();
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const { t } = useTranslation("common");
  const { myDetails } = useAppContext();
  const [productSource, setProductSource] = useState<TProduct>();
  const [qteSource, setQteSource] = useState<number>(0);
  const [productResult, setProductResult] = useState<TProduct>();
  const [qteResult, setQteResult] = useState<number>(0);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const {
    data: categoriesAchatVente,
    isLoading: isLoadingMyProductsAchatVente,
  } = useOperation<Category[]>(
    myDetails,
    {
      link: "myProducts",
    },
    "myProductsAchatVente",
    { type: "vente" } as any,
    undefined,
    !myDetails
  );

  const { data: categoriesAchat, isLoading: isLoadingMyProductsAchat } =
    useOperation<Category[]>(
      myDetails,
      {
        link: "myProducts",
      },
      "myProductsAchat",
      { type: "achat" } as any,
      undefined,
      !myDetails
    );

  const { data: equivalences, isLoading: isLoadingMyEquivalences } =
    useOperation<
      {
        productSourceTitle: string;
        productSourceImageSrc: string;
        productSourceUnit: string;
        productSourceId: number;
        productSourceQuantity: number;
        productResultTitle: string;
        productResultImageSrc: string;
        productResultUnit: string;
        productResultId: number;
        productResultQuantity: number;
        canEdit: boolean;
      }[]
    >(
      myDetails,
      {
        link: "myProductsEquivalence",
      },
      "myProductsEquivalence",
      undefined,
      undefined,
      !myDetails
    );

  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const {
    onSubmit,
    data: serverReply,
    isLoading: isLoadingCreateEquivalence,
    isSuccess,
  } = useCommand(myDetails, "myProductsEquivalenceCreateOrUpdate", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setSuccess(t("pages.products.equivalence.equivalence_updated"));
    appQueryClient.invalidateQueries(["myProductsEquivalence"]);
    appQueryClient.invalidateQueries(["My:Details"]);
    setProductSource(undefined);
    setQteSource(0);
    setProductResult(undefined);
    setQteResult(0);
    setIsEditMode(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };

  return (
    <ResponsiveDrawer title={t("pages.products.equivalence.title")}>
      <UnlockDialog />
      <Section title="" mt={0}>
        <ErrorToast error={error} success={success} handleClose={handleClose} />
        {isLoadingMyEquivalences ? (
          <Box sx={{ display: "flex", mb: 2 }}>
            <CircularProgress sx={{ color: primaryColor().primary }} />
          </Box>
        ) : (
          <Box>
            <ProductsContextMenu activeMenu="equivalence" />
          </Box>
        )}

        <Section
          title=""
          //description={t("pages.products.equivalence.description")}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <FormControl
                sx={{
                  mr: direction === "rtl" ? 0 : 1,
                  ml: direction === "rtl" ? 1 : 0,
                  mb: 2,
                }}
              >
                <DirTheme>
                  <InputLabel htmlFor="grouped-native-select">
                    {t("pages.products.equivalence.product_source")}
                  </InputLabel>
                  <Select
                    native
                    defaultValue=""
                    id="grouped-native-select"
                    value={`${productSource?.id}`}
                    label={t("pages.products.equivalence.product_source")}
                    onChange={(event) => {
                      setProductSource(undefined);
                      categoriesAchat?.forEach((category) => {
                        category.products?.forEach((product) => {
                          if (product.id === parseInt(event.target.value)) {
                            setProductSource(product);
                          }
                        });
                      });
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  >
                    <option aria-label="None" value="00">
                      {t("pages.products.equivalence.choose_product")}
                    </option>
                    {!isLoadingMyProductsAchat &&
                      categoriesAchat?.map((category, key) => (
                        <optgroup
                          label={category.title}
                          key={`optgroup-categoriesAchat-src-${category.id}`}
                        >
                          {category.products?.map((product, key) => (
                            <option
                              value={product.id}
                              key={`optgroup-categoriesAchat-p-src-${product.id}`}
                            >
                              {product.title}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                  </Select>
                </DirTheme>
              </FormControl>
              <FormControl
                variant="outlined"
                sx={{
                  mr: direction === "rtl" ? 0 : 1,
                  ml: direction === "rtl" ? 1 : 0,
                }}
              >
                <DirTheme>
                  <TextField
                    sx={{
                      fontStyle: "normal",
                    }}
                    label={t("pages.products.equivalence.quantity")}
                    name="quantity"
                    id="formatted-numberformat-input-x"
                    variant="outlined"
                    value={qteSource}
                    onChange={(event) =>
                      setQteSource(parseFloat(event.target.value))
                    }
                    InputProps={{
                      inputComponent:
                        productSource?.unit === "litre"
                          ? (NumericFormatCustomLitre as any)
                          : productSource?.unit === "kg"
                          ? (NumericFormatCustomKG as any)
                          : (NumericFormatCustomPiece as any),
                    }}
                    disabled={!productSource}
                    size="small"
                    fullWidth
                  />
                </DirTheme>
              </FormControl>
            </Box>
            <RepeatOne
              sx={{
                mr: direction === "rtl" ? 0 : 1,
                ml: direction === "rtl" ? 1 : 0,
              }}
            />
            <Box display={"flex"} flexDirection={"column"}>
              <FormControl
                sx={{
                  mr: direction === "rtl" ? 0 : 1,
                  ml: direction === "rtl" ? 1 : 0,
                  mb: 2,
                }}
              >
                <DirTheme>
                  <InputLabel htmlFor="grouped-native-select">
                    {t("pages.products.equivalence.product_result")}
                  </InputLabel>
                  <Select
                    native
                    defaultValue=""
                    id="grouped-native-select"
                    value={`${productResult?.id}`}
                    label={t("pages.products.equivalence.product_result")}
                    onChange={(event) => {
                      setProductResult(undefined);
                      categoriesAchatVente?.forEach((category) => {
                        category.products?.forEach((product) => {
                          if (product.id === parseInt(event.target.value)) {
                            setProductResult(product);
                          }
                        });
                      });
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  >
                    <option aria-label="None" value="00">
                      {t("pages.products.equivalence.product_result")}
                    </option>
                    {!isLoadingMyProductsAchatVente &&
                      categoriesAchatVente?.map((category, key) => (
                        <optgroup
                          label={category.title}
                          key={`optgroup-categoriesAchatVente-rst-${category.id}`}
                        >
                          {category.products?.map((product, key) => (
                            <option
                              value={product.id}
                              key={`option-categoriesAchatVente-p-rst-${product.id}`}
                            >
                              {product.title}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                  </Select>
                </DirTheme>
              </FormControl>
              <FormControl
                variant="outlined"
                sx={{
                  mr: direction === "rtl" ? 0 : 1,
                  ml: direction === "rtl" ? 1 : 0,
                }}
              >
                <DirTheme>
                  <TextField
                    sx={{
                      fontStyle: "normal",
                    }}
                    label={t("pages.products.equivalence.quantity")}
                    name="quantity"
                    id="formatted-numberformat-input-x"
                    variant="outlined"
                    value={qteResult}
                    onChange={(event) =>
                      setQteResult(parseFloat(event.target.value))
                    }
                    InputProps={{
                      inputComponent:
                        productResult?.unit === "litre"
                          ? (NumericFormatCustomLitre as any)
                          : productResult?.unit === "kg"
                          ? (NumericFormatCustomKG as any)
                          : (NumericFormatCustomPiece as any),
                    }}
                    disabled={!productResult}
                    size="small"
                    fullWidth
                  />
                </DirTheme>
              </FormControl>
            </Box>
          </Box>
          <Button
            sx={{
              borderRadius: "4px",
              backgroundColor: primaryColor().primary,
              ":hover": {
                background: primaryColor().primary,
              },
              color: themeColors.white,
              fontStyle: "normal",
              fontWeight: 600,
              height: "40px",
              mt: 2,
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              if (productSource && productResult) {
                onSubmit({
                  productSourceId: productSource.id,
                  quantitySource: qteSource,
                  productResultId: productResult.id,
                  quantityResult: qteResult,
                });
              }
            }}
            disabled={
              !productSource || !qteSource || !productSource || !qteSource
            }
          >
            {isLoadingCreateEquivalence ? (
              <CircularProgress
                key={"isLoadingCreateEquivalence"}
                size={20}
                sx={{ color: themeColors.white }}
              />
            ) : isEditMode ? (
              <EditOutlined />
            ) : (
              <Add />
            )}
          </Button>
          <Box mt={2}>
            {isLoadingMyEquivalences ? (
              <CircularProgress
                key={"isLoadingMyEquivalences"}
                size={20}
                sx={{ color: themeColors.white }}
              />
            ) : (
              <Section
                title={t("pages.products.equivalence.equivalence_items")}
              >
                {equivalences && equivalences?.length !== 0 ? (
                  equivalences?.map((equivalence) => (
                    <Box
                      sx={{
                        backgroundColor: themeColors.white,
                        padding: "10px 5px",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        borderRadius: "10px",
                        width: "97%",
                      }}
                      mb={1}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"start"}
                        width={"40%"}
                      >
                        <Box
                          sx={{
                            width: { xs: "40px", sm: "40px", md: "40px" },
                            height: { xs: "40px", sm: "40px", md: "40px" },
                            marginRight: "15px",
                            marginLeft: "15px",
                            borderRadius: "10px",
                            border:
                              "1px solid " + themeColors.midnightBlueLight,
                            backgroundColor: themeColors.gray,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={equivalence.productSourceImageSrc}
                            alt="Product"
                            width="40px"
                            height="40px"
                            style={{ borderRadius: "10px" }}
                          />
                        </Box>
                        <Box width="65%">
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontSize: "14px",
                              fontWeight: 600,
                              lineHeight: "28px",
                            }}
                          >
                            {equivalence.productSourceTitle}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              fontStyle: "normal",
                              fontWeight: 600,
                              fontSize: { xs: "10px", sm: "10px", md: "12px" },
                              lineHeight: "22px",
                              leadingTrim: "both",
                              textEdge: "cap",
                              color: primaryColor().primary,
                            }}
                          >
                            {t(equivalence.productSourceUnit)}{" "}
                            {equivalence.productSourceQuantity
                              ? equivalence.productSourceQuantity.toFixed(2)
                              : "--"}
                          </Typography>
                        </Box>
                      </Box>
                      <RepeatOne
                        sx={{
                          align: "center",
                          mr: 1,
                          ml: 1,
                        }}
                      />
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        width={"40%"}
                      >
                        <Box
                          sx={{
                            width: { xs: "40px", sm: "40px", md: "40px" },
                            height: { xs: "40px", sm: "40px", md: "40px" },
                            marginRight: "15px",
                            marginLeft: "15px",
                            borderRadius: "10px",
                            border:
                              "1px solid " + themeColors.midnightBlueLight,
                            backgroundColor: themeColors.gray,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={equivalence.productResultImageSrc}
                            alt="Product"
                            width="40px"
                            height="40px"
                            style={{ borderRadius: "10px" }}
                          />
                        </Box>
                        <Box width="65%">
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontSize: "14px",
                              fontWeight: 600,
                              lineHeight: "28px",
                            }}
                          >
                            {equivalence.productResultTitle}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              fontStyle: "normal",
                              fontWeight: 600,
                              fontSize: { xs: "10px", sm: "10px", md: "12px" },
                              lineHeight: "22px",
                              leadingTrim: "both",
                              textEdge: "cap",
                              color: primaryColor().primary,
                            }}
                          >
                            {t(equivalence.productResultUnit)}{" "}
                            {equivalence.productResultQuantity
                              ? equivalence.productResultQuantity.toFixed(2)
                              : 0}
                          </Typography>
                        </Box>
                        {equivalence.canEdit && (
                          <Box
                            sx={{
                              position: "absolute",
                              right: 5,
                              top: 5,
                            }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={handleClick}
                            >
                              <MoreVert />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleCloseMenu}
                            >
                              <MenuItem
                                key={"edit-equiv"}
                                onClick={() => {
                                  setIsEditMode(true);
                                  const foundProductSource = categoriesAchat
                                    ?.flatMap(
                                      (category) => category.products || []
                                    )
                                    .find(
                                      (product) =>
                                        product.id ===
                                        equivalence.productSourceId
                                    );
                                  const foundProductResult =
                                    categoriesAchatVente
                                      ?.flatMap(
                                        (category) => category.products || []
                                      )
                                      .find(
                                        (product) =>
                                          product.id ===
                                          equivalence.productResultId
                                      );

                                  setProductSource(foundProductSource);
                                  setProductResult(foundProductResult);
                                  setQteSource(
                                    equivalence.productSourceQuantity
                                  );
                                  setQteResult(
                                    equivalence.productResultQuantity
                                  );
                                  handleCloseMenu();
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                }}
                              >
                                <ListItemIcon>
                                  <EditOutlined />
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                  {t("pages.products.equivalence.edit")}
                                </Typography>
                              </MenuItem>
                            </Menu>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body1">
                    {t("pages.products.equivalence.empty_equivalence")}
                  </Typography>
                )}
              </Section>
            )}
          </Box>
        </Section>
      </Section>
    </ResponsiveDrawer>
  );
};
