
export const getLinks = (param: any): {[linkName: string] : any} => {
    if (!param) return {};
    let links: any = false;
    if (typeof param._links === 'object') links = param._links;
    const keys = Object.keys(param);
    if (!links && keys.length === 1) {
      const element = param[keys[0]];
      if (typeof element?._links === 'object') links = element._links;
    }
    if (!links) return {};
    const linksCleaned: {[linkName: string] : any} = {};
    Object.keys(links).map((name: string) => {
      const link: any = links[name];

      if (link && link.endsWith('@post')) {
        linksCleaned[name] = {
          url: link.slice(0, -6),
          method: 'POST',
        };
      } else if (link && link.endsWith('@put')) {
        linksCleaned[name] = {
          url: link.slice(0, -5),
          method: 'PUT',
        };
      } else if (link && link.endsWith('@delete')) {
        linksCleaned[name] = {
          url: link.slice(0, -8),
          method: 'DELETE',
        };
      } else {
        linksCleaned[name] = {
          url: link,
          method: 'GET',
        };
      }
      return false;
    });
    return linksCleaned;
  };

export default getLinks;
