import { Avatar, Box, Button, CircularProgress, Grid } from "@mui/material";
import { useAppContext } from "App";
import { useOperation } from "Components/api";
import { Section, SessionType } from "Kcafi/Pages/DashboardPage";
import { Session } from "./Session";
import ResponsiveDrawer from "Kcafi/ResponsiveDrawer";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Search } from "@mui/icons-material";
import { primaryColor, themeColors } from "..";
import { useHistory } from "react-router-dom";
import { DataNotFound } from "../DataNotFound";
import { useTranslation } from "react-i18next";
import DirTheme from "../DirTheme";
import { useState } from "react";

export const SessionsPage = () => {
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const { t } = useTranslation("common");
  const { myDetails } = useAppContext();
  const history = useHistory();
  const [fromDate, setFromDate] = useState<any>();
  const [toDate, setToDate] = useState<any>();
  const [search, setSearch] = useState<any>();
  const handleButtonClick = () => {
    if (fromDate && toDate)
      setSearch({
        startedAt: fromDate,
        closedAt: toDate,
      });
  };
  const { data: mySessions, isLoading: isLoadingSessions } = useOperation<
    SessionType[]
  >(
    myDetails,
    {
      link: "mySessions",
    },
    "mySessions",
    search as any,
    undefined,
    !myDetails
  );

  return (
    <ResponsiveDrawer title={t("pages.session.all_sessions.title")}>
      <Section
        title={t("pages.session.all_sessions.list_of_sessions")}
        action={() => {
          history.push("/caisse");
        }}
      >
        <Box
          sx={{ mb: 4, display: "flex", alignItems: "center" }}
          dir={direction}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DirTheme>
              <DatePicker
                label={t("pages.session.all_sessions.from")}
                sx={{ mr: 2 }}
                value={fromDate}
                onChange={(newDate) => setFromDate(newDate)}
              />
            </DirTheme>
            <DirTheme>
              <DatePicker
                label={t("pages.session.all_sessions.to")}
                sx={{ mr: 2 }}
                value={toDate}
                onChange={(newDate) => {
                  setToDate(newDate);
                }}
              />
            </DirTheme>
            <Button
              onClick={handleButtonClick}
              sx={{
                mr: 1,
                height: "100%",
                border: "1px solid " + primaryColor().primary,
                background: primaryColor().primary17,
                borderRadius: "8px",
                color: primaryColor().primary,
                ":hover": {
                  background: primaryColor().primary17,
                },
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: themeColors.transparent,
                }}
              >
                <Search style={{ color: primaryColor().primary }} />
              </Avatar>
            </Button>
          </LocalizationProvider>
        </Box>

        <Grid container spacing={2}>
          {isLoadingSessions ? (
            <Box sx={{ display: "flex", ml: 2 }}>
              <CircularProgress sx={{ color: primaryColor().primary }} />
            </Box>
          ) : mySessions?.length ? (
            mySessions?.map((session) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Session session={session} />
              </Grid>
            ))
          ) : (
            <DataNotFound />
          )}
        </Grid>
      </Section>
    </ResponsiveDrawer>
  );
};
