

export function primaryColor() {
    const primary = localStorage.getItem("activityThemeColor") || "rgba(223, 102, 4, 1)";
    return {
        primary: primary,
        primary17: primary.replace('1)', '0.17)'),
        primaryLinearGrad: "linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), "+primary.replace('1)', '0.25)')
        
    }
}

export const themeColors = {

    white: "#FFFFFF",
    black: "#000000",
    transparent: "transparent",
    red: "#e02425",
    green: "rgb(34, 199, 169, 1)",
    brown: "#684131",
    black007: "rgba(0, 0, 0, 0.07)",
    darkShadow: "0px 20px 60px rgba(99, 99, 99, 0.89)",
    

    deepTurquoise: "#35596C",


    darkBlue: "#11263C",
    midnightBlueDark: "rgba(2, 48, 71, 1)",
    midnightBlueMiddle: "rgba(2, 48, 71, 0.5)",
    midnightBlueLight: "rgba(2, 48, 71, 0.25)",
    darkBrown: "#2F1300",

    lightPeriwinkleBlue: "#9197B3",
    lightGray: "#f4f4f4",
    gray: "#E4E8EC",
    

    white05: "rgba(226, 236, 249, 0.5)",
    white075: "rgba(226, 236, 249, 0.75)",
    grayLinearGrad: "linear-gradient(180deg, #E3E7EB 0%, rgba(227, 231, 235, 0.68) 100%)",
    

}

export const colors = [
    {
      name: "Orange",
      code: "rgba(223, 102, 4, 1)",
    },
    {
      name: "Navy Blue",
      code: "rgba(7, 18, 71, 1)",
    },
    {
      name: "Turquoise",
      code: "rgba(0, 103, 104, 1)",
    },
    {
      name: "Brown",
      code: "rgb(176, 70, 50, 1)",
    },
    {
      name: "Blue",
      code: "rgb(0, 121, 191, 1)",
    },
    {
      name: "Blue Light",
      code: "rgb(0, 174, 204, 1)",
    },
    {
      name: "Magenta",
      code: "rgba(242, 67, 126, 1)",
    },
    {
      name: "Green",
      code: "rgb(75, 191, 107, 1)",
    },
    {
      name: "Yellow",
      code: "rgb(210, 144, 52, 1)",
    },
    {
      name: "Pink",
      code: "rgb(205, 90, 145, 1)",
    },
    {
      name: "Purple",
      code: "rgb(137, 96, 158, 1)",
    },
    {
      name: "Green Light",
      code: "rgba(34, 199, 169, 1)",
    }
  ];