import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import ResponsiveDrawer from "Kcafi/ResponsiveDrawer";
import { Section } from "Kcafi/Pages/DashboardPage";
import { Product } from "./Product";
import useLocalStorage from "App/components/useLocalStorage";
import { useAppContext } from "App";
import { useCommand, useOperation } from "Components/api";
import { CategorySTD, ProductSTD } from ".";
import { primaryColor, themeColors } from "..";
import { ProductsContextMenu } from "./ProductsContextMenu";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { ErrorToast } from "../ErrorToast";
import DirTheme from "../DirTheme";
import { UnlockDialog } from "../Caisse/UnlockDialog";

export const ProductsPage = () => {
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const { t } = useTranslation("common");
  const [showCart] = useLocalStorage("showCart", false);
  const { myDetails } = useAppContext();
  const { data: categoriesSTD, isLoading } = useOperation<CategorySTD[]>(
    myDetails,
    {
      link: "categoriesStd",
    },
    "categoriesStd",
    undefined,
    undefined,
    !myDetails
  );
  const [value, setValue] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<CategorySTD>();

  useEffect(() => {
    if (selectedCategory || !categoriesSTD) return;

    setSelectedCategory(categoriesSTD[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesSTD]);

  const { data: productsSTD, isLoading: isLoadingProducts } = useOperation<
    ProductSTD[]
  >(
    selectedCategory,
    {
      link: "products_std_by_category",
    },
    "products_std_by_category" + selectedCategory?.id,
    undefined,
    undefined,
    !selectedCategory
  );

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const [openModalAddCategory, setOpenModalAddCategory] = useState(false);

  return (
    <ResponsiveDrawer
      title={
        (selectedCategory?.title || "") +
        " " +
        t("pages.products.list_of_products.menu")
      }
    >
      <UnlockDialog />
      <Section title="" mt={0}>
        {isLoading ? (
          <Box sx={{ display: "flex", mb: 2 }}>
            <CircularProgress sx={{ color: primaryColor().primary }} />
          </Box>
        ) : (
          <Box>
            <ProductsContextMenu activeMenu="products" />

            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Custom Tabs"
              variant="scrollable"
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: themeColors.lightGray,
                "& .MuiTab-root": {
                  marginRight: direction === "ltr" ? "14px" : "0px",
                  marginLeft: direction === "rtl" ? "14px" : "0px",
                  "&:last-child": {
                    marginRight: direction === "ltr" ? "14px" : "0px",
                    marginLeft: direction === "rtl" ? "14px" : "0px",
                  },
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                mb: 2,
                pb: 1,
                zIndex: 2,
              }}
              TabScrollButtonProps={{
                sx: {
                  backgroundColor: themeColors.white,
                  borderRadius: "20px",
                  height: "40px",
                  width: "40px",
                  marginTop: { xs: 2.5, md: 5 },
                  marginLeft: 1,
                  marginRight: 1,
                  "& svg": {
                    fontSize: 18,
                  },
                },
              }}
            >
              {categoriesSTD?.map((category: CategorySTD) => (
                <Tab
                  key={category.id}
                  label={
                    <>
                      <Box
                        sx={{
                          display: { xs: "none", md: "flex" },
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            category.img ||
                            `https://fakeimg.pl/600x400?text=${category.title}`
                          }
                          alt={category.title}
                          style={{ width: 54, height: 54 }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: 14,
                            lineHeight: "28px",
                            leadingTrim: "both",
                            textEdge: "cap",
                            color: themeColors.darkBlue,
                          }}
                        >
                          {category.title}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: { xs: "flex", md: "none" },
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            category.img ||
                            `https://fakeimg.pl/600x400?text=${category.title}`
                          }
                          alt={category.title}
                          style={{ width: 27, height: 27 }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: 12,
                            lineHeight: "24px",
                            leadingTrim: "both",
                            textEdge: "cap",
                            color: themeColors.darkBlue,
                            textOverflow: "ellipsis",
                            width: 64,
                            overflow: "hidden",
                          }}
                        >
                          {category.title}
                        </Typography>
                      </Box>
                    </>
                  }
                  sx={{
                    width: { md: 103, xs: 74 },
                    minWidth: { md: 103, xs: 74 },
                    height: { md: 126, xs: 84 },
                    background: themeColors.white,
                    border: "1px solid " + themeColors.gray,
                    borderRadius: "15px",
                    "&.Mui-selected": {
                      background: primaryColor().primaryLinearGrad,
                      border: "1px solid " + primaryColor().primary,
                      borderRadius: "15px",
                      color: primaryColor().primary,
                    },
                  }}
                  onClick={() => {
                    setSelectedCategory(category);
                  }}
                />
              ))}
              <Tab
                key={"add"}
                label={
                  <>
                    <Box
                      sx={{
                        display: { xs: "none", md: "flex" },
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Add
                        style={{
                          width: 54,
                          height: 54,
                          color: themeColors.midnightBlueMiddle,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: { xs: "flex", md: "none" },
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Add
                        style={{
                          width: 27,
                          height: 27,
                          color: themeColors.midnightBlueMiddle,
                        }}
                      />
                    </Box>
                  </>
                }
                sx={{
                  width: { md: 103, xs: 74 },
                  minWidth: { md: 103, xs: 74 },
                  height: { md: 126, xs: 84 },
                  background: themeColors.white,
                  border: "1px solid " + themeColors.gray,
                  borderRadius: "15px",
                  "&.Mui-selected": {
                    background: primaryColor().primaryLinearGrad,
                    border: "1px solid " + primaryColor().primary,
                    borderRadius: "15px",
                    color: primaryColor().primary,
                  },
                }}
                onClick={() => {
                  setOpenModalAddCategory(true);
                }}
              />
            </Tabs>
          </Box>
        )}

        <Grid container spacing={1}>
          {isLoadingProducts ? (
            <Box sx={{ display: "flex", ml: 2, mt: 2 }}>
              <CircularProgress sx={{ color: primaryColor().primary }} />
            </Box>
          ) : (
            <>
              {productsSTD?.map((product: ProductSTD) => (
                <Grid
                  key={product.id}
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={showCart ? 3 : 2}
                  xl={2}
                >
                  <Product product={product} />
                </Grid>
              ))}
            </>
          )}
        </Grid>
        <AddNewCategoryModal
          openModal={openModalAddCategory}
          setOpenModal={setOpenModalAddCategory}
        />
      </Section>
    </ResponsiveDrawer>
  );
};

type AddNewCategoryModalProps = {
  openModal: boolean;
  setOpenModal: any;
};

const AddNewCategoryModal = ({
  openModal,
  setOpenModal,
}: AddNewCategoryModalProps) => {
  const { myDetails } = useAppContext();
  const { t } = useTranslation("common");
  const appQueryClient = useQueryClient();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [title, setTitle] = useState<string>();
  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(myDetails, "api_create_custom_category", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess || isLoading) return;
    setError("");
    setSuccess(t("pages.products.product.category_saved") + ": " + title);
    appQueryClient.invalidateQueries(["products_std_by_category"]);
    appQueryClient.invalidateQueries(["categoriesStd"]);
    appQueryClient.invalidateQueries(["myProducts"]);
    handleCloseModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply, isLoading]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };
  const handleCloseModal = () => setOpenModal(false);
  return (
    <>
      <ErrorToast error={error} success={success} handleClose={handleClose} />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            border: 0,
            boxShadow: `0px 11px 64px ${themeColors.black007}`,
            p: 4,
            outline: 0,
          }}
        >
          <DirTheme>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                mb: 4,
              }}
            >
              {t("pages.products.product.add_new_category")}
            </Typography>
            <TextField
              sx={{ mb: 4, fontStyle: "normal" }}
              label={t("pages.products.product.title")}
              variant="outlined"
              fullWidth
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              value={title}
              size="small"
            />
          </DirTheme>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                sx={{
                  border: "1px solid " + primaryColor().primary,
                  borderRadius: "82px",
                  backgroundColor: themeColors.white,
                  color: primaryColor().primary,
                  fontStyle: "normal",
                  fontWeight: 600,
                  marginRight: 1,
                }}
                fullWidth
                onClick={handleCloseModal}
              >
                {t("pages.products.product.cancel")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{
                  borderRadius: "82px",
                  backgroundColor: primaryColor().primary,
                  ":hover": {
                    background: primaryColor().primary,
                  },
                  color: themeColors.white,
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
                fullWidth
                variant="contained"
                color="primary"
                disabled={!title || isLoading}
                onClick={() => {
                  onSubmit({
                    title: title,
                  } as any);
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: themeColors.white }}
                  />
                ) : (
                  t("pages.products.product.save")
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>{" "}
    </>
  );
};
