import { AxiosProvider, WebServiceContextProvider } from "Components/api";
import { AppContextProvider, AppRoutes } from "App";
import { ReactQueryDevtools } from "react-query/devtools";
import "./Application.css";

import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/700.css";
import { ProvideAuth } from "App/utils/auth";
import i18next from "i18next";
import common_en from "locales/common_en.json";
import common_fr from "locales/common_fr.json";
import common_ar from "locales/common_ar.json";
import { I18nextProvider } from "react-i18next";
import { useEffect } from "react";
import { themeColors } from "Kcafi/Pages";
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme,
} from "@mui/material";
const theme = unstable_createMuiStrictModeTheme();
export const Application: React.FC = () => {
  const language = localStorage.getItem("lang");

  useEffect(() => {
    if (!language) return;
    document.documentElement.lang = language;
  }, [language]);

  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: language || "en", //                              // language to use
    resources: {
      en: {
        common: common_en, // 'common' is our custom namespace
      },
      fr: {
        common: common_fr,
      },
      ar: {
        common: common_ar,
      },
    },
  });
  return (
    <ThemeProvider theme={theme} >
        <AxiosProvider>
          <AppContextProvider>
            <ProvideAuth>
              <WebServiceContextProvider>
                <I18nextProvider i18n={i18next}>
                  <AppRoutes />
                </I18nextProvider>
              </WebServiceContextProvider>
            </ProvideAuth>
          </AppContextProvider>
        </AxiosProvider>
        <ReactQueryDevtools initialIsOpen={false} />
    </ThemeProvider>
  );
};
