import { Section } from "../../DashboardPage";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { primaryColor, themeColors } from "../..";
import { useCommand, useOperation } from "Components/api";
import { useAppContext } from "App";
import { useState, useEffect } from "react";
import { ErrorToast } from "Kcafi/Pages/ErrorToast";
import { SessionLabel } from "Kcafi/Pages/Session/SessionLabel";
import { PermissionForWorkers } from "./PermissionForWorkers";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import DirTheme from "Kcafi/Pages/DirTheme";

export type MyWorkerType = {
  id: number;
  uid: string;
  email: string;
  nom: string;
  img: string;
  prenom: string;
  telephone: string;
  permissions: any;
};

export const WorkerManagement = () => {
  const { t } = useTranslation("common");
  const appQueryClient = useQueryClient();
  const { myDetails } = useAppContext();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [value, setValue] = useState("");
  const [activeWorker, setActiveWorker] = useState();
  const [selectedWorker, setSelectedWorker] = useState<MyWorkerType>();
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(myDetails, "update_activity_add_worker", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setValue("");
    setSuccess(t("pages.settings.worker.update_worker_success"));
    appQueryClient.invalidateQueries(["settings_activity_workers"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);
  const handleClose = () => {
    setError("");
    setSuccess("");
  };
  const { data: myWorkers, isLoading: isLoadingWorkers } = useOperation<
    MyWorkerType[]
  >(
    myDetails,
    {
      link: "settings_activity_workers",
    },
    "settings_activity_workers",
    {} as any,
    undefined,
    !myDetails
  );

  const handleTabsChange = (event: any, newValue: any) => {
    setActiveWorker(newValue);
  };

  return (
    <Card
      sx={{
        boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
        padding: "15px",
        borderRadius: "10px",
        marginBottom: "25px",
      }}
    >
      <Section
        title={t("pages.settings.worker.title")}
        description={t("pages.settings.worker.description")}
      >
        <ErrorToast error={error} success={success} handleClose={handleClose} />
        <Box
          sx={{
            display: "flex",
            mb: 2,
          }}
        >
          <DirTheme>
            <TextField
              type="text"
              label={t("pages.settings.worker.uid")}
              variant="outlined"
              value={value}
              onChange={handleChange}
              fullWidth
              sx={{
                maxWidth: "200px",
              }}
            />
          </DirTheme>
          <Button
            sx={{
              borderRadius: "4px",
              backgroundColor: primaryColor().primary,
              ":hover": {
                background: primaryColor().primary,
              },
              color: themeColors.white,
              fontStyle: "normal",
              fontWeight: 600,
              height: "54px",
              marginLeft: "10px",
              marginRight: "10px",
              width: "120px",
            }}
            variant="contained"
            color="primary"
            onClick={() =>
              onSubmit({
                uid: value,
              })
            }
            disabled={!value}
          >
            {isLoading ? (
              <CircularProgress size={20} sx={{ color: themeColors.white }} />
            ) : (
              t("pages.settings.worker.add")
            )}
          </Button>
        </Box>

        {isLoadingWorkers ? (
          <CircularProgress size={20} sx={{ color: primaryColor().primary }} />
        ) : (
          <>
            <Tabs
              value={activeWorker}
              onChange={handleTabsChange}
              aria-label="Custom Tabs"
              variant="scrollable"
              sx={{
                backgroundColor: themeColors.transparent,
                "& .MuiTab-root": {
                  marginRight: direction === "ltr" ? "14px" : "0px",
                  marginLeft: direction === "rtl" ? "14px" : "0px",
                  "&:last-child": {
                    marginRight: direction === "ltr" ? "14px" : "0px",
                    marginLeft: direction === "rtl" ? "14px" : "0px",
                  },
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                mb: 2,
              }}
              TabScrollButtonProps={{
                sx: {
                  backgroundColor: themeColors.white,
                  borderRadius: "20px",
                  height: "40px",
                  width: "40px",
                  marginTop: { xs: 2.5, md: 5 },
                  marginLeft: 1,
                  marginRight: 1,
                  "& svg": {
                    fontSize: 18,
                  },
                },
              }}
            >
              {myWorkers?.map((worker) => (
                <Tab
                  key={worker.id}
                  label={
                    <>
                      <Box
                        sx={{
                          display: { xs: "none", md: "flex" },
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: 14,
                            lineHeight: "18px",
                            leadingTrim: "both",
                            textEdge: "cap",
                            color: themeColors.darkBlue,
                          }}
                        >
                          {worker.prenom}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: { xs: "flex", md: "none" },
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: 12,
                            lineHeight: "14px",
                            leadingTrim: "both",
                            textEdge: "cap",
                            color: themeColors.darkBlue,
                            textOverflow: "ellipsis",
                            width: 64,
                            overflow: "hidden",
                          }}
                        >
                          {worker.nom}
                        </Typography>
                      </Box>
                    </>
                  }
                  sx={{
                    width: { md: 103, xs: 74 },
                    minWidth: { md: 103, xs: 74 },
                    height: { md: 66, xs: 64 },
                    background: themeColors.white075,
                    border: "1px solid " + themeColors.midnightBlueLight,
                    borderRadius: "15px",
                    "&.Mui-selected": {
                      background: primaryColor().primaryLinearGrad,
                      border: "1px solid " + primaryColor().primary,
                      borderRadius: "15px",
                      color: primaryColor().primary,
                    },
                  }}
                  onClick={() => {
                    setSelectedWorker(undefined);
                    setSelectedWorker(worker);
                  }}
                />
              ))}
            </Tabs>

            {selectedWorker && (
              <Box
                sx={{
                  background: themeColors.white,
                  borderRadius: "15.53px",
                  padding: "25px 5px",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <SessionLabel
                  labelCols={3}
                  label={t("pages.settings.worker.uid")}
                  text={`${selectedWorker.uid}`}
                />
                <SessionLabel
                  labelCols={3}
                  label={t("pages.settings.worker.name")}
                  text={`${selectedWorker.nom}`}
                />
                <SessionLabel
                  labelCols={3}
                  label={t("pages.settings.worker.last_name")}
                  text={`${selectedWorker.prenom}`}
                />
                <SessionLabel
                  labelCols={3}
                  label={t("pages.settings.worker.email")}
                  text={`${selectedWorker.email}`}
                />
                <SessionLabel
                  labelCols={3}
                  label={t("pages.settings.worker.tel")}
                  text={`${selectedWorker.telephone || "-"}`}
                />

                {selectedWorker && (
                  <PermissionForWorkers
                    worker={selectedWorker}
                    setSelectedWorker={setSelectedWorker}
                  />
                )}
              </Box>
            )}
          </>
        )}
      </Section>
    </Card>
  );
};
