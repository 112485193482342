import {
  Box,
  Grid,
  Typography,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Paper,
  MenuList,
  useMediaQuery,
  Button,
  Badge,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCartOutlined";

import {
  Home,
  Fastfood,
  AccessTime,
  GridView,
  Settings,
  Logout,
  Person,
  BarChart,
  ArrowForwardIos,
  ConfirmationNumber,
  FiberManualRecord,
  FilePresentOutlined,
  FileOpen,
} from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Cart } from "./Pages/Caisse/Cart";
import useLocalStorage from "App/components/useLocalStorage";
import { useAppContext } from "App";
import { useAuth } from "App/utils/auth";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { primaryColor, themeColors } from "./Pages";
import { CloseSessionDialog } from "./Pages/Session/CloseSessionDialog";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./Pages/Settings/components/LanguageSelector ";
import DirTheme from "./Pages/DirTheme";
import { LockDialog } from "./Pages/Caisse/LockDialog";

type ResponsiveDrawerProps = {
  title: string;
  children?: any;
};
const ResponsiveDrawer = ({ title, children }: ResponsiveDrawerProps) => {
  const { t } = useTranslation("common");
  const auth = useAuth();

  const { myDetails } = useAppContext();
  const isLarge = useMediaQuery("(min-width:1204px)");
  const history = useHistory();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCart, setShowCart] = useLocalStorage("showCart", true);
  useEffect(() => {
    setShowCart(isLarge && pathname === "/caisse");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLarge, pathname]);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const DrawerContent = ({ isMobile, items }: any) => (
    <Box sx={{ p: 2, display: isMobile ? "contents" : "block" }}>
      {!isMobile && (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <Typography
            variant="caption"
            sx={{
              p: 4,
              color: themeColors.brown,
              fontSize: "32px",
              fontWeight: 700,
            }}
          >
            K
          </Typography>
        </Box>
      )}
      {items.map((item: any, index: any) => (
        <Box
          key={item.title}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: isMobile ? 64 : 94, // Make the item smaller on mobile
            height: isMobile ? 64 : 94, // Make the item smaller on mobile
            borderRadius: "15px",
            m: isMobile ? "6px" : 2,
            position: "relative",
            //mt: 2,
            backgroundColor:
              pathname === item.route
                ? primaryColor().primary
                : index === 2 || index === 3
                ? themeColors.lightGray
                : themeColors.white,
            color:
              pathname === item.route
                ? themeColors.white
                : themeColors.lightPeriwinkleBlue,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: primaryColor().primary,
              color: themeColors.white,
            },
            pointerEvents:
              (index === 3 && !myDetails.hasActiveSession) || item.disabled
                ? "none"
                : "click",
            opacity:
              (index === 3 && !myDetails.hasActiveSession) || item.disabled
                ? 0.5
                : 1,
          }}
          onClick={() => {
            if (index !== 5) history.push(item.route);
            else auth.signout((_: any) => history.replace("/login"));
          }}
        >
          {index === 2 && (
            <FiberManualRecord
              sx={{
                position: "absolute",
                top: isMobile ? 2.25 : 5,
                right: isMobile ? 2.25 : 5,
                color: myDetails.hasActiveSession
                  ? themeColors.green
                  : themeColors.red,
                fontSize: 16,
              }}
            />
          )}
          {index === 3 && (
            <Badge
              color="info"
              sx={{
                position: "absolute",
                top: isMobile ? 7 : 5,
                right: isMobile ? 7 : 5,
              }}
              badgeContent={myDetails.activeSessionTotalTickets || 0}
            ></Badge>
          )}

          {index === 0 && <Home sx={{ fontSize: isMobile ? 22 : 40 }} />}
          {index === 1 && <AccessTime sx={{ fontSize: isMobile ? 22 : 40 }} />}
          {index === 2 && <Fastfood sx={{ fontSize: isMobile ? 22 : 40 }} />}
          {index === 3 && (
            <ConfirmationNumber sx={{ fontSize: isMobile ? 22 : 40 }} />
          )}
          {index === 4 && <GridView sx={{ fontSize: isMobile ? 22 : 40 }} />}
          {index === 6 && <BarChart sx={{ fontSize: isMobile ? 22 : 40 }} />}
          {index === 5 && <Logout sx={{ fontSize: isMobile ? 22 : 40 }} />}
          <Typography
            variant="caption"
            sx={{
              pt: 1,
              fontSize: isMobile ? "10px" : "14px",
              fontWeight: 500,
            }}
          >
            {item.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
  const handle = useFullScreenHandle();
  return (
    <FullScreen handle={handle}>
      <Box sx={{ display: "flex", flexDirection: "column", height: "0vh" }}>
        {/* Sidebar */}
        <Box
          component="nav"
          sx={{
            width: {
              sm: 170,
            },
            display: { xs: "none", sm: "block" },
          }}
        >
          <Drawer
            variant="permanent"
            open
            PaperProps={{
              sx: { border: 0 },
            }}
          >
            <DrawerContent
              isMobile={false}
              items={[
                {
                  title: t("pages.menu.home"),
                  route: "/",
                },
                {
                  title: t("pages.menu.sessions"),
                  route: "/sessions",
                  disabled: !myDetails.permissions?.Sessions?.ReadSessions,
                },
                {
                  title: t("pages.menu.caisse"),
                  route: "/caisse",
                  disabled: !myDetails.permissions?.Caisse?.ReadCaisse,
                },
                {
                  title: t("pages.menu.tickets"),
                  route: myDetails.activeSessionId
                    ? `/sessions/${myDetails.activeSessionId}/tickets`
                    : null,
                  disabled: !myDetails.permissions?.Caisse?.ReadCaisse,
                },
                {
                  title: t("pages.menu.products"),
                  route: "/products",
                  disabled: !myDetails.permissions?.Products?.ReadProducts,
                },
                {
                  title: t("pages.menu.logout"),
                  route: "/login",
                },
              ]}
            />
          </Drawer>
        </Box>

        {/* Bottom bar for mobile */}
        <Box
          component="nav"
          sx={{
            width: "94%",
            display: { xs: "block", sm: "none" },
            position: "fixed",
            bottom: 20,
            left: "3%",
            zIndex: 2,
            borderRadius: "17px",
            backgroundColor: themeColors.white,
            height: "75px",
            boxShadow: themeColors.darkShadow,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <DrawerContent
              isMobile={true}
              items={[
                {
                  title: t("pages.menu.home"),
                  route: "/",
                },
                {
                  title: t("pages.menu.sessions"),
                  route: "/sessions",
                  disabled: !myDetails.permissions?.Sessions?.ReadSessions,
                },
                {
                  title: t("pages.menu.caisse"),
                  route: "/caisse",
                  disabled: !myDetails.permissions?.Caisse?.ReadCaisse,
                },
                {
                  title: t("pages.menu.tickets"),
                  route: myDetails.activeSessionId
                    ? `/sessions/${myDetails.activeSessionId}/tickets`
                    : null,
                  disabled: !myDetails.permissions?.Sessions?.ReadSessions,
                },
                {
                  title: t("pages.menu.products"),
                  route: "/products",
                  disabled: !myDetails.permissions?.Products?.ReadProducts,
                },
              ]}
            />
          </Box>
        </Box>

        {/* Main content area */}
        <Box
          component="main"
          sx={{
            px: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            py: 3,
            marginLeft: {
              sm: "152px",
              xs: "0px",
            },
            width: {
              md: "calc(100% - 200px)",
              sm: "calc(100% - 200px)",
            },
          }}
        >
          {/* Header */}
          <Box
            sx={{
              position: "fixed",
              background: themeColors.white,
              top: "0",
              left: { md: "154px", sm: "154px", xs: "0px" },
              padding: "11px 15px",
              zIndex: 3,
              width: {
                md: "calc(100% - 185px)",
                sm: "calc(100% - 185px)",
                xs: "calc(100% - 30px)",
              },
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs={12} sm={12} md={6}>
                <Typography
                  variant="h6"
                  sx={{
                    display: { xs: "none", sm: "none", md: "block" },
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "28px",
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                container
                alignItems="center"
                justifyContent={{
                  xs: "space-between",
                  sm: "space-between",
                  md: "end",
                  flexWrap: "nowrap",
                }}
                sx={{ zIndex: 1 }}
              >
                {myDetails.permissions?.Caisse?.WriteCaisse && (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CloseSessionDialog />
                    <CartButton showCart={showCart} setShowCart={setShowCart} />
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    textAlign: { xs: "right", sm: "right" },
                  }}
                >
                  {myDetails?.avatarUrl ? (
                    <Avatar
                      sx={{
                        backgroundColor: primaryColor().primary,
                        ml: 4,
                        width: {
                          xs: 34,
                          sm: 34,
                          md: 44,
                        },
                        height: {
                          xs: 34,
                          sm: 34,
                          md: 44,
                        },
                        cursor: "pointer",
                        textTransform: "uppercase",
                      }}
                      onClick={handleMenuOpen}
                      src={myDetails?.avatarUrl}
                    >
                      {myDetails?.email ? myDetails?.email[0] : ""}
                    </Avatar>
                  ) : (
                    <Avatar
                      sx={{
                        backgroundColor: primaryColor().primary,
                        ml: 4,
                        width: {
                          xs: 32,
                          sm: 32,
                          md: 40,
                        },
                        height: {
                          xs: 32,
                          sm: 32,
                          md: 40,
                        },
                        cursor: "pointer",
                        textTransform: "uppercase",
                      }}
                      onClick={handleMenuOpen}
                    >
                      {myDetails?.email ? myDetails?.email[0] : ""}
                    </Avatar>
                  )}

                  <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <Paper
                      sx={{ width: 320, maxWidth: "100%", boxShadow: "unset" }}
                    >
                      <DirTheme>
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              history.push("/profile");
                              handleMenuClose();
                            }}
                          >
                            <ListItemIcon>
                              <Person />
                            </ListItemIcon>
                            <ListItemText>
                              {t("pages.menu.profile")}
                            </ListItemText>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              history.push("/settings");
                              handleMenuClose();
                            }}
                          >
                            <ListItemIcon>
                              <Settings />
                            </ListItemIcon>
                            <ListItemText>
                              {t("pages.menu.settings")}
                            </ListItemText>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              history.push("/invoices");
                              handleMenuClose();
                            }}
                          >
                            <ListItemIcon>
                              <FileOpen />
                            </ListItemIcon>
                            <ListItemText>
                              {t("pages.menu.subscription")}
                            </ListItemText>
                          </MenuItem>
                          <MenuItem>
                            <LockDialog handleMenuClose={handleMenuClose} />
                          </MenuItem>
                          <MenuItem>
                            <LanguageSelector />
                          </MenuItem>
                          <Divider />
                          <MenuItem
                            onClick={() => {
                              history.push("/login");
                              handleMenuClose();
                            }}
                          >
                            <ListItemIcon>
                              <Logout fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              onClick={() => {
                                auth.signout((_: any) =>
                                  history.replace("/login")
                                );
                              }}
                            >
                              {t("pages.menu.logout")}
                            </ListItemText>
                          </MenuItem>
                        </MenuList>
                      </DirTheme>
                    </Paper>
                  </Menu>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            mt={"54px"}
            sx={{
              height: "calc(100vh - 108px)",
              overflow: showCart ? "hidden" : "auto",
            }}
          >
            <Grid container>
              <Grid
                dir={direction}
                item
                xs={12}
                sm={12}
                md={12}
                lg={showCart ? 9 : 12}
                xl={showCart ? 9 : 12}
              >
                {children}
              </Grid>
              <Grid
                item
                xs={showCart ? 12 : 0}
                sm={showCart ? 12 : 0}
                md={showCart ? 4 : 0}
                lg={showCart ? 3 : 0}
                xl={showCart ? 3 : 0}
              >
                {showCart && <Cart showCart setShowCart={setShowCart} />}
              </Grid>
            </Grid>

            <Box
              sx={{
                height: "124px",
              }}
            ></Box>
          </Box>
        </Box>
      </Box>
    </FullScreen>
  );
};

export default ResponsiveDrawer;

const CartButton = ({ showCart, setShowCart }: any) => {
  const { t } = useTranslation("common");
  const { animationPosition, cartManager } = useAppContext();
  return (
    <Button
      size="small"
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      className="cart-button"
      onClick={() => {
        setShowCart(!showCart);
      }}
      sx={{
        background: primaryColor().primary17,
        borderRadius: "8px",
        color: primaryColor().primary,
        textAlign: { xs: "right", sm: "right" },
        animation: animationPosition ? "pulse 0.7s" : "",
        ":hover": {
          background: primaryColor().primary17,
          //animation: "pulse 0.5s",
        },
        "@keyframes pulse": {
          "0%, 100%": {
            transform: "scale(1)",
            background: primaryColor().primary17,
          },
          "50%": {
            transform: "scale(1.08)",
            background: primaryColor().primary17,
          },
        },
        height: {
          xs: 38,
          sm: 38,
          md: 44,
        },
      }}
    >
      <Avatar
        sx={{
          backgroundColor: themeColors.transparent,
        }}
      >
        <ShoppingCartIcon style={{ color: primaryColor().primary }} />
      </Avatar>
      <Typography
        variant="caption"
        sx={{
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: { xs: "14px", sm: "14px", md: "16px" },
          lineHeight: "23px",
          leadingTrim: "both",
          textEdge: "uppercase",
          whiteSpace: "nowrap",
        }}
      >
        {cartManager.getTotalProducts()}x {t("pages.menu.products")}
      </Typography>
      <Avatar
        sx={{
          backgroundColor: themeColors.transparent,
        }}
      >
        <ArrowForwardIos style={{ color: primaryColor().primary }} />
      </Avatar>
    </Button>
  );
};
