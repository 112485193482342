import { Section } from "../../DashboardPage";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppContext } from "App";
import { useCommand } from "Components/api";
import { ErrorToast } from "../../ErrorToast";
import { colors, primaryColor, themeColors } from "../..";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import DirTheme from "Kcafi/Pages/DirTheme";

export const ThemeSettings = () => {
  const { t } = useTranslation("common");
  const appQueryClient = useQueryClient();
  const { myDetails } = useAppContext();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [value, setValue] = useState(myDetails.activityThemeColor);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(myDetails, "update_theme", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    const f = colors?.find((c) => c.name === value);
    if (f) {
      localStorage.setItem("activityThemeColor", f.code);
    }

    setError("");
    setSuccess(t("pages.settings.theme.update_theme_success"));
    appQueryClient.invalidateQueries(["My:Details"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };

  return (
    <Card
      sx={{
        boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
        padding: "15px",
        borderRadius: "10px",
        marginBottom: "25px",
      }}
    >
      <Section
        title={t("pages.settings.theme.title")}
        description={t("pages.settings.theme.description")}
      >
        <ErrorToast error={error} success={success} handleClose={handleClose} />
        <Box>
          <DirTheme>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {colors.map((color, index) => (
                <FormControlLabel
                  value={color.name}
                  control={
                    <Radio
                      sx={{
                        color: color.code,
                        "&.Mui-checked": {
                          color: color.code,
                        },
                      }}
                    />
                  }
                  label={color.name}
                />
              ))}
            </RadioGroup>
          </DirTheme>
          <Button
            sx={{
              background: primaryColor().primary,
              borderRadius: "4px",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              ":hover": {
                background: primaryColor().primary,
              },
              mb: 6,
              boxShadow: `0px 11px 64px ${themeColors.black007}`,
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              setError("");
              setSuccess("");
              onSubmit({
                color: value,
                _method: "PUT",
              } as any);
            }}
            disabled={isLoading || !value}
          >
            {isLoading ? (
              <CircularProgress size={20} sx={{ color: themeColors.white }} />
            ) : (
              t("pages.settings.theme.save")
            )}
          </Button>
        </Box>
      </Section>
    </Card>
  );
};
