import {
  createSafeContext,
  fetchAxios,
  IWebService,
  resolveApiUrl,
  useAxios,
  useSafeContext,
} from "Components/api";

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { UserDetails, useProvideAuth } from "./auth";
import { CartManager } from "Kcafi/Pages/Caisse/CartManager";

// export const API_URL = resolveApiUrl();
let AppContext = createSafeContext<any>();
export const useAppContext = () => useSafeContext<IAppContextValue>(AppContext);

type IAppContextValue = {
  myDetails: UserDetails;
  error: Error | null;
  myRoles: string[];
  ownerRoles: string[];
  currentOwnerRole: string | undefined;
  setCurrentOwnerRole: React.Dispatch<React.SetStateAction<string | undefined>>;
  // TODO: (Auth) API_URL: string;
  // TODO: (Auth) setAPI_URL: React.Dispatch<React.SetStateAction<string>>;
  flashMessage: string;
  setFlashMessage: React.Dispatch<React.SetStateAction<string>>;
  ws: IWebService | undefined;
  isDevelopmentEnvironment: boolean;
  hasRole: (role: string) => boolean;
  findFirstRole: (roleName: string) => string | undefined;
  isLoading: boolean;
  isAuthenticated: boolean;
  loadingText: string;
  animationPosition: any;
  setAnimationPosition: any;
  cartManager: CartManager;
  setCartManager: React.Dispatch<React.SetStateAction<CartManager>>;
  //refetchMyAccounts:any;
};

const checkRole = (
  myDetails: UserDetails,
  roles: any,
  roleName: string
): boolean => {
  if (!roles || roles.length === 0) {
    // Allow Admin to have all roles
    if (roleName === "ROLE_ADMIN" && myDetails.username === "admin")
      return true;
    return false;
  }

  let result = false;
  // eslint-disable-next-line array-callback-return
  roles.map((x: any) => {
    if (x.RoleName === roleName) {
      result = true;
      // eslint-disable-next-line array-callback-return
      return; // Early exit:
    }
  });
  return result;
};

const findFirstRole = (
  roles: string[] | undefined,
  roleName: string
): string | undefined => {
  let result: string | undefined = undefined;
  if (!roles) return undefined;

  // eslint-disable-next-line array-callback-return
  roles.map((x: string) => {
    if (x === roleName) {
      result = x;
      // eslint-disable-next-line array-callback-return
      return;
    }
  });
  return result;
};

const findRoles = (roles: string[] | undefined, roleName: string): string[] =>
  !roles ? [] : roles?.filter((x: string) => x === roleName);

export const AppContextProvider = (props: any) => {
  const [flashMessage, setFlashMessage] = useState("");
  const [ownerRoles, setOwnerRoles] = useState<string[]>([]);
  const [currentOwnerRole, setCurrentOwnerRole] =
    useState<string | undefined>(undefined);

  const [cartManager, setCartManager] = useState(new CartManager());

  const [animationPosition, setAnimationPosition] = useState(false);

  const [loadingText, setLoadingText] = useState("Starting application");

  // TODO: const [API_URL, setAPI_URL] = useState(resolveApiUrl);
  const axios = useAxios();

  const {
    data: ws,
    isLoading: isLoadingWs,
    error = null,
  } = useQuery<IWebService, Error>("WebServiceComponent", () =>
    fetchAxios<IWebService>(axios, resolveApiUrl(), "WebServiceComponent")
  );
  const {
    user,
    isLoading: isLoadingAuth,
    isAuthenticated,
    isLoadingDetails,
  } = useProvideAuth();

  const myRoles = user.roles;
  useEffect(() => {
    const myOwnerRoles = findRoles(myRoles, "ROLE_USER");

    if (ownerRoles !== myOwnerRoles) setOwnerRoles(myOwnerRoles);

    if (!currentOwnerRole && myOwnerRoles.length > 0)
      setCurrentOwnerRole(myOwnerRoles[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (isLoadingDetails) {
      setLoadingText("");
      return;
    }

    if (isLoadingWs) {
      setLoadingText("");
      return;
    }
    setLoadingText("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAuth, isLoadingWs]);

  const value: IAppContextValue = {
    error,
    flashMessage,
    setFlashMessage,
    ws,
    myDetails: user as UserDetails,
    myRoles: myRoles || [],
    ownerRoles,
    currentOwnerRole,
    isAuthenticated,
    isLoading: isLoadingAuth || isLoadingWs || isLoadingDetails,
    isDevelopmentEnvironment: true,
    loadingText,
    setCurrentOwnerRole,
    hasRole: (roleName: string): boolean =>
      checkRole(user, myRoles || [], roleName),
    findFirstRole: (roleName: string) => findFirstRole(myRoles, roleName),
    animationPosition,
    setAnimationPosition,
    cartManager, setCartManager
  };
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};

export default AppContextProvider;
