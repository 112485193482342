import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Box, InputAdornment } from "@mui/material";
import { useAppContext } from "App";
import { primaryColor } from "Kcafi/Pages";
import { useTranslation } from "react-i18next";
import DirTheme from "Kcafi/Pages/DirTheme";
export const UidInput = () => {
  const { t } = useTranslation("common");
  const { myDetails } = useAppContext();
  const [inputValue, setInputValue] = useState(myDetails.uid || "");
  const [isHidden, setIsHidden] = useState(true);

  const handleToggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(inputValue).then(() => {
      console.log(t("pages.settings.account_uid.text_copied"));
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <DirTheme>
        <TextField
          type={isHidden ? "password" : "text"}
          label={t("pages.settings.account_uid.uid")}
          variant="outlined"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleVisibility} edge="end">
                  {isHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          sx={{
            maxWidth: "200px",
          }}
        />
      </DirTheme>
      <Button
        onClick={handleCopyToClipboard}
        sx={{
          background: primaryColor().primary17,
          borderRadius: "4px",
          color: primaryColor().primary,
          textAlign: { xs: "right", sm: "right" },
          ":hover": {
            background: primaryColor().primary17,
          },
          marginLeft: "10px",
          marginRight: "10px",
          height: "55px",
          width: "125px",
          paddingLeft: 1,
          paddingRight: 1,
        }}
      >
        <FileCopyIcon />{" "}
        <Box ml={1} mr={1}>
          {t("pages.settings.account_uid.copy")}
        </Box>
      </Button>
    </Box>
  );
};
