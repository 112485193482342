import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useAppContext } from "App";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { primaryColor, themeColors } from "..";
import { ErrorToast } from "../ErrorToast";
import DirTheme from "../DirTheme";
import { useCommand } from "Components/api";
import { useHistory } from "react-router-dom";

export const UnlockDialog = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const appQueryClient = useQueryClient();
  const { myDetails } = useAppContext();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [open, setOpen] = useState(!!myDetails.locked);
  const [codePin, setCodePin] = useState<string>();
  const [isHidden, setIsHidden] = useState(true);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodePin((event.target as HTMLInputElement).value);
  };
  const handleToggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(myDetails, "update_profile_security_unlock", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setCodePin("");
    //setSuccess(t("pages.settings.security.security_unlocked"));
    appQueryClient.invalidateQueries(["My:Details"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };

  const handleClosedialog = () => {
    if (myDetails.locked)
      setError(t("pages.settings.security.enter_pin_code_to_unlock"));
    else setOpen(false);
  };

  useEffect(() => {
    setOpen(!!myDetails.locked);
  }, [myDetails.locked]);

  return (
    <Dialog maxWidth={"sm"} open={open} onClose={handleClosedialog}>
      <DialogTitle>
        {t("pages.settings.security.enter_pin_code_to_unlock")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <ErrorToast
            error={error}
            success={success}
            handleClose={handleClose}
          />
          <DirTheme>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                type={isHidden ? "password" : "text"}
                label={t("pages.settings.security.code_pin")}
                variant="outlined"
                value={codePin}
                onChange={handleChange}
                fullWidth
                sx={{
                  maxWidth: "200px",
                  mt: 2,
                  mb: 2,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleToggleVisibility} edge="end">
                        {isHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography
                onClick={() => {
                  history.push("/settings");
                }}
                variant="body2"
                sx={{
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "142.19%",
                  mb: 2,
                  cursor: "pointer",
                }}
              >
                {t("pages.settings.security.forgot_code_pin")}
              </Typography>

              <Button
                sx={{
                  borderRadius: "4px",
                  backgroundColor: primaryColor().primary,
                  ":hover": {
                    background: primaryColor().primary,
                  },
                  color: themeColors.white,
                  fontStyle: "normal",
                  fontWeight: 600,
                  height: "54px",
                  width: "140px",
                }}
                variant="contained"
                color="primary"
                onClick={() =>
                  onSubmit({
                    codePin: codePin,
                  })
                }
              >
                {isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: themeColors.white }}
                  />
                ) : (
                  t("pages.settings.security.unlock")
                )}
              </Button>
            </Box>
          </DirTheme>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
