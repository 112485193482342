import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { WorkerItem } from "./WorkerItem";
import { CartItem } from "./CartItem";
import { useAppContext } from "App";
import { useCommand, useOperation } from "Components/api";
import { ErrorToast } from "../ErrorToast";
import { primaryColor, themeColors } from "..";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import DirTheme from "../DirTheme";
import { MyWorkerType } from "../Settings/components";
import DataNotFound from "../DataNotFound";
import { ArrowBackIos, Close } from "@mui/icons-material";

type CartProps = {
  showCart?: boolean;
  setShowCart: (value: boolean) => void;
};
export const Cart = ({ showCart, setShowCart }: CartProps) => {
  const { t } = useTranslation("common");
  const appQueryClient = useQueryClient();
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const { myDetails, cartManager } = useAppContext();

  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(myDetails, "current_session_ticket_create", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess || isLoading || !serverReply) return;
    setError("");
    setSuccess(t("pages.caisse.cart.ticket_created"));
    cartManager.clearCart();
    appQueryClient.invalidateQueries(["My:Details"]);
    history.push("/caisse/payment/" + (serverReply as any).ticket.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply, isLoading]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };

  const { data: myWorkers } = useOperation<MyWorkerType[]>(
    myDetails,
    {
      link: "settings_activity_workers",
    },
    "settings_activity_workers",
    {} as any,
    undefined,
    !myDetails
  );

  const imgPr = require("Kcafi/img/fr.png");
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";

  return (
    <>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "block",
          },
        }}
        dir={direction}
      >
        <img
          src={imgPr}
          alt="imgPr"
          style={{
            position: "absolute",
            top: "20%",
            right: "300px",
          }}
        />
      </Box>
      <ErrorToast error={error} success={success} handleClose={handleClose} />
      <Box
        sx={{
          width: {
            xs: showCart ? "100%" : "360px",
            sm: "360px",
            md: "360px",
            lg: "290px",
            xl: "360px",
          },
          height: "100%",
          minWidth: {
            xs: "0px",
            sm: "360px",
            md: "360px",
            lg: "290px",
            xl: "360px",
          },
          backgroundColor: themeColors.white,
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: 2,
          display: {
            xs: showCart ? "block" : "none",
            sm: showCart ? "block" : "none",
            md: showCart ? "block" : "none",
            LG: "block",
            xl: "block",
          },
        }}
      >
        <Box p={"24px 0px"} pt={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px",
            }}
          >
            {step === 1 ? (
              <Icon
                aria-controls="menu"
                aria-haspopup="true"
                onClick={() => setStep(0)}
                sx={{
                  paddingTop: "5px",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIos />
              </Icon>
            ) : (
              <span></span>
            )}
            <Typography
              variant="caption"
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "16px",
                color: themeColors.darkBlue,
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {step === 1
                ? t("pages.caisse.cart.select_a_worker")
                : t("pages.caisse.cart.new_ticket")}
            </Typography>
            <Icon
              aria-controls="menu"
              aria-haspopup="true"
              onClick={() => {
                setShowCart(false);
              }}
              sx={{
                paddingTop: "5px",
                cursor: "pointer",
              }}
            >
              <Close />
            </Icon>
          </Box>

          <Box
            sx={{
              maxHeight: "calc(100vh + 185px)",
              overflow: "auto",
              //backgroundColor: "black"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "15px",
                alignItems: "center",
                pb: 100,
              }}
            >
              {step === 0 ? (
                cartManager
                  ?.getCart()
                  .items.map((item) => (
                    <CartItem
                      productId={item.product}
                      imageSrc={item.img}
                      title={item.title}
                      price={`${item.sellPrice.toFixed(2)} ${t(
                        "pages.currency"
                      )}`}
                      quantity={item.quantity}
                    />
                  ))
              ) : !myWorkers || myWorkers?.length === 0 ? (
                <DataNotFound />
              ) : (
                myWorkers?.map((worker) => <WorkerItem worker={worker} />)
              )}
            </Box>
          </Box>
          <Box
            sx={{
              mt: 1,
              mx: 2,
              m: 0,
              position: "fixed",
              p: "10px 15px 30px 10px",
              backgroundColor: themeColors.white,
              bottom: "env(safe-area-inset-bottom)",
              width: {
                xs: showCart ? "94%" : "340px",
                sm: "340px",
                md: "340px",
                lg: "265px",
                xl: "340px",
              },
            }}
          >
            <DirTheme>
              <Grid container alignItems="center" mb={1}>
                <Grid item xs>
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: themeColors.midnightBlueLight,
                    }}
                    gutterBottom
                    component="div"
                  >
                    {t("pages.caisse.cart.items")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: themeColors.midnightBlueDark,
                    }}
                    gutterBottom
                    component="div"
                  >
                    {cartManager.getTotalProducts()}
                  </Typography>
                </Grid>
              </Grid>
            </DirTheme>
            <Divider variant="fullWidth" />
            <DirTheme>
              <Grid container alignItems="center" mt={1}>
                <Grid item xs>
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: themeColors.midnightBlueLight,
                    }}
                    gutterBottom
                    component="div"
                  >
                    {t("pages.caisse.cart.total")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: primaryColor().primary,
                    }}
                    gutterBottom
                    component="div"
                  >
                    {cartManager.getTotal().toFixed(2)} {t("pages.currency")}
                  </Typography>
                </Grid>
              </Grid>
            </DirTheme>

            {step === 0 ? (
              <Button
                sx={{
                  background: primaryColor().primary,
                  borderRadius: "100px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  ":hover": {
                    background: primaryColor().primary,
                  },
                  mt: 1,
                  boxShadow: `0px 11px 64px ${themeColors.black007}`,
                }}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setStep(1)}
                disabled={!cartManager.getTotalProducts()}
              >
                {t("pages.caisse.cart.select_a_worker")}
              </Button>
            ) : (
              <Button
                sx={{
                  background: primaryColor().primary,
                  borderRadius: "100px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  ":hover": {
                    background: primaryColor().primary,
                  },
                  mt: 1,
                  boxShadow: `0px 11px 64px ${themeColors.black007}`,
                }}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => onSubmit(cartManager.getCart() as any)}
                disabled={isLoading || !cartManager.getTotalProducts()}
              >
                {isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: themeColors.white }}
                  />
                ) : (
                  t("pages.caisse.cart.create")
                )}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
