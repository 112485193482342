import { Alert, Box, CircularProgress, Fade, Grid, Link, Paper, Typography } from "@mui/material";
import { useAppContext } from "App";
import { useOperation } from "Components/api";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { primaryColor, themeColors } from "..";

export const EmailVerificationPage = () => {
    const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
    const { t } = useTranslation("common");
    const { ws } = useAppContext();
    const { tokenValue } = useParams<any>();
  
    const { data, isLoading: isLoadingToken } = useOperation<any>(
      {
        _links: {
          user_get_token_registration: ws?._links["user_get_token_registration"]?.replace(
            ":tokenValue",
            tokenValue
          ),
        },
      },
      {
        link: "user_get_token_registration",
      },
      "user_get_token_registration",
      undefined,
      undefined,
      !ws
    );
  
    console.log(data);
  
    return (
      <Grid container sx={{ height: "100vh" }} dir={direction}>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          p={2}
          sx={{ backgroundColor: "white" }}
          className="login-image"
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: { xs: "none", sm: "block" },
              backgroundImage: `url(${require("Kcafi/img/auth.png")})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "20px",
            }}
          ></Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          component={Paper}
          elevation={0}
          square
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: 10,
          }}
          className="login-form"
        >
          <Typography
            variant="h2"
            sx={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 32,
              lineHeight: "120%",
              color: themeColors.darkBlue,
              mb: 4,
            }}
            className="login-title"
          >
            {t("pages.registration_verification.title")}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 18,
              lineHeight: "150%",
              color: themeColors.deepTurquoise,
              mb: 4,
            }}
            className="login-subtitle"
          >
            {t("pages.registration_verification.subtitle")}
          </Typography>
          {isLoadingToken ? (
            <Box sx={{ height: 40 }}>
              <Fade
                in={true}
                style={{
                  transitionDelay: "800ms",
                }}
                unmountOnExit
              >
                <CircularProgress sx={{ color: primaryColor().primary }} />
              </Fade>
            </Box>
          ) : !data || data?.expired || data?.errorMessage ? (
            <Alert sx={{ mb: 4 }} variant="outlined" severity="error">
              {t("pages.registration_verification.token_not_found_or_expired")}
            </Alert>
          ) : (
            <Alert sx={{ mb: 4 }} variant="outlined" severity="success">
              {t("pages.registration_verification.account_verified")}
            </Alert>
          )}
  
          <Typography
            variant="body2"
            sx={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 14,
              lineHeight: "150%",
              color: themeColors.darkBlue,
              mt: 2,
              textAlign: "center",
            }}
          >
            <Link href="/login" underline="always">
              {t("pages.registration_verification.login")}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    );
  };