import { Typography } from "@mui/material";
import ResponsiveDrawer from "Kcafi/ResponsiveDrawer";

type Props = {
  title: string;
  message?: string;
};

export const ErrorPage = ({ title, message }: Props) => (
  <ResponsiveDrawer title={title}>
    <Typography>{message}</Typography>
  </ResponsiveDrawer>
);
