import { useAuth } from "App/utils/auth";
import { ErrorPage } from "Kcafi/Pages/ErrorPage";
import { useTranslation } from "react-i18next";
import { Route, Redirect } from "react-router-dom";

export function PrivateRoute({ children, haveAccess = true, ...rest }: any) {
  const {t} = useTranslation("common");
  const auth = useAuth();
  if (auth.isLoading) return null;
  return (
    <Route
      {...rest}
      render={({ location }: any) =>
        auth.isAuthenticated ? (
          haveAccess ? (
            children
          ) : (
            <ErrorPage
              title="Oups.."
              message={t("pages.messages.access_denied")}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
export function PublicRoute({ children, ...rest }: any) {
  return <Route {...rest} render={({ location }: any) => children} />;
}
