import { useEffect, useState } from "react";
import ResponsiveDrawer from "../../ResponsiveDrawer";
import {
  Box,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Section } from "../DashboardPage";
import { Product } from "./Product";
import useLocalStorage from "App/components/useLocalStorage";
import { useAppContext } from "App";
import { useCommand, useOperation } from "Components/api";
import { primaryColor, themeColors } from "..";
import { StartNewSession } from "../Session/StartNewSession";
import { useQueryClient } from "react-query";
import { ErrorToast } from "../ErrorToast";
import { DataNotFound } from "../DataNotFound";
import { useTranslation } from "react-i18next";
import { UnlockDialog } from "./UnlockDialog";

export type TProduct = {
  id: number;
  title: string;
  description: string;
  img: string;
  sellPrice: any;
  unit: string;
  type: "vente" | "achat" | "achat-vente";
  _links?: any;
};

export type Category = {
  id: number;
  title: string;
  description: string;
  img: string;
  products: TProduct[];
};

export const CaissePage = () => {
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const { t } = useTranslation("common");
  const appQueryClient = useQueryClient();
  const [showCart] = useLocalStorage("showCart", false);
  const { myDetails } = useAppContext();
  const { data: categories, isLoading } = useOperation<Category[]>(
    myDetails,
    {
      link: "myProducts",
    },
    "myProducts",
    { "show-custom-tabs": true } as any,
    undefined,
    !myDetails
  );
  const [value, setValue] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<Category>();

  useEffect(() => {
    if (selectedCategory || !categories) return;

    setSelectedCategory(categories[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const {
    onSubmit,
    data: serverReply,
    isLoading: isLoadingCreateSession,
    isSuccess,
  } = useCommand(myDetails, "session_create", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setSuccess(t("pages.caisse.success_create_session"));
    appQueryClient.invalidateQueries(["mySessions"]);
    appQueryClient.invalidateQueries(["My:Details"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };

  return (
    <ResponsiveDrawer
      title={`${selectedCategory?.title || ""} ${t("pages.caisse.menu")}`}
    >
      <UnlockDialog />
      {isLoading ? (
        <Box sx={{ display: "flex", ml: 2 }}>
          <CircularProgress sx={{ color: primaryColor().primary }} />
        </Box>
      ) : myDetails?.hasActiveSession ? (
        <>
          <Section title="" mt={0}>
            <Tabs
              key={"categories"}
              value={value}
              onChange={handleChange}
              aria-label="Custom Tabs"
              variant="scrollable"
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: themeColors.lightGray,
                "& .MuiTab-root": {
                  marginRight: direction === "ltr" ? "14px" : "0px",
                  marginLeft: direction === "rtl" ? "14px" : "0px",
                  "&:last-child": {
                    marginRight: direction === "ltr" ? "14px" : "0px",
                    marginLeft: direction === "rtl" ? "14px" : "0px",
                  },
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                mb: 2,
                pb: 1,
                zIndex: 2,
              }}
              TabScrollButtonProps={{
                sx: {
                  backgroundColor: themeColors.white,
                  borderRadius: "20px",
                  height: "40px",
                  width: "40px",
                  marginTop: { xs: 2.5, md: 5 },
                  marginLeft: 1,
                  marginRight: 1,
                  "& svg": {
                    fontSize: 18,
                  },
                },
              }}
            >
              {categories?.map((category) => (
                <Tab
                  key={category.id}
                  label={
                    <>
                      <Box
                        sx={{
                          display: { xs: "none", md: "flex" },
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            category.img ||
                            `https://fakeimg.pl/600x400?text=${category.title}`
                          }
                          alt={category.title}
                          style={{ width: 54, height: 54 }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: 14,
                            lineHeight: "28px",
                            leadingTrim: "both",
                            textEdge: "cap",
                            color: themeColors.darkBlue,
                          }}
                        >
                          {category.title}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: { xs: "flex", md: "none" },
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            category.img ||
                            `https://fakeimg.pl/600x400?text=${category.title}`
                          }
                          alt={category.title}
                          style={{ width: 27, height: 27 }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: 12,
                            lineHeight: "28px",
                            leadingTrim: "both",
                            textEdge: "cap",
                            color: themeColors.darkBlue,
                            textOverflow: "ellipsis",
                            width: 64,
                            overflow: "hidden",
                          }}
                        >
                          {category.title}
                        </Typography>
                      </Box>
                    </>
                  }
                  sx={{
                    width: { md: 103, xs: 74 },
                    minWidth: { md: 103, xs: 74 },
                    height: { md: 126, xs: 84 },
                    background: themeColors.white,
                    border: "1px solid " + themeColors.gray,
                    borderRadius: "15px",
                    "&.Mui-selected": {
                      background: primaryColor().primaryLinearGrad,
                      border: "1px solid " + primaryColor().primary,
                      borderRadius: "15px",
                      color: primaryColor().primary,
                    },
                  }}
                  onClick={() => {
                    setSelectedCategory(category);
                  }}
                />
              ))}
            </Tabs>
            <Grid container spacing={1}>
              {selectedCategory?.products?.length ? (
                selectedCategory?.products?.map((product) => (
                  <Grid item xs={4} sm={4} md={3} lg={showCart ? 3 : 2} xl={2}>
                    <Product key={product.id} product={product} />
                  </Grid>
                ))
              ) : (
                <DataNotFound />
              )}
            </Grid>
          </Section>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <ErrorToast
            error={error}
            success={success}
            handleClose={handleClose}
          />
          <Typography mb={5} variant="body1"></Typography>
          <StartNewSession
            onSubmit={onSubmit}
            isLoading={isLoadingCreateSession}
          />
        </Box>
      )}
    </ResponsiveDrawer>
  );
};
