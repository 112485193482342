import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useAppContext } from "App";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { primaryColor, themeColors } from "..";
import { ErrorToast } from "../ErrorToast";
import DirTheme from "../DirTheme";
import { useCommand } from "Components/api";
import { Lock } from "@mui/icons-material";

export const LockDialog = ({
    handleMenuClose
}: {
    handleMenuClose: () => void
}) => {
  const { t } = useTranslation("common");
  const appQueryClient = useQueryClient();
  const { myDetails } = useAppContext();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [open, setOpen] = useState(false);
  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(myDetails, "update_profile_security_lock", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    //setSuccess(t("pages.settings.security.locked"));
    appQueryClient.invalidateQueries(["My:Details"]);
    handleClosedialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };

  const handleClosedialog = () => {
    setOpen(false);
    handleMenuClose();
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        onClick={handleOpenDialog}
        color="inherit"
      >
        <ListItemIcon>
          <Lock />
        </ListItemIcon>
        <ListItemText>{t("pages.settings.security.lock")}</ListItemText>
      </Box>

      <Dialog maxWidth={"sm"} open={open} onClose={handleClosedialog}>
        <DialogTitle>{t("pages.settings.security.confirm_lock")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ErrorToast
              error={error}
              success={success}
              handleClose={handleClose}
            />
            <DirTheme>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "142.19%",
                  }}
                >
                  {t("pages.settings.security.confirm_lock_description")}
                </Typography>

                <Button
                  sx={{
                    borderRadius: "4px",
                    backgroundColor: primaryColor().primary,
                    ":hover": {
                      background: primaryColor().primary,
                    },
                    color: themeColors.white,
                    fontStyle: "normal",
                    fontWeight: 600,
                    height: "54px",
                    width: "140px",
                    mt: 2,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => onSubmit({})}
                >
                  {isLoading ? (
                    <CircularProgress
                      size={20}
                      sx={{ color: themeColors.white }}
                    />
                  ) : (
                    t("pages.settings.security.lock")
                  )}
                </Button>
              </Box>
            </DirTheme>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
