import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Link,
  InputAdornment,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Email } from "@mui/icons-material";
import { useCommand } from "Components/api";
import { useAppContext } from "App";
import { useEffect, useState } from "react";
import { primaryColor, themeColors } from "..";
import { useTranslation } from "react-i18next";
import DirTheme from "../DirTheme";

export const ResetPasswordPage = () => {
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  const { t } = useTranslation("common");
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [email, setEmail] = useState<string>();
  const { ws } = useAppContext();
  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(ws, "user_forgot_password", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setEmail("");
    setSuccess(
      "We have successfully sent you a link. Please use it to reset your password."
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  return (
    <Grid container sx={{ height: "100vh" }} dir={direction}>
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        p={2}
        sx={{ backgroundColor: "white" }}
        className="login-image"
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: { xs: "none", sm: "block" },
            backgroundImage: `url(${require("Kcafi/img/auth.png")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "20px",
          }}
        ></Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        component={Paper}
        elevation={0}
        square
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 10,
        }}
        className="login-form"
      >
        <Typography
          variant="h2"
          sx={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 32,
            lineHeight: "120%",
            color: themeColors.darkBlue,
            mb: 4,
            width: 280,
          }}
          className="login-title"
        >
          {t("pages.reset_password.title")}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 18,
            lineHeight: "150%",
            color: themeColors.deepTurquoise,
            mb: 4,
          }}
          className="login-subtitle"
        >
          {t("pages.reset_password.subtitle")}
        </Typography>

        {error && (
          <Alert sx={{ mb: 4 }} variant="outlined" severity="error">
            {error}
          </Alert>
        )}

        {success && (
          <Alert sx={{ mb: 4 }} variant="outlined" severity="success">
            {success}
          </Alert>
        )}

        <form>
          <DirTheme>
            <TextField
              sx={{ mb: 4 }}
              label={t("pages.reset_password.email")}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
            />
          </DirTheme>
          <Button
            sx={{
              py: 1,
              px: 2,
              background: primaryColor().primary,
              borderRadius: "100px",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              ":hover": {
                background: primaryColor().primary,
              },
              mb: 6,
              boxShadow: `0px 11px 64px ${themeColors.black007}`,
            }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              setSuccess(undefined);
              setError(undefined);
              onSubmit({
                email: email,
              } as any);
            }}
            disabled={isLoading || !email}
          >
            {isLoading ? (
              <CircularProgress size={20} sx={{ color: themeColors.white }} />
            ) : (
              t("pages.reset_password.reset_password")
            )}
          </Button>
        </form>
        <Typography
          variant="body2"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "150%",
            color: themeColors.darkBlue,
            mt: 2,
            textAlign: "center",
          }}
        >
          {t("pages.reset_password.already_have_account")}{" "}
          <Link href="/login" underline="always">
            {t("pages.reset_password.login")}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordPage;
