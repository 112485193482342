import { Section } from "../../DashboardPage";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { primaryColor, themeColors } from "../..";
import { useEffect, useState } from "react";
import { useAppContext } from "App";
import { useCommand } from "Components/api";
import { ErrorToast } from "Kcafi/Pages/ErrorToast";
import ImageUploader from "../ImageUploader";
import { useTranslation } from "react-i18next";
import DirTheme from "Kcafi/Pages/DirTheme";
import { VolumeOff, VolumeUp } from "@mui/icons-material";
import { useQueryClient } from "react-query";

export const ActivitySettings = () => {
  const { t } = useTranslation("common");
  const appQueryClient = useQueryClient();
  const { myDetails } = useAppContext();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();

  const [showAllCategory, setShowAllCategory] = useState<boolean | undefined>(
    myDetails.showAllCategory
  );
  const [showTopCategory, setShowTopCategory] = useState<boolean | undefined>(
    myDetails.showTopCategory
  );
  const [showFavoriteCategory, setShowFavoriteCategory] = useState<
    boolean | undefined
  >(myDetails.showFavoriteCategory);
  const [mutedCaisse, setMutedCaise] = useState<boolean | undefined>(
    myDetails.mutedCaisse
  );

  const [value, setValue] = useState(myDetails.activityName);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(myDetails, "update_activity_name", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setSuccess(t("pages.settings.activity.activity_updated"));
    appQueryClient.invalidateQueries(["My:Details"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };

  const handleMuteToggle = () => {
    setMutedCaise(!mutedCaisse);
    onSubmit({
      name: value,
      showAllCategory: !!showAllCategory,
      showFavoriteCategory: !!showFavoriteCategory,
      showTopCategory: !!showTopCategory,
      mutedCaisse: !mutedCaisse,
    })
  };

  return (
    <Card
      sx={{
        boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
        padding: "15px",
        borderRadius: "10px",
        marginBottom: "25px",
      }}
    >
      <Section
        title={t("pages.settings.activity.title")}
        description={t("pages.settings.activity.description")}
      >
        <ErrorToast error={error} success={success} handleClose={handleClose} />
        <Box maxWidth={180}>
          <ImageUploader
            currentImageUrl={myDetails.activityLogo}
            uploadUrl={myDetails._links.update_activity_logo}
            variant="square"
            avatarSx={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Box
          sx={
            {
              //display: "flex",
            }
          }
        >
          <DirTheme>
            <TextField
              type="text"
              label={t("pages.settings.activity.name")}
              variant="outlined"
              value={value}
              onChange={handleChange}
              fullWidth
              sx={{
                maxWidth: "200px",
                mb: 2,
              }}
            />
          </DirTheme>
          <DirTheme>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "142.19%",
                }}
              >
                {t("pages.settings.activity.show_tabs_description")}
              </Typography>

              <FormControlLabel
                control={
                  <Switch
                    checked={showAllCategory}
                    onChange={() => {
                      setShowAllCategory(!showAllCategory);
                    }}
                    name="showAllCategory"
                  />
                }
                label={t("pages.settings.activity.show_all_category")}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showFavoriteCategory}
                    onChange={() => {
                      setShowFavoriteCategory(!showFavoriteCategory);
                    }}
                    name="showFavoriteCategory"
                  />
                }
                label={t("pages.settings.activity.show_favorite_category")}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showTopCategory}
                    onChange={() => {
                      setShowTopCategory(!showTopCategory);
                    }}
                    name="showTopCategory"
                  />
                }
                label={t("pages.settings.activity.show_top_category")}
              />
            </Box>
            <Box
              sx={{
                mb: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "142.19%",
                }}
              >
                {t("pages.settings.activity.show_tabs_description")}
              </Typography>
              <IconButton onClick={handleMuteToggle} size="large">
                {mutedCaisse ? <VolumeOff /> : <VolumeUp />}
              </IconButton>
            </Box>
          </DirTheme>
          <Button
            sx={{
              borderRadius: "4px",
              backgroundColor: primaryColor().primary,
              ":hover": {
                background: primaryColor().primary,
              },
              color: themeColors.white,
              fontStyle: "normal",
              fontWeight: 600,
              height: "54px",
              width: "120px",
            }}
            variant="contained"
            color="primary"
            onClick={() =>
              onSubmit({
                name: value,
                showAllCategory: !!showAllCategory,
                showFavoriteCategory: !!showFavoriteCategory,
                showTopCategory: !!showTopCategory,
                mutedCaisse: !!mutedCaisse,
              })
            }
            disabled={!value}
          >
            {isLoading ? (
              <CircularProgress size={20} sx={{ color: themeColors.white }} />
            ) : (
              t("pages.settings.activity.update")
            )}
          </Button>
        </Box>
      </Section>
    </Card>
  );
};
