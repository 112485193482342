import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import ResponsiveDrawer from "Kcafi/ResponsiveDrawer";
import { Section } from "../DashboardPage";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { AttachMoney, ConfirmationNumber } from "@mui/icons-material";
import { useAppContext } from "App";
import { useOperation } from "Components/api";
import { primaryColor, themeColors } from "..";
import { useTranslation } from "react-i18next";

export type ChartData = {
  chartTickets: {
    series: number[];
    series2: number[];
    xAxis: string[];
  };
  chartTopSales: {
    label: string;
    value: number;
    color: string;
  }[];
  totalEarningOfToday: number;
  totalEarnings: number;
  totalTickets: number;
  totalTicketsOfToday: number;
};

export const StatsPage = () => {
  const { t } = useTranslation("common");
  const { myDetails } = useAppContext();
  const {
    data: myStats,
    isLoading: isLoadingMyStats,
    error,
  } = useOperation<ChartData>(
    myDetails,
    {
      link: "myStats",
    },
    "myStats",
    {} as any,
    undefined,
    !myDetails
  );

  return (
    <ResponsiveDrawer title={t("pages.stats.title")}>
      {isLoadingMyStats && (
        <Box sx={{ display: "flex", ml: 2 }}>
          <CircularProgress sx={{ color: primaryColor().primary }} />
        </Box>
      )}

      {error && <Box>{t("pages.stats.load_stats_failed")}</Box>}

      {myStats && (
        <>
          <Grid container spacing={2} sx={{ marginBottom: "25px" }}>
            <Grid item xs={12} sm={6} lg={3}>
              <OverviewEarnings
                sx={{
                  height: "100%",
                  boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
                }}
                value={`${t("pages.currency")}`}
                value2={`${myStats.totalEarnings.toFixed(2)}`}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <OverviewTotalToday
                sx={{
                  height: "100%",
                  boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
                }}
                value={`${t("pages.currency")}`}
                value2={`${myStats.totalEarningOfToday.toFixed(2)}`}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <OverviewTotalTickets
                sx={{
                  height: "100%",
                  boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
                }}
                value={`${myStats.totalTickets}`}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <OverviewTodaysTickets
                sx={{
                  height: "100%",
                  boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
                }}
                value={`${myStats.totalTicketsOfToday}`}
              />
            </Grid>
          </Grid>
          <Card
            sx={{
              boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
              padding: "15px",
              borderRadius: "10px",
              marginBottom: "25px",
            }}
          >
            <Section
              title={t("pages.stats.section_1_title")}
              description={t("pages.stats.section_1_description")}
            >
              <Grid container dir={"ltr"}>
                <Grid item xs={12} sm={12} md={12} xl={8}>
                  <BarChart
                    xAxis={[
                      {
                        id: "barCategories",
                        data: myStats.chartTickets.xAxis,
                        scaleType: "band",
                      },
                    ]}
                    series={[
                      {
                        data: myStats.chartTickets.series,
                      },
                    ]}
                    height={400}
                  />
                </Grid>
              </Grid>
            </Section>
          </Card>
          <Card
            sx={{
              boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
              padding: "15px",
              borderRadius: "10px",
              marginBottom: "25px",
            }}
          >
            <Section
              title={t("pages.stats.section_2_title")}
              description={t("pages.stats.section_2_description")}
            >
              <Grid container dir={"ltr"}>
                <Grid item xs={12} sm={12} md={12} xl={8}>
                  <BarChart
                    xAxis={[
                      {
                        id: "barCategories2",
                        data: myStats.chartTickets.xAxis,
                        scaleType: "band",
                      },
                    ]}
                    series={[
                      {
                        data: myStats.chartTickets.series2,
                      },
                    ]}
                    height={400}
                  />
                </Grid>
              </Grid>
            </Section>
          </Card>
          <Card
            sx={{
              boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
              padding: "15px",
              borderRadius: "10px",
              marginBottom: "25px",
            }}
          >
            <Section
              title={t("pages.stats.section_3_title")}
              description={t("pages.stats.section_3_description")}
            >
              <Grid container dir={"ltr"}>
                <Grid item xs={12} sm={12} md={6} xl={4}>
                  <PieChart
                    series={[
                      {
                        innerRadius: 30,
                        outerRadius: 100,
                        paddingAngle: 5,
                        cornerRadius: 5,
                        startAngle: -90,
                        endAngle: 180,
                        cx: 150,
                        cy: 150,
                        data: myStats.chartTopSales,
                      },
                    ]}
                    slotProps={{
                      legend: {
                        position: {
                          horizontal: "right",
                          vertical: "middle",
                        },
                      },
                    }}
                    height={300}
                  />
                </Grid>
              </Grid>
            </Section>
          </Card>
        </>
      )}
    </ResponsiveDrawer>
  );
};

export const OverviewTotalToday = (props: any) => {
  const { value, sx, value2 } = props;
  const { t } = useTranslation("common");
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              {t("pages.stats.overview_total_today")}
            </Typography>
            <Stack
              display={"flex"}
              flexDirection={direction === "ltr" ? "row" : "row-reverse"}
            >
              <Typography variant="h4">{value}</Typography>
              <Typography variant="h4">{value2}</Typography>
            </Stack>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: primaryColor().primary,
              height: 56,
              width: 56,
              ml: "0 !important",
            }}
          >
            <SvgIcon>
              <AttachMoney />
            </SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

export const OverviewTodaysTickets = (props: any) => {
  const { value, sx } = props;
  const { t } = useTranslation("common");

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" gutterBottom variant="overline">
              {t("pages.stats.overview_todays_tickets")}
            </Typography>
            <Typography variant="h4">{value}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: primaryColor().primary,
              height: 56,
              width: 56,
              ml: "0 !important",
            }}
          >
            <SvgIcon>
              <ConfirmationNumber />
            </SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

export const OverviewTotalTickets = (props: any) => {
  const { sx, value } = props;
  const { t } = useTranslation("common");

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              {t("pages.stats.overview_total_tickets")}
            </Typography>
            <Typography variant="h4">{value}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: primaryColor().primary,
              height: 56,
              width: 56,
              ml: "0 !important",
            }}
          >
            <SvgIcon>
              <ConfirmationNumber />
            </SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

export const OverviewEarnings = (props: any) => {
  const { sx, value, value2 } = props;
  const { t } = useTranslation("common");
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";
  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
          width={"100%"}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              {t("pages.stats.overview_earnings")}
            </Typography>
            <Stack
              display={"flex"}
              flexDirection={direction === "ltr" ? "row" : "row-reverse"}
            >
              <Typography variant="h4">{value}</Typography>
              <Typography variant="h4">{value2}</Typography>
            </Stack>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: primaryColor().primary,
              height: 56,
              width: 56,
              ml: "0 !important",
            }}
          >
            <SvgIcon>
              <AttachMoney />
            </SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};
