import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useAppContext } from "App";
import { useEffect } from "react";
import gsap from "gsap";
import { TProduct } from "./CaissePage";
import { CartManager } from "./CartManager";
import { primaryColor, themeColors } from "..";
import { useTranslation } from "react-i18next";

type ProductProps = {
  product: TProduct;
};

export const Product = ({ product }: ProductProps) => {
  const {t} = useTranslation("common");
  const {
    myDetails,
    animationPosition,
    setAnimationPosition,
    cartManager,
    setCartManager,
  } = useAppContext();

  useEffect(() => {
    if (!animationPosition) return;
    const timer = setTimeout(() => {
      setAnimationPosition(false);
    }, 700);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationPosition]);

  const animateProduct = () => {
    const parentProductBox = document.querySelector(
      ".product-box-parent" + product.id
    );
    const productBox = document.querySelector(".product-box" + product.id);
    const cartButton = document.querySelector(".cart-button");
    const productBoxPosition = productBox?.getBoundingClientRect() || {
      left: 0,
      top: 0,
    };
    const cartButtonPosition = cartButton?.getBoundingClientRect() || {
      left: 0,
      top: 0,
    };

    const cloneProductBox = productBox?.cloneNode(true) as HTMLElement;
    parentProductBox?.appendChild(cloneProductBox);
    gsap.fromTo(
      cloneProductBox,
      { zIndex: 3, opacity: 1 },
      {
        x: cartButtonPosition.left - productBoxPosition.left,
        y: cartButtonPosition.top - productBoxPosition.top,
        scaleX: 0.5,
        scaleY: 0.5,
        opacity: 0,
        zIndex: 3,
        duration: 0.7,
        onComplete: () => {},
      }
    );
  };

  const audio = new Audio("./beep.wav");
  const handleAddItem = () => {
    cartManager.addItem(
      product.id,
      product.title,
      product.img || `https://fakeimg.pl/600x400?text=${product.title}`,
      1,
      product.sellPrice,
      product.description
    );
    setCartManager(new CartManager());
    if (!myDetails.mutedCaisse) audio.play();
  };
  return (
    <div style={{ position: "relative" }}>
      <Box
        onClick={() => {
          animateProduct();
          setAnimationPosition(true);
          handleAddItem();
        }}
        sx={{
          height: {
            xs: "114px",
            sm: "132px",
            md: "160px",
          },
          background: themeColors.white,
          borderRadius: "8px",
          position: "relative",
          cursor: "pointer",
          zIndex: 1,
          userSelect: "none",
          WebkitTapHighlightColor: themeColors.transparent,
          border: `1px solid ${themeColors.gray}`,
        }}
      >
        <Box
          sx={{
            height: {
              xs: "58%",
              sm: "60%",
              md: "65%",
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "3px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            paddingTop: 0.5,
            paddingBottom: 0.5,
            userSelect: "none",
            WebkitTapHighlightColor: themeColors.transparent,
            borderBottom: `1px solid ${themeColors.gray}`,
            //background: themeColors.grayLinearGrad
          }}
        >
          <Box
            sx={{
              width: {
                xs: "72px",
                sm: "72px",
                md: "92px",
              },
            }}
          >
            <img
              src={product.img || `https://fakeimg.pl/600x400?text=${product.title}`}
              alt="Product"
              style={{
                display: "block",
                userSelect: "none",
                WebkitTapHighlightColor: themeColors.transparent,
                background: themeColors.white,
                width: "100%",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: 1,
            paddingRight: 1,
            userSelect: "none",
            WebkitTapHighlightColor: themeColors.transparent,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: { xs: "10px", sm: "12px", md: "12px" },
              leadingTrim: "both",
              textEdge: "cap",
              color: themeColors.darkBlue,
              userSelect: "none",
              WebkitTapHighlightColor: themeColors.transparent,
              textOverflow: "ellipsis",
              maxWidth: { xs: 112, sm: 120, md: 160 },
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {product.title}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: { xs: "10px", sm: "12px", md: "12px" },
              leadingTrim: "both",
              textEdge: "cap",
              color: primaryColor().primary,
              userSelect: "none",
              WebkitTapHighlightColor: themeColors.transparent,
              whiteSpace: "nowrap",
            }}
          >
            {t('pages.currency')} {product.sellPrice ? product.sellPrice.toFixed(2) : "--"}
          </Typography>
        </Box>
      </Box>
      <div
        className={"product-box-parent" + product.id}
        style={{ position: "absolute", top: "0", width: "100%" }}
      >
        <Box
          sx={{
            height: "160px",
            width: "80%",
            borderRadius: "8.24791px",
            position: "absolute",
            cursor: "pointer",
            userSelect: "none",
            opacity: 0,
            zIndex: 0,
            WebkitTapHighlightColor: themeColors.transparent,
          }}
          className={"product-box" + product.id}
        >
          <Box
            sx={{
              height: "65%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "5px",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              userSelect: "none",
              WebkitTapHighlightColor: themeColors.transparent,
              background: themeColors.grayLinearGrad,
            }}
          >
            <img
              src={product.img || `https://fakeimg.pl/600x400?text=${product.title}`}
              alt="Product"
              style={{
                display: "block",
                userSelect: "none",
                WebkitTapHighlightColor: themeColors.transparent,
                background: themeColors.white,
                width: 94,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: 1,
              paddingRight: 1,
              userSelect: "none",
              WebkitTapHighlightColor: themeColors.transparent,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: { xs: "10px", sm: "12px", md: "12px" },
                leadingTrim: "both",
                textEdge: "cap",
                color: themeColors.darkBlue,
                userSelect: "none",
                WebkitTapHighlightColor: themeColors.transparent,
                textOverflow: "ellipsis",
                width: 112,
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {product.title}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: { xs: "10px", sm: "12px", md: "12px" },
                leadingTrim: "both",
                textEdge: "cap",
                color: primaryColor().primary,
                userSelect: "none",
                WebkitTapHighlightColor: themeColors.transparent,
              }}
            >
              {t('pages.currency')} {product.sellPrice ? product.sellPrice.toFixed(2) : "--"}
            </Typography>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Product;
