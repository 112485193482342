import React from "react";
import { useTranslation } from "react-i18next";
import { FlagIcon } from "react-flag-kit";
import { Box, Menu, MenuItem, Typography } from "@mui/material";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("lang", language);
    document.documentElement.lang = language;
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box
        display={"flex"}
        alignItems={"center"}
        onClick={handleMenuClick}
        color="inherit"
      >
        <FlagIcon
          width={26}
          height={18}
          code={
            i18n.language === "fr" ? "FR" : i18n.language === "en" ? "US" : "TN"
          }
        />
        <Typography ml={1} mr={1}>{
            i18n.language === "fr" ? "Français" : i18n.language === "en" ? "English" : "العربية"
        }</Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick("en")}>
          <FlagIcon width={26} height={18} code="US" />
          <Typography ml={1} mr={1}>English</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("fr")}>
          <FlagIcon width={26} height={18} code="FR" />
          <Typography ml={1} mr={1}>Français</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("ar")}>
          <FlagIcon width={26} height={18} code="TN" />
          <Typography ml={1} mr={1}>العربية</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSelector;
