import ResponsiveDrawer from "Kcafi/ResponsiveDrawer";
import { Section } from "../DashboardPage";
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Email, Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAppContext } from "App";
import { useCommand } from "Components/api";
import { ErrorToast } from "../ErrorToast";
import { primaryColor, themeColors } from "..";
import ImageUploader from "./ImageUploader";
import { useTranslation } from "react-i18next";
import DirTheme from "../DirTheme";

export const ProfilePage = () => {
  const { t } = useTranslation("common");
  const { myDetails } = useAppContext();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [email, setEmail] = useState<string>(myDetails.email);
  const [password, setPassword] = useState<string>();
  const [nom, setNom] = useState<string>(myDetails.nom);
  const [prenom, setPrenom] = useState<string>(myDetails.prenom);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const {
    onSubmit,
    data: serverReply,
    isLoading,
    isSuccess,
  } = useCommand(myDetails, "update_profile", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setPassword("");
    setSuccess(t("pages.profile.account_updated"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleClose = () => {
    setError("");
    setSuccess("");
  };
  return (
    <ResponsiveDrawer title={t("pages.profile.title")}>
      <Card
        sx={{
          boxShadow: `0px 10px 60px 0px ${themeColors.white05}`,
          padding: "15px",
          borderRadius: "10px",
          marginBottom: "25px",
        }}
      >
        <Section
          title={t("pages.profile.title")}
          description={t("pages.profile.description")}
        >
          <ErrorToast
            error={error}
            success={success}
            handleClose={handleClose}
          />
          <Grid container xs={12} sm={12} md={8} xl={8}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ImageUploader
                    currentImageUrl={myDetails.avatarUrl}
                    uploadUrl={myDetails._links.update_profile_picture}
                  />
                </Grid>

                <Grid item xs={6}>
                  <DirTheme>
                    <TextField
                      sx={{ mb: 4 }}
                      label={t("pages.profile.name")}
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setPrenom(event.target.value);
                      }}
                      value={prenom}
                    />
                  </DirTheme>
                </Grid>
                <Grid item xs={6}>
                  <DirTheme>
                    <TextField
                      sx={{ mb: 4 }}
                      label={t("pages.profile.last_name")}
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setNom(event.target.value);
                      }}
                      value={nom}
                    />
                  </DirTheme>
                </Grid>
              </Grid>
              <DirTheme>
                <TextField
                  sx={{ mb: 4 }}
                  label={t("pages.profile.email")}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  value={email}
                />
              </DirTheme>
              <DirTheme>
                <TextField
                  sx={{ mb: 4 }}
                  label={t("pages.profile.password")}
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  value={password}
                />
              </DirTheme>
              <Button
                sx={{
                  background: primaryColor().primary,
                  borderRadius: "100px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  ":hover": {
                    background: primaryColor().primary,
                  },
                  mb: 6,
                  boxShadow: `0px 11px 64px ${themeColors.black007}`,
                }}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  setError("");
                  setSuccess("");
                  onSubmit({
                    email: email,
                    username: email,
                    nom: nom,
                    prenom: prenom,
                    password: password,
                    _method: "PUT",
                  } as any);
                }}
                disabled={isLoading || !email || !nom || !prenom}
              >
                {isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: themeColors.white }}
                  />
                ) : (
                  t("pages.profile.save")
                )}
              </Button>
            </form>
          </Grid>
        </Section>
      </Card>
    </ResponsiveDrawer>
  );
};
