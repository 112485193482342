import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  CircularProgress,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { useAppContext } from "App";
import { useEffect, useState } from "react";
import { useCommand } from "Components/api";
import { ErrorToast } from "../ErrorToast";
import { useTranslation } from "react-i18next";
import { ProductSTD } from ".";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  product: ProductSTD;
};

export const DeleteProductDialog = ({ product }: Props) => {
  const { t } = useTranslation("common");
  const appQueryClient = useQueryClient();
  const { cartManager } = useAppContext();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const {
    onSubmit,
    data: serverReply,
    isLoading: isLoadingCloseSession,
    isSuccess,
  } = useCommand(product, "product_delete", (e: any) => {
    setError(t(e?.detail));
  });
  useEffect(() => {
    if (!isSuccess) return;
    setError("");
    setSuccess(t("pages.products.product.delete_dialog.success_deleted"));
    appQueryClient.invalidateQueries(["products_std_by_category"]);
    appQueryClient.invalidateQueries(["myProducts"]);
    cartManager.clearCart();
    handleCloseDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverReply]);

  const handleCloseAlert = () => {
    setError("");
    setSuccess("");
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  return (
    <div>
      <ErrorToast
        error={error}
        success={success}
        handleClose={handleCloseAlert}
      />
      {product && (
        <MenuItem
          onClick={() => {
            handleClickOpenDialog();
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          {t("pages.products.product.delete")}
        </MenuItem>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {t("pages.products.product.delete_dialog.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("pages.products.product.delete_dialog.description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            {t("pages.products.product.delete_dialog.cancel")}
          </Button>
          <Button onClick={() => onSubmit({
            deleted: "changed"
          })}>
            {isLoadingCloseSession ? (
              <CircularProgress sx={{ width: 24, height: 24, mr: 1 }} />
            ) : (
              t("pages.products.product.delete_dialog.confirm")
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
