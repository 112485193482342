import { Box, Typography } from "@mui/material";
import { useAppContext } from "App";
import { CartManager } from "./CartManager";
import { primaryColor, themeColors } from "..";
import { MyWorkerType } from "../Settings/components";

export const WorkerItem = ({ worker }: { worker: MyWorkerType }) => {
  const { cartManager, setCartManager } = useAppContext();
  return (
    <Box
      sx={{
        padding: "15px 5px",
        display: "flex",
        alignItems: "center",
        position: "relative",
        borderRadius: "10px",
        cursor: "pointer",
        width: "97%",
        border:
          cartManager.getWorker() === worker.id
            ? "2px solid " + primaryColor().primary
            : undefined,
      }}
      mb={1}
      onClick={() => {
        cartManager.setWorker(worker.id);
        setCartManager(new CartManager());
      }}
    >
      <Box
        sx={{
          width: "60px",
          height: "60px",
          marginRight: "15px",
          borderRadius: "10px",
          border: "1px solid " + themeColors.midnightBlueLight,
          backgroundColor: themeColors.gray,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {worker.img ? (
          <img width="60" height="60" style={{borderRadius: "10px"}} src={worker.img} alt="worker" />
        ) : (
          <img
            style={{borderRadius: "10px"}}
            src={`https://fakeimg.pl/60x60?text=${worker.prenom}`}
            alt="worker"
          />
        )}
      </Box>

      <Box width="65%">
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "28px",
          }}
        >
          {worker.prenom}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "28px",
            color: themeColors.midnightBlueLight,
          }}
        >
          {worker.email}
        </Typography>
      </Box>
    </Box>
  );
};
