export type RSErrorType =
  | undefined
  | {
      Error?: any;
      errorMessage?: string | undefined;
      errorMessageDetails?: string | undefined;
      debug?: any;
      errorFields?: string[];
      statusCode?: string;
      ListOfErrors?: any;
    };

export const isError = (error: RSErrorType | Error | false | null) => {
  const { errorMessage, errorMessageDetails, ListOfErrors = [], Error = false, GenericErrorList = [] } = error as any|| {};

  if (errorMessage && errorMessage !== "") return true;
  if (errorMessageDetails && errorMessageDetails !== "") return true;
  if(Error) return true;
  if(ListOfErrors.length > 0 || GenericErrorList.length > 0) return true;
  if(error && (error as Error)?.message) return true;
  return false;
};

export type RSPaginationReply = {
  page?: number;
  total?: number;
  pageSize?: number;
  hasNextPage?: boolean;
};

export type UrlParams = {
  page?: string;
  pageSize?: string;
  orderBy?: string ;
};

export class UrlParamOrderBy {
  field: string = "";
  ascending: string | boolean = false;
  constructor(string: string) {
    // this.field = field;
    this.fromString(string);
  }
  fromString(s: string = "") {
    const parts = s.split(" ");
    this.field = parts[0];
    this.ascending = parts[1] && parts[1].startsWith("asc");
  }
  toString() {
    if (!this.field) return undefined;
    return `${this.field}${this.ascending ? " asc" : " desc"}`;
  }
}

export type UrlParamsRel = UrlParams & {
  link?: any;
};

export interface RSPagination {
  page?: number;
  total?: number;
  pageSize?: number;
  pageCount?: number;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
}

export type RSReply = RSErrorType & {
  // statusCode: number;
  _metaData?: any;
  _links?: any[];
  pagination?: RSPagination | undefined;
};

export type RSListReply = RSReply;

export type RSDetailsReply = RSErrorType & {
  _links?: any[];
};

export interface WebServiceComponent extends RSReply {
  readonly component_id: string;
  readonly description: string;
  readonly name: string;
  readonly type: string;
  readonly version: string;
  readonly runtime_version: string;
  readonly migrated_at: string;
  readonly APP_ENV: string;
  readonly RS_ADMIN_URL: string;
  readonly APP_API_URL: string;
  readonly RS_APP_LABEL: string;
  readonly APP_URL: string;
  readonly RS_FILESYSTEM_BUCKET?: string;
  readonly RS_YOUSIGN_APP_URL?: string;
  readonly RS_TEST_FILESYSTEM_URL?: string;
  readonly RS_WEBSOCKET_URL?: string;
  readonly RS_WEBSERVER_URL?: string;
  readonly _links?: any;
}

export interface ScheduleSearch {
  readonly name?: string;
}

export interface Schedule {
  readonly date_created: string;
  readonly date_updated: string;
  readonly name: string;
  readonly schedule_id: string;
  readonly last_execution: string;
  readonly next_execution: string;
  readonly is_active: string;
  readonly lines_total: number;
  readonly lines_error: number;
}

export interface AggregateEventSearch {
  readonly aggregate_id?: string;
  readonly event_name?: string;
  readonly id?: string;
}

export interface AggregateEvent {
  readonly aggregate_id: string;
  readonly aggregate_version: string;
  readonly date_created: string;
  readonly date_updated: string;
  readonly event_name: string;
  readonly id: string;
  readonly values: any;
}

// export const shimmeredDetailsListProps: IListProps = {
//   renderedWindowsAhead: 0,
//   renderedWindowsBehind: 0,
// };