import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useAppContext } from "App";
import { DashboardPage } from "Kcafi/Pages/DashboardPage";
import NewPasswordPage from "Kcafi/Pages/Auth/NewPasswordPage";
import RegisterPage from "Kcafi/Pages/Auth/RegisterPage";
import ResetPasswordPage from "Kcafi/Pages/Auth/ResetPasswordPage";
import { CaissePage } from "Kcafi/Pages/Caisse/CaissePage";
import { CaissePaymentPage } from "Kcafi/Pages/Caisse/CaissePaymentPage";
import { ProductsPage } from "Kcafi/Pages/Products/ProductsPage";
import { SessionsPage } from "Kcafi/Pages/Session/SessionsPage";
import { SettingsPage } from "Kcafi/Pages/Settings/SettingsPage";
import { StatsPage } from "Kcafi/Pages/Stats/StatsPage";
import { PrivateRoute, PublicRoute } from "./PrivateRoute";
import LoginPage from "Kcafi/Pages/Auth/LoginPage";
import { ProfilePage } from "Kcafi/Pages/Settings/ProfilePage";
import { ErrorPage } from "Kcafi/Pages/ErrorPage";
import { Box, Button, CircularProgress, Fade } from "@mui/material";
import { SessionTicketsPage } from "Kcafi/Pages/Session/SessionTicketsPage";
import { SessionTicketDetailsPage } from "Kcafi/Pages/Session/SessionTicketDetailsPage";
import { SessionGenerateReportPage } from "Kcafi/Pages/Session/SessionGenerateReportPage";
import { primaryColor, themeColors } from "Kcafi/Pages";
import { EquivalencePage } from "Kcafi/Pages/Products/EquivalencePage";
import { StockPage } from "Kcafi/Pages/Products/StockPage";
import { EmailVerificationPage } from "Kcafi/Pages/Auth/EmailVerificationPage";
import { InvoicesPage } from "Kcafi/Pages/Invoices/InvoicesPage";

export const AppRoutes: React.FC = () => {
  const { isLoading, loadingText, isAuthenticated, myDetails } = useAppContext();
  return isAuthenticated && !isLoading ? (
    <div style={{ backgroundColor: themeColors.lightGray }}>
      <Router>
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Box sx={{ height: 40 }}>
                <Fade
                  in={true}
                  style={{
                    transitionDelay: "800ms",
                  }}
                  unmountOnExit
                >
                  <CircularProgress sx={{ color: primaryColor().primary }} />
                </Fade>
              </Box>
              <Button sx={{ m: 2, color: primaryColor().primary }}>
                Loading page
              </Button>
            </Box>
          }
        >
          <Switch>
            <PrivateRoute exact path="/login">
              <LoginPage />
            </PrivateRoute>
            <PrivateRoute exact path="/">
              <DashboardPage />
            </PrivateRoute>
            <PrivateRoute exact path="/caisse" haveAccess={myDetails.permissions?.Caisse?.ReadCaisse}>
              <CaissePage />
            </PrivateRoute>
            <PrivateRoute exact path="/caisse/payment/:ticketId" haveAccess={myDetails.permissions?.Caisse?.ReadCaisse}>
              <CaissePaymentPage />
            </PrivateRoute>
            <PrivateRoute exact path="/sessions" haveAccess={myDetails.permissions?.Sessions?.ReadSessions}>
              <SessionsPage />
            </PrivateRoute>
            <PrivateRoute exact path="/sessions/:sessionId/tickets" haveAccess={myDetails.permissions?.Sessions?.ReadSessions}>
              <SessionTicketsPage />
            </PrivateRoute>
            <PrivateRoute exact path="/sessions/:sessionId/generate-report" haveAccess={myDetails.permissions?.Sessions?.ReadSessions}>
              <SessionGenerateReportPage />
            </PrivateRoute>
            <PrivateRoute exact path="/sessions/:sessionId/tickets/:ticketId" haveAccess={myDetails.permissions?.Sessions?.ReadSessions}>
              <SessionTicketDetailsPage />
            </PrivateRoute>
            <PrivateRoute exact path="/products" haveAccess={myDetails.permissions?.Products?.ReadProducts}>
              <ProductsPage />
            </PrivateRoute>
            <PrivateRoute exact path="/equivalence" haveAccess={myDetails.permissions?.Products?.ReadProducts}>
              <EquivalencePage />
            </PrivateRoute>
            <PrivateRoute exact path="/stock" haveAccess={myDetails.permissions?.Products?.ReadProducts}>
              <StockPage />
            </PrivateRoute>
            <PrivateRoute exact path="/stats" haveAccess={myDetails.permissions?.Stats?.ReadStats}>
              <StatsPage />
            </PrivateRoute>
            <PrivateRoute exact path="/settings" haveAccess={myDetails.permissions?.Settings?.ReadSettings}>
              <SettingsPage />
            </PrivateRoute>
            <PrivateRoute exact path="/profile">
              <ProfilePage />
            </PrivateRoute>
            <PrivateRoute exact path="/invoices">
              <InvoicesPage />
            </PrivateRoute>
            <Route path="*">
              <ErrorPage
                title="Oups.."
                message="The resource you have requested is not found. If the
                      problem doesn't go away, please contact support."
              />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </div>
  ) : (
    <div style={{ backgroundColor: themeColors.lightGray }}>
      <Router>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Box sx={{ height: 40 }}>
              <Fade
                in={true}
                style={{
                  transitionDelay: "800ms",
                }}
                unmountOnExit
              >
                <CircularProgress sx={{ color: primaryColor().primary }} />
              </Fade>
            </Box>
            <Button sx={{ m: 2, color: primaryColor().primary }}>
              {loadingText}
            </Button>
          </Box>
        ) : (
          <Switch>
            <PublicRoute exact path="/">
              <LoginPage />
            </PublicRoute>
            <PublicRoute exact path="/login">
              <LoginPage />
            </PublicRoute>
            <PublicRoute exact path="/register">
              <RegisterPage />
            </PublicRoute>
            <PublicRoute exact path="/forgot-password">
              <ResetPasswordPage />
            </PublicRoute>
            <PublicRoute exact path="/forgot-password/:tokenValue">
              <NewPasswordPage />
            </PublicRoute>
            <PublicRoute exact path="/email-verification/:tokenValue">
              <EmailVerificationPage />
            </PublicRoute>
            <PrivateRoute exact path="/caisse">
              <CaissePage />
            </PrivateRoute>
            <PrivateRoute exact path="/caisse/payment">
              <CaissePaymentPage />
            </PrivateRoute>
            <PrivateRoute exact path="/sessions">
              <SessionsPage />
            </PrivateRoute>
            <PrivateRoute exact path="/products">
              <ProductsPage />
            </PrivateRoute>
            <PrivateRoute exact path="/stats">
              <StatsPage />
            </PrivateRoute>
            <PrivateRoute exact path="/settings">
              <SettingsPage />
            </PrivateRoute>
            <PrivateRoute exact path="/profile">
              <ProfilePage />
            </PrivateRoute>
            <PrivateRoute exact path="/invoices">
              <InvoicesPage />
            </PrivateRoute>
          </Switch>
        )}
      </Router>
    </div>
  );
};
