import ResponsiveDrawer from "Kcafi/ResponsiveDrawer";
import { Section, SessionType } from "../DashboardPage";
import { SessionLabel } from "./SessionLabel";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useAppContext } from "App";
import { useOperation } from "Components/api";
import { useHistory, useParams } from "react-router-dom";
import { FiberManualRecord } from "@mui/icons-material";
import { primaryColor, themeColors } from "..";
import { DataNotFound } from "../DataNotFound";
import { useTranslation } from "react-i18next";
import { UnlockDialog } from "../Caisse/UnlockDialog";

export const SessionTicketsPage = () => {
  const { t } = useTranslation("common");
  const { myDetails } = useAppContext();
  const history = useHistory();

  const { sessionId } = useParams<any>();
  const { data: session, isLoading: isLoadingSessions } =
    useOperation<SessionType>(
      {
        _links: {
          mySessionDetails: myDetails?._links["mySessionDetails"]?.replace(
            ":sessionId",
            sessionId
          ),
        },
      },
      {
        link: "mySessionDetails",
      },
      "mySessionDetails" + sessionId,
      {} as any,
      undefined,
      !myDetails
    );
  const { data: tickets, isLoading: isLoadingTickets } = useOperation<any[]>(
    session,
    {
      link: "session_tickets",
    },
    "session_tickets" + sessionId,
    {} as any,
    undefined,
    !session
  );

  return (
    <ResponsiveDrawer title={t("pages.session.tickets.session_tickets")}>
      <UnlockDialog />
      <Section
        title={`${t("pages.session.tickets.session_details")} ${
          session?.id ? `#${session?.id}` : ""
        }`}
        /*extraChildren={
          <Button
            sx={{
              borderRadius: "82px",
              backgroundColor: primaryColor().primary,
              ":hover": {
                background: primaryColor().primary,
              },
              color: themeColors.white,
              fontStyle: "normal",
              fontWeight: 600,
              paddingLeft: 2,
              paddingRight: 2,
            }}
            color="primary"
            onClick={() =>
              history.push(`/sessions/${sessionId}/generate-report`)
            }
          >
            <Poll />{" "}
            <Box ml={1} mr={1}>
              {t("pages.session.tickets.report")}
            </Box>
          </Button>
        }*/
      >
        <Grid container spacing={2}>
          {isLoadingSessions ? (
            <Box sx={{ display: "flex", ml: 2 }}>
              <CircularProgress sx={{ color: primaryColor().primary }} />
            </Box>
          ) : session ? (
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  height: "160px",
                  background: themeColors.white,
                  borderRadius: "15.53px",
                  padding: "25px 15px",
                  position: "relative",
                }}
              >
                <FiberManualRecord
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    color: session.closedAt
                      ? themeColors.red
                      : themeColors.green,
                    fontSize: 16,
                  }}
                />
                <SessionLabel
                  label={t("pages.session.tickets.session_id")}
                  text={`${session.internalId}`}
                />

                <SessionLabel
                  label={t("pages.session.session_info.opened_by")}
                  text={`${session.nom} ${session.prenom}`}
                />
                <SessionLabel
                  label={t("pages.session.tickets.started_at")}
                  text={`${new Date(
                    session.startedAt.date
                  ).toLocaleDateString()} ${new Date(
                    session.startedAt.date
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`}
                />
                <SessionLabel
                  label={t("pages.session.tickets.ended_at")}
                  text={`${
                    session.closedAt
                      ? new Date(session.closedAt.date).toLocaleDateString() +
                        " " +
                        new Date(session.closedAt.date).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "-- : --"
                  }`}
                />
                <SessionLabel
                  label={t("pages.session.tickets.total_tickets")}
                  text={`${session.totalTickets}`}
                />
                <SessionLabel
                  label={t("pages.session.tickets.total_earnings")}
                  text={`${session.totalEarnings.toFixed(2)} ${t(
                    "pages.currency"
                  )}`}
                />
                <SessionLabel
                  label={t("pages.session.session_report.sold_available")}
                  text={`${
                    session.availableFunds
                      ? `${session.availableFunds.toFixed(2)} ${t(
                          "pages.currency"
                        )}`
                      : "--"
                  }`}
                />
              </Box>
            </Grid>
          ) : (
            <DataNotFound />
          )}
        </Grid>
      </Section>
      <br></br>
      <Section title={t("pages.session.tickets.tickets_list")}>
        <Grid container spacing={2}>
          {isLoadingTickets ? (
            <Box sx={{ display: "flex", ml: 2 }}>
              <CircularProgress sx={{ color: primaryColor().primary }} />
            </Box>
          ) : tickets?.length ? (
            tickets?.map((ticket) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    background: themeColors.white,
                    borderRadius: "15.53px",
                    padding: "25px 15px",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    history.push(`/sessions/${sessionId}/tickets/${ticket.id}`)
                  }
                >
                  <SessionLabel
                    labelCols={3}
                    textCols={9}
                    label={t("pages.session.tickets.ticket_id")}
                    text={`${ticket.internalId}`}
                  />

                  <SessionLabel
                    labelCols={3}
                    textCols={9}
                    label={t("pages.session.tickets.total")}
                    text={`${ticket.total.toFixed(2)} ${t("pages.currency")}`}
                  />
                </Box>
              </Grid>
            ))
          ) : (
            <DataNotFound />
          )}
        </Grid>
      </Section>
    </ResponsiveDrawer>
  );
};
