import { Alert, Snackbar } from "@mui/material";
import DirTheme from "./DirTheme";

type Props = {
  error?: string;
  success?: string;
  handleClose?: any;
};

export const ErrorToast = ({ error, success, handleClose }: Props) => {
  return (
    <DirTheme>
      <Snackbar
        open={!!error || !!success}
        autoHideDuration={10000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div>
          {error && (
            <Alert
              sx={{ mb: 4, zIndex: 999 }}
              variant="filled"
              severity="error"
            >
              {error}
            </Alert>
          )}
          {success && (
            <Alert
              sx={{ mb: 4, zIndex: 999 }}
              variant="filled"
              severity="success"
            >
              {success}
            </Alert>
          )}
        </div>
      </Snackbar>
    </DirTheme>
  );
};
