import { Grid, Typography } from "@mui/material";
import { themeColors } from "..";

export type SessionLableProps = {
  label: string;
  text: string;
  labelCols?: number;
  textCols?: number;
};
export const SessionLabel = ({
  label,
  text,
  labelCols = 5,
  textCols = 5,
}: SessionLableProps) => {
  return (
    <Grid container spacing={2} mb={1} flexWrap={"nowrap"}>
      <Grid item xs={labelCols} sm={labelCols} md={labelCols}>
        <Typography
          variant="body2"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "142.19%",
            color: themeColors.midnightBlueMiddle,
            whiteSpace: "nowrap",
          }}
        >
          <span style={{ color: themeColors.midnightBlueMiddle, marginRight: "5px" }}>
            {label}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={1} sm={1} md={1}>
        <Typography
          variant="body2"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "142.19%",
            color: themeColors.midnightBlueMiddle,
          }}
        >
          <span style={{ color: themeColors.midnightBlueMiddle, marginRight: "5px" }}>
            :
          </span>
        </Typography>
      </Grid>
      <Grid item xs={textCols} sm={textCols} md={textCols}>
        <Typography
          variant="body2"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "142.19%",
            whiteSpace: "nowrap",
          }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};
