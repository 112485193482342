import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { primaryColor, themeColors } from "..";
import {
  NumericFormatCustom,
  NumericFormatCustomKG,
  NumericFormatCustomLitre,
  NumericFormatCustomPiece,
} from "../Products/NumericFormatCustom";
import { Add } from "@mui/icons-material";
import { StockItem } from "./StockItem";
import { useState } from "react";
import { useOperation } from "Components/api";
import { Category, TProduct } from "../Caisse/CaissePage";
import { useAppContext } from "App";
import { useTranslation } from "react-i18next";
import DirTheme from "../DirTheme";

export const StartNewSession = ({
  onSubmit,
  isLoading,
}: {
  onSubmit: (data: any) => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation("common");
  const { myDetails } = useAppContext();
  const [availableFunds, setAvailableFunds] = useState<number>(0);
  const [product, setProduct] = useState<TProduct>();
  const [qte, setQte] = useState<number>(0);
  const direction = localStorage.getItem("lang") === "ar" ? "rtl" : "ltr";

  const { data: categories, isLoading: isLoadingMyProducts } = useOperation<
    Category[]
  >(
    myDetails,
    {
      link: "myProducts",
    },
    "myProducts",
    {type: "achat,achat-vente"} as any,
    undefined,
    !myDetails
  );

  const [stockItems, setStockItems] = useState<
    {
      title: string;
      imageSrc: string;
      productId: number;
      quantity: number;
      unit: string;
    }[]
  >([]);

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        border: 0,
        boxShadow: `0px 11px 64px ${themeColors.black007}`,
        p: 2,
        outline: 0,
      }}
    >
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          mb: 4,
        }}
      >
        {t("pages.session.start_new_session.title")}
      </Typography>
      <DirTheme>
        <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
          <TextField
            sx={{
              fontStyle: "normal",
            }}
            label={t("pages.session.start_new_session.available_funds")}
            onChange={(event) =>
              setAvailableFunds(parseFloat(event.target.value))
            }
            name="availableFunds"
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            variant="outlined"
            fullWidth
          />
        </FormControl>
      </DirTheme>

      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{
          fontStyle: "normal",
          fontWeight: 400,
          mb: 4,
        }}
      >
        {t("pages.session.start_new_session.available_in_stock")} :
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FormControl
          sx={{
            mb: 4,
            mr: direction === "rtl" ? 0 : 1,
            ml: direction === "rtl" ? 1 : 0,
          }}
        >
          <DirTheme>
            <InputLabel htmlFor="grouped-native-select">
              {t("pages.session.start_new_session.product")}
            </InputLabel>
            <Select
              native
              defaultValue=""
              id="grouped-native-select"
              label={t("pages.session.start_new_session.product")}
              onChange={(event) => {
                setProduct(undefined);
                categories?.forEach((category) => {
                  category.products?.forEach((product) => {
                    if (product.id === parseInt(event.target.value)) {
                      setProduct(product);
                    }
                  });
                });
              }}
              variant="outlined"
              size="small"
            >
              <option aria-label="None" value="00">
                {t("pages.session.start_new_session.choose_product")}
              </option>
              {!isLoadingMyProducts &&
                categories?.map((category, key) => (
                  <optgroup label={category.title} key={key}>
                    {category.products?.map((product, key) =>
                      product.type !== "vente" ? (
                        <option value={product.id} key={key}>
                          {product.title}
                        </option>
                      ) : (
                        <></>
                      )
                    )}
                  </optgroup>
                ))}
            </Select>
          </DirTheme>
        </FormControl>
        <FormControl
          variant="outlined"
          sx={{
            mb: 4,
            mr: direction === "rtl" ? 0 : 1,
            ml: direction === "rtl" ? 1 : 0,
          }}
        >
          <DirTheme>
            <TextField
              sx={{
                fontStyle: "normal",
              }}
              label={t("pages.session.start_new_session.quantity")}
              name="quantity"
              id="formatted-numberformat-input-x"
              variant="outlined"
              onChange={(event) => setQte(parseFloat(event.target.value))}
              InputProps={{
                inputComponent:
                  product?.unit === "litre"
                    ? (NumericFormatCustomLitre as any)
                    : product?.unit === "kg"
                    ? (NumericFormatCustomKG as any)
                    : (NumericFormatCustomPiece as any),
              }}
              disabled={!product}
              size="small"
            />
          </DirTheme>
        </FormControl>
        <Button
          sx={{
            borderRadius: "4px",
            backgroundColor: primaryColor().primary,
            ":hover": {
              background: primaryColor().primary,
            },
            color: themeColors.white,
            fontStyle: "normal",
            fontWeight: 600,
            height: "40px",
            mr: direction === "rtl" ? 1 : 0,
            ml: direction === "rtl" ? 1 : 0,
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            if (product) {
              setStockItems((prev) => [
                ...prev,
                {
                  title: product.title,
                  imageSrc: product.img || `https://fakeimg.pl/600x400?text=${product.title}`,
                  productId: product.id,
                  quantity: qte,
                  unit: product.unit || "",
                },
              ]);
              setProduct(undefined);
              setQte(0);
            }
          }}
          disabled={!product || !qte || availableFunds === undefined}
        >
          {false ? (
            <CircularProgress size={20} sx={{ color: themeColors.white }} />
          ) : (
            <Add />
          )}
        </Button>
      </Box>

      {stockItems.map((stockItem) => (
        <StockItem
          imageSrc={stockItem.imageSrc}
          title={stockItem.title}
          quantity={`${stockItem.quantity} ${stockItem.unit}`}
          removeProduct={() => {
            const productIndex = stockItems.findIndex(
              (item) => item.productId === stockItem.productId
            );

            if (productIndex !== -1) {
              setStockItems((prev) =>
                prev.filter((item, index) => index !== productIndex)
              );
            }
          }}
        />
      ))}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            size="small"
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            className="cart-button"
            sx={{
              background: primaryColor().primary17,
              borderRadius: "8px",
              color: primaryColor().primary,
              animation: "pulse 0.7s",
              ":hover": {
                background: primaryColor().primary17,
                //animation: "pulse 0.5s",
              },
              "@keyframes pulse": {
                "0%, 100%": {
                  transform: "scale(1)",
                  background: primaryColor().primary17,
                },
                "50%": {
                  transform: "scale(1.08)",
                  background: primaryColor().primary17,
                },
              },
            }}
            onClick={() =>
              onSubmit({
                availableFunds: availableFunds,
                stockItems: stockItems,
              })
            }
            disabled={isLoading}
          >
            <Avatar
              sx={{
                backgroundColor: themeColors.transparent,
              }}
            >
              {isLoading ? (
                <CircularProgress size={20} sx={{ color: themeColors.white }} />
              ) : (
                <Add style={{ color: primaryColor().primary }} />
              )}
            </Avatar>
            <Typography
              variant="caption"
              sx={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: { xs: "14px", sm: "14px", md: "16px" },
                lineHeight: "23px",
                leadingTrim: "both",
                textEdge: "uppercase",
                mr: 1,
                ml: 1,
              }}
            >
              {t("pages.session.start_new_session.start")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
